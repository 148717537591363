import React from "react";
import { useSelector } from "react-redux";
import { LoginForm } from "../../../features/auth/LoginForm";
import { RegisterForm } from "../../../features/auth/RegisterForm";

import { ErrorModal } from "./ErrorModal";

export const ModalManager = () => {
  const modalLookup = {
    LoginForm,
    RegisterForm,
  };
  const currentModal = useSelector((state) => state.modals);

  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    let ModalComponent = modalLookup?.[modalType];
    if (!ModalComponent) ModalComponent = ErrorModal;
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <>{renderedModal}</>;
};
