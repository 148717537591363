import React from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { Input } from "../../app/common/form/Input";
import { addVehicleToFirestore } from "../../app/firestore/firestoreService";

//#12 : https://tanstack.com/query/v4/docs/guides/window-focus-refetching
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const AddVehicle = () => {
  const [input, setInput] = React.useState("");
  const [manual, setManual] = React.useState(false);
  const [state, dispatch] = React.useReducer(reducer, {
    vin: "",
    isSold: false,
    isPublic: false,
  });

  React.useEffect(() => {
    if (input.length === 17) {
      console.log("17!!!!");
    }
  }, [input]);

  const handleOnChange = (e) =>
    dispatch({
      type: "UPDATE_INPUT",
      payload: { field: e.target.name, value: e.target.value },
    });

  return (
    <div className="container mx-auto py-2 px-2 md:py-10 md:px-10 bg-white md:rounded-xl md:mt-2 shadow-lg flex flex-col space-y-2">
      <QueryClientProvider client={queryClient}>
        <h1>Add a vehicle</h1>
        <Input
          value={state.vin}
          name="vin"
          onChange={handleOnChange}
          label="VIN (Vehicle Identification Number)"
          placeholder="XXXXXXXXXXXXXXXXX"
          helperText="Must be 17 symbols. No 'i' or 'o'."
        />
        {(state.vin.length === 17 || manual) && (
          <VehicleDetails
            key={input}
            state={state}
            dispatch={dispatch}
            vin={state.vin}
            manual={manual}
          />
        )}
        <span
          onClick={() => setManual((v) => !v)}
          className="text-xs text-gray-500 hover:underline"
        >
          Or add information manually
        </span>
        <button
          type="button"
          onClick={(e) => {
            addVehicleToFirestore(state);
            alert(
              `${state?.year} ${state?.make} ${state?.model} Added Succesfully."`
            );
            dispatch({ type: "CLEAR" });
            // setInput("");
          }}
          className="bg-lime-400 px-4 py-2 rounded w-full md:w-48 text-center hover:bg-lime-300 transition-all text-white flex items-center justify-center space-x-2 disabled:bg-slate-200"
          disabled={state.vin.length !== 17}
        >
          Add Vehicle
        </button>
      </QueryClientProvider>
    </div>
  );
};

const reducer = (state, { type, payload }) => {
  console.log(payload);
  switch (type) {
    case "UPDATE_INPUT":
      return { ...state, [payload.field]: payload.value };
    case "CLEAR":
      return { vin: "" };
    case "UPDATE_ALL":
      return {
        vin: payload.VIN,
        ...keysArray.reduce(
          (acc, val) => ({ ...acc, [val.name]: payload[val.label] || "" }),
          {}
        ),
      };

    // ...Objepayload.reduce((acc, val) => {

    // return {...acc, }
    // }, {}) };
    default:
      return state;
  }
};

function VehicleDetails({ state, dispatch, vin = "", manual = false }) {
  // const [state, dispatch] = React.useReducer(reducer, { VIN: vin });

  const handleOnChange = (e) =>
    dispatch({
      type: "UPDATE_INPUT",
      payload: { field: e.target.name, value: e.target.value },
    });

  const { isLoading, error, data } = useQuery("decodeVin", () =>
    fetch(
      `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValues/${vin}?format=json`
    )
      .then((res) => res.json())
      .then((data) =>
        dispatch({ type: "UPDATE_ALL", payload: data.Results[0] })
      )
  );

  if (isLoading && !manual) return "Loading...";

  if (error && !manual) return "An error has occurred: " + error.message;

  return (
    <div className="flex flex-col">
      {/* <pre className="text-xs">{JSON.stringify(state, null, 2)}</pre> */}
      {keysArray.map((k) => (
        <Input
          key={k.name}
          value={state[k.name]}
          name={k.name}
          onChange={handleOnChange}
          label={k.label}
        />
      ))}
    </div>
  );
}

const keysArray = [
  { label: "ModelYear", name: "year" },
  { label: "Make", name: "make" },
  { label: "Model", name: "model" },
  { label: "BodyClass", name: "body" },
  { label: "Trim", name: "trim" },
  { label: "TransmissionStyle", name: "transmissionStyle" },
  { label: "Mileage", name: "mileage" },
  { label: "BuyingDate", name: "buyingDate" },
  { label: "BuyingPrice", name: "buyingPrice" },
  { label: "SellingPrice", name: "sellingPrice" },
  { label: "ExteriorColor", name: "exteriorColor" },
  { label: "InteriorColor", name: "interiorColor" },
  { label: "InteriorType", name: "interiorType" },
  { label: "DisplacementCC", name: "engineCC" },
  { label: "Doors", name: "doors" },
  { label: "DriveType", name: "driveType" },
  { label: "EngineCylinders", name: "endgineCylinders" },
  { label: "EngineHP", name: "engineHP" },
  { label: "EngineKW", name: "engineKW" },
  { label: "FuelTypePrimary", name: "fuelType" },
  { label: "Seats", name: "seats" },
  { label: "Series", name: "series" },
  { label: "Wheels", name: "wheels" },
  { label: "TransmissionSpeeds", name: "transmissionSpeeds" },
];
