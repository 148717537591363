import React, { forwardRef } from "react";
import "./Image.css";

import {
  closestCenter,
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  useDndContext,
  MeasuringStrategy,
  defaultDropAnimationSideEffects,
} from "@dnd-kit/core";

import {
  arrayMove,
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

import { CSS, isKeyboardEvent } from "@dnd-kit/utilities";

import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdDeleteForever,
  MdStarRate,
  MdVisibility,
  MdVisibilityOff,
} from "react-icons/md";

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimation = {
  keyframes({ transform }) {
    return [
      { transform: CSS.Transform.toString(transform.initial) },
      {
        transform: CSS.Transform.toString({
          scaleX: 0.98,
          scaleY: 0.98,
          x: transform.final.x - 10,
          y: transform.final.y - 10,
        }),
      },
    ];
  },
  sideEffects: defaultDropAnimationSideEffects({
    className: {
      active: "active",
    },
  }),
};

export const Images = ({
  images,
  order,
  setOrder,
  mainImgId,
  setMainVehiclePhoto,
  //   onRemove,
  toggleVehicleImageVisibility,
  rearrangeMode,
  toggleSelection,
  selected,
  deleteVehicleImage,
  openImage,
}) => {
  const [activeId, setActiveId] = React.useState(null);

  const activationConstraint = {
    delay: rearrangeMode ? 0 : 250,
    tolerance: 5,
  };

  const activeIndex = activeId ? order.indexOf(activeId) : -1;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint,
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      activationConstraint,
    })
  );

  if (!images) return null;
  return (
    <div>
      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        sensors={sensors}
        collisionDetection={closestCenter}
        measuring={measuring}
      >
        <SortableContext items={order}>
          <ul className="Pages grid">
            {order.map((id, index) => (
              <SortableImage
                id={id}
                index={index + 1}
                key={id}
                activeIndex={activeIndex}
                image={images[id]}
                toggleSelection={toggleSelection}
                isSelected={selected.includes(id)}
                rearrangeMode={rearrangeMode}
                mainImgId={mainImgId}
                toggleVehicleImageVisibility={toggleVehicleImageVisibility}
                setMainVehiclePhoto={setMainVehiclePhoto}
                deleteVehicleImage={deleteVehicleImage}
                openImage={openImage}
              />
            ))}
          </ul>
        </SortableContext>
        <DragOverlay dropAnimation={dropAnimation}>
          {activeId ? (
            <ImageOverlay
              id={activeId}
              items={order}
              image={images[activeId]}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );

  function handleDragStart({ active }) {
    setActiveId(active.id);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  function handleDragEnd({ over }) {
    if (over) {
      const overIndex = order.indexOf(over.id);

      if (activeIndex !== overIndex) {
        const newIndex = overIndex;

        setOrder(arrayMove(order, activeIndex, newIndex));
      }
    }

    setActiveId(null);
  }
};

function ImageOverlay({ id, items, ...props }) {
  const { activatorEvent, over } = useDndContext();
  const isKeyboardSorting = isKeyboardEvent(activatorEvent);
  const activeIndex = items.indexOf(id);
  const overIndex = over?.id ? items.indexOf(over?.id) : -1;

  return (
    <Image
      id={id}
      {...props}
      clone
      insertPosition={
        isKeyboardSorting && overIndex !== activeIndex
          ? overIndex > activeIndex
            ? 1
            : -1
          : undefined
      }
    />
  );
}

const SortableImage = ({ id, activeIndex, ...props }) => {
  const {
    attributes,
    listeners,
    index,
    isDragging,
    isSorting,
    over,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges: always,
  });

  return (
    <Image
      ref={setNodeRef}
      id={id}
      active={isDragging}
      style={{
        transition,
        transform: isSorting ? undefined : CSS.Translate.toString(transform),
      }}
      insertPosition={
        over?.id === id ? (index > activeIndex ? 1 : -1) : undefined
      }
      {...props}
      {...attributes}
      {...listeners}
    />
  );
};

export const Image = forwardRef(function Image(
  {
    active,
    clone,
    id,
    index,
    insertPosition,
    onRemove,
    image,
    style,
    isSelected = false,
    rearrangeMode,
    mainImgId,
    toggleSelection = () => {},
    toggleVehicleImageVisibility,
    setMainVehiclePhoto,
    deleteVehicleImage,
    openImage,
    ...props
  },
  ref
) {
  //   console.log({ rearrangeMode });
  //   console.log(insertPosition);
  return (
    <li
      className={` Wrapper ${active ? "active" : ""} ${clone ? "clone" : ""} ${
        insertPosition === -1 ? "insertBefore" : ""
      } ${insertPosition === 1 ? "insertAfter" : ""} `}
      ref={ref}
    >
      <button
        className={`Page ${rearrangeMode ? "cursor-grab" : "cursor-zoom-in"}`}
        data-id={id.toString()}
        onClick={(e) => openImage(e.target.dataset.id)}
        {...props}
        style={{
          ...style,
          backgroundImage: `url(${image?.thumbs?.[0]?.url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      {!active && !isSelected && !clone && !rearrangeMode && (
        <div className="absolute bottom-0 flex pointer-events-none">
          {!image.isPublic && (
            <span className="  px-2 py-3 text-xl flex text-white   ">
              <MdVisibilityOff />
            </span>
          )}
          {mainImgId === id && (
            <span className=" px-2 py-3 text-xl flex text-yellow-400   ">
              <MdStarRate />
            </span>
          )}
        </div>
      )}

      {!active && toggleSelection && !clone && !rearrangeMode ? (
        <span
          className="absolute top-3 left-2 cursor-pointer 
          bg-white  rounded z-50  text-lg"
          onClick={() => toggleSelection(id)}
        >
          {isSelected ? (
            <MdCheckBox className="text-lime-600   scale-150" />
          ) : (
            <MdCheckBoxOutlineBlank className="text-gray-400  scale-150 bg-opacity-70 hover:bg-opacity-100 transition-all" />
          )}
        </span>
      ) : null}
      {!active && isSelected && !clone && !rearrangeMode ? (
        <div className="text-white  border-t border-slate-600 rounded-b overflow-hidden absolute w-full bg-slate-900 bg-opacity-90 bottom-0 h-12 flex justify-around">
          {mainImgId !== id ? (
            <ToolbarButton
              Icon={MdStarRate}
              text="Set Main"
              onClick={() => setMainVehiclePhoto(id)}
            />
          ) : (
            <ToolbarButton
              className="text-yellow-400"
              Icon={MdStarRate}
              text="Main Photo"
              disabled
            />
          )}
          <ToolbarButton
            Icon={image.isPublic ? MdVisibilityOff : MdVisibility}
            text={image.isPublic ? "Set Hidden" : "Set Public"}
            className={image.isPublic && "text-blue-200"}
            onClick={() => toggleVehicleImageVisibility(!image.isPublic, id)}
            // disabled={mainImgId === id}
          />
          <ToolbarButton
            Icon={MdDeleteForever}
            text="Delete"
            className="text-red-400"
            onClick={() => deleteVehicleImage(id)}
          />
        </div>
      ) : null}

      {/* {index != null ? <span className="PageNumber">{index}</span> : null} */}
    </li>
  );
});

function always() {
  return true;
}

const ToolbarButton = ({
  Icon,
  text = "",
  className = "",
  onClick = () => {},
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      className={`select-none ${
        props?.disabled ? "" : "hover:bg-black hover:bg-opacity-80"
      }  w-full flex flex-col justify-center items-center  bg-opacity-40  transition-all ${className}`}
      {...props}
    >
      <Icon />
      <span className="bg-transparent text-[8px] mt-1">{text}</span>
    </button>
  );
};
