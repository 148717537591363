import React from "react";
// import { useHotkeys } from "react-hotkeys-hook";
import {
  useSortBy,
  useTable,
  useResizeColumns,
  useFlexLayout,
  useGroupBy,
} from "react-table";
// import { useGamepads } from "../../app/hooks/useGamepads";
import {
  checkCellConditionalFormatting,
  checkRowConditionalFormatting,
} from "./conditionalFormatting";

export const SalesTable = ({ data, columns, initialState }) => {
  const [disableSortBy, setDisableSortBy] = React.useState(false);
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 50, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      { columns, data, initialState, defaultColumn, disableSortBy },
      useSortBy,
      useResizeColumns,
      useFlexLayout
    );
  const colCount = columns.length;
  const rowCount = rows.length;
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const focusContainer = React.useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === "Tab" && e.shiftKey) {
      e.preventDefault();
      return setSelectedIndex((prevIndex) =>
        prevIndex % colCount === 0 ? prevIndex : prevIndex - 1
      );
    }
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex + colCount >= rowCount * colCount
            ? prevIndex
            : prevIndex + colCount
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex - colCount < 0 ? prevIndex : prevIndex - colCount
        );
        break;
      case "ArrowRight":
      case "Tab":
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          (prevIndex + 1) % colCount === 0 ? prevIndex : prevIndex + 1
        );
        break;
      case "ArrowLeft":
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex % colCount === 0 ? prevIndex : prevIndex - 1
        );
        break;
      case "Home":
        e.preventDefault();
        setSelectedIndex(
          (prevIndex) => Math.floor(prevIndex / colCount) * colCount
        );
        break;
      case "End":
        e.preventDefault();
        setSelectedIndex(
          (prevIndex) => Math.ceil((prevIndex + 1) / colCount) * colCount - 1
        );
        break;
      case "Enter":
      case " ":
        e.preventDefault();
        if (e.target.tagName.toLowerCase() === "div") {
          const elButton = e.target.querySelector("button");
          const elInput = e.target.querySelector("input");

          if (elInput) elInput?.focus();
          if (elButton && !elInput) elButton?.click();
        }
        if (e.target.tagName.toLowerCase() === "button") {
          e.target?.click();
        }
        if (e.target.tagName.toLowerCase() === "input") {
          const tdElement = e.target.closest("div");
          if (tdElement) {
            tdElement.focus();
          }
          // e.target?.parentNode?.focus();
          // e.target?.blur();
        }

        // console.log(el);

        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    onFocus();
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  const onFocus = () => {
    focusContainer.current.focus();
  };

  const onBlur = () => {
    console.log("Tab is blurred");
  };

  React.useEffect(() => {
    if (!focusContainer.current) return;
    focusContainer.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    focusContainer.current.focus();
  }, [selectedIndex]);

  return (
    <div
      {...getTableProps()}
      className="block   lg:bg-white mx-1 lg:mx-0 max-w-full border-spacing-0"
    >
      <div className="hidden  overflow-x-hidden lg:table-header-group">
        {headerGroups.map((headerGroup) => (
          <div {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <div
                {...column.getHeaderProps(
                  column.getSortByToggleProps({
                    onMouseUp: (e) => {
                      if (column.isResizing) {
                        setDisableSortBy(true);
                      }
                    },
                    onMouseDown: () => setDisableSortBy(false),
                  })
                )}
                className="text-center text-xs py-1 px-2 border select-none relative overflow-x-clip"
              >
                {column.render("Header")}
                <span className="absolute left-0 top-0">
                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                </span>
                {column.canResize && (
                  <div
                    {...column.getResizerProps({
                      // onMouseDown: (e) => setDisableSortBy(true),
                      // onMouseUp: (e) => setDisableSortBy(true),
                    })}
                    className={`right-0 bg-blue-400 bg-opacity-5 hover:bg-opacity-50 w-2 h-full absolute top-0 z-10 touch-none ${
                      column.isResizing ? "bg-red-400 bg-opacity-50" : ""
                    }`}
                  />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className=" overflow-x-clip " {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);

          return (
            <div
              className="transition-all p-0.5 lg:p-0 min-h-max flex mb-2 lg:my-0 rounded lg:rounded-none shadow lg:shadow-none collapse flex-wrap w-full lg:table-row even:bg-white odd:bg-gray-100"
              {...row.getRowProps({
                style: checkRowConditionalFormatting(row?.original),
              })}
            >
              {row.cells.map((cell, columnIndex) => {
                const cellIndex = rowIndex * colCount + columnIndex;
                return (
                  <div
                    tabIndex={cellIndex === 0 ? 0 : -1}
                    ref={cellIndex === selectedIndex ? focusContainer : null}
                    {...cell.getCellProps({
                      onKeyDown: handleKeyDown,
                      onMouseDown: () => setSelectedIndex(cellIndex),
                      style: checkCellConditionalFormatting(
                        cell?.value,
                        cell?.row?.original
                      ),
                    })}
                    className={`  relative  flex-grow  [&::-webkit-scrollbar]:hidden  mt-4  pt-0.5 lg:p-0 lg:m-0 transition-colors  border-spacing-0 rounded lg:rounded-none border m-0.5  border-black border-opacity-10 flex flex-col lg:table-cell     ring-inset focus:ring-1  focus:ring-red-500 focus-within:ring-1 focus-within:ring-red-300   ${
                      selectedIndex === cellIndex
                        ? " bg-red-500 bg-opacity-20"
                        : ""
                    } "`}
                  >
                    {cell.render("Cell")}
                    <div className="text-[8px] select-none absolute -top-3  opacity-50  lg:hidden">
                      {cell?.column?.Header}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
