export const conditionalFormatting = {
  id1: {
    name: "Checkbox true",
    priority: 3,
    type: "Cell",
    validation: (value) => (typeof value === "boolean" && value ? true : false),
    style: { backgroundColor: "rgba(0, 255, 0, 0.55)" },
  },
  id2: {
    name: "Checkbox false",
    priority: 2,
    type: "Cell",
    validation: (value) =>
      typeof value === "boolean" && !value ? true : false,
    style: { backgroundColor: "rgba(255, 255, 255, 0.7)" },
  },
  id4: {
    name: "Delivered",
    priority: 4,
    type: "Cell",
    validation: (value, row) =>
      typeof row.delivered === "boolean" && row?.delivered ? true : false,
    style: { backgroundColor: "rgba(115, 55, 0, 0.95)", color: "white" },
  },
  // id3: {
  //   name: "Delivered",
  //   priority: 4,
  //   type: "Row",
  //   validation: ({ delivered }) =>
  //     typeof delivered === "boolean" && delivered ? true : false,
  //   style: { backgroundColor: "blue", color: "white" },
  // },
  id5: {
    name: "Delivery Today",
    priority: 3,
    type: "Row",
    validation: ({ deliveryDate }) =>
      isToday(deliveryDate) && deliveryDate ? true : false,
    style: { backgroundColor: "rgba(0, 255, 0, 0.35)", color: "black" },
  },
  id6: {
    name: "Delivery Tomorrow",
    priority: 3,
    type: "Row",
    validation: ({ deliveryDate }) =>
      isTomorrow(deliveryDate) && deliveryDate ? true : false,
    style: { backgroundColor: "rgba(255, 0, 0, 0.35)", color: "black" },
  },
  id7: {
    name: "Near Future",
    priority: 3,
    type: "Row",
    validation: ({ deliveryDate }) =>
      isSoon(deliveryDate) && deliveryDate ? true : false,
    style: { backgroundColor: "rgba(255, 255, 0, 0.55)", color: "black" },
  },
};

export function checkRowConditionalFormatting(rowValue) {
  const cellFormating = Object.values(conditionalFormatting);
  const formatFilter = cellFormating.filter((v) => v.type === "Row");
  const sortFilter = formatFilter.sort((a, b) =>
    a.priority > b.priority ? 1 : -1
  );
  const obj = sortFilter.find((val) => val.validation(rowValue));
  if (obj && obj?.style) {
    return obj.style;
  }
}

export function checkCellConditionalFormatting(cellValue, row) {
  const cellFormating = Object.values(conditionalFormatting);
  const formatFilter = cellFormating.filter((v) => v.type === "Cell");
  const sortFilter = formatFilter.sort((a, b) =>
    a.priority < b.priority ? 1 : -1
  );
  const obj = sortFilter.find((val) => val.validation(cellValue, row));
  if (obj && obj?.style) {
    return obj.style;
  }
}

function isTomorrow(timestampInSeconds) {
  const now = new Date();
  const tomorrow = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1
  );
  const date = new Date(timestampInSeconds * 1000);
  return (
    date.getDate() === tomorrow.getDate() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getFullYear() === tomorrow.getFullYear()
  );
}
function isToday(timestampInSeconds) {
  const now = new Date();
  const date = new Date(timestampInSeconds * 1000);
  return (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
}
function isSoon(timestampInSeconds) {
  const now = new Date();

  const afterTomorrow = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 2
  );
  const daysAfterTomorrow = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 3
  );

  const date = new Date(timestampInSeconds * 1000);
  return (
    (date.getDate() === afterTomorrow.getDate() ||
      date.getDate() === daysAfterTomorrow.getDate()) &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  );
}
