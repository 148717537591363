import React from "react";
import { ModalWrapper } from "../../app/common/modals/ModalWrapper";
import { signInWithEmail } from "../../app/firestore/firebaseService";
import { MdLockOpen, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Input } from "../../app/common/form/Input";

export const LoginForm = ({ isModal = true }) => {
  return (
    <ModalWrapper size="mini" header="Log In" HeaderIcon={MdLockOpen}>
      <Login />
    </ModalWrapper>
  );
};

export const Login = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [values, setValues] = React.useState({
    email: "",
    password: "",
  });

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((v) => !v);
  };

  const handleChange = (e) => {
    setValues((v) => ({ ...v, [e.target.name]: e.target.value }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    signInWithEmail(values);
  };

  return (
    <form className="w-96 mx-auto px-2" onSubmit={onSubmit}>
      <div className=" flex flex-col space-y-4">
        <Input
          name="email"
          placeholder="Email address"
          label="Email"
          value={values?.email}
          onChange={handleChange}
        />
        <Input
          name="password"
          placeholder="Password"
          type={showPassword ? "text" : "password"}
          label="Password"
          value={values?.password}
          onChange={handleChange}
          ActionButton={() => (
            <span
              className="absolute right-0  flex items-center pr-2  cursor-pointer opacity-20 focus:opacity-100 hover:opacity-100"
              type="text"
              tabIndex="-1"
              onClick={toggleShowPassword}
            >
              {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
            </span>
          )}
        />
      </div>
      <div className=" px-4 py-3 sm:px-6 sm:flex sm:flex-row space-x-2 justify-end w-full">
        <button
          className="px-4 py-2 border rounded bg-main text-white"
          type="submit"
          label="Login"
        >
          Login
        </button>
      </div>
    </form>
  );
};
