import React, { useState } from "react";

export const InputTest = () => {
  const [inputValue, setInputValue] = useState("");
  const [lastKey, setLastKey] = useState("");
  const [enter, setEnter] = useState("NOT");

  const handleKeyDown = async (event) => {
    setEnter(event.key);
  };

  const handleKeyUp = (event) => {
    event.preventDefault();
    setEnter(event.key);
    if (event.key === lastKey && event.key === "Enter") {
      // Do something when the Enter/Return key is pressed
      //   setEnter("ENTER");
      console.log("Enter key pressed");
    } else {
      //   setEnter("NOT");
    }
  };

  return (
    <>
      <input
        type="search"
        className="border p-2"
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        enterkeyhint="bitch"
      />
      <input
        type="text"
        className="border p-2"
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
      />
      <input
        type="text"
        className="border p-2"
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
      />
      <div>{enter}</div>
    </>
  );
};
