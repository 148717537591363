import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useState } from "react";
import { Input } from "./Input";

export const AddressInput = ({
  name,
  label,
  value = { address: "" },
  dispatch,
  setValue,
  options,
  onBlur,
  ...props
}) => {
  // const [value, setValue] = useState({ address: "" });
  const [error, setError] = useState(null);

  function handleSelect(address) {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) =>
        dispatch({
          type: "SET_ADDRESS",
          payload: { [name]: address, [`${name}LatLng`]: latLng },
        })
      )
      .catch((error) => setError(error));
  }

  function handleBlur(e) {
    // onBlur(e);
    // if (!value.latLng) {
    //     dispatch({ address: "", latLng: null });
    // }
  }

  return (
    <PlacesAutocomplete
      value={value.toString()}
      onChange={(value) =>
        dispatch({ type: "UPDATE_INPUT", payload: { field: name, value } })
      }
      onSelect={handleSelect}
      // searchOptions={options}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="flex items-center justify-center  w-full  ">
          <div className="  flex relative w-full ">
            {/* <div>{JSON.stringify(value, null, 2) || ""}</div> */}
            <Input
              placeholder="Address"
              label="Address"
              className="px-4  py-1 my-1 rounded  outline-none text-sm "
              {...getInputProps({ onBlur: handleBlur, ...props })}
            />
            {suggestions?.length > 0 && (
              <div className="bg-white mt-0 z-10 absolute left-0 top-10  text-xs">
                <ul className="w-full rounded border">
                  {loading ? (
                    <li> Loading</li>
                  ) : (
                    suggestions.map((suggestion) => (
                      <li
                        className="w-full flex px-2 py-1 space-x-1"
                        key={suggestion.placeId}
                        {...getSuggestionItemProps(suggestion)}
                      >
                        <h2>{suggestion.formattedSuggestion.mainText}</h2>
                        <p>{suggestion.formattedSuggestion.secondaryText}</p>
                      </li>
                    ))
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};
