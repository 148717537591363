import cuid from "cuid";
import cuidPlus from "cuid-plus";
import { db, auth } from "../config/firebase";
import {
  doc,
  setDoc,
  Timestamp,
  serverTimestamp,
  // updateDoc,
  collection,
  arrayUnion,
  arrayRemove,
  deleteField,
  increment,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  // startAt,
  // endAt,
  // onSnapshot,
  writeBatch,
  updateDoc,
  addDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { updateProfile, getAuth } from "firebase/auth";
import { deleteImageAndThumb } from "./firebaseService";

export const addVehicleToFirestore = (state) => {
  console.log(state);
  try {
    return setDoc(
      doc(db, "vehicles", cuid()),
      { isPublic: false, isSold: false, ...state },
      { merge: true }
    );
  } catch (error) {
    console.log(error);
  }
};

export function listenToVehicleFromFirestore(vehicleId) {
  return doc(db, `vehicles/${vehicleId}`);
}
export function listenToVehiclesFromFirestore(showSold) {
  if (showSold) return collection(db, `vehicles`);
  return query(collection(db, `vehicles`), where("isSold", "==", false));
}
export function getUser(userId) {
  return doc(db, `users/${userId}`);
}

export function dataFromSnapshot(snapshot) {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data();

  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof Timestamp) {
        //Change Firestore dates into Date object
        data[prop] = data[prop].toDate();
      }
    }
  }

  return {
    ...data,
    id: snapshot.id,
  };
}

export function deleteVehicleInFirestore(vehicleId) {
  console.log(vehicleId);
  return deleteDoc(doc(db, `vehicles`, vehicleId));
}
export function setPublicVehicleInFirestore(vehicleId, value) {
  console.log(vehicleId);
  return deleteDoc(doc(db, `vehicles`, vehicleId));
}

export async function updateVehicleInFirestore(vehicleId, data) {
  console.log(vehicleId, data);
  return updateDoc(doc(db, `vehicles`, vehicleId), data);
}

/********************* USER PROFILE CRUD START ********************************/
export function setUserProfileData(user) {
  return setDoc(doc(db, `users/${user.uid}`), {
    displayName: user.displayName,
    email: user.email,
    photoURL: user.photoURL || null,
    role: { noRole: true },
    createdAt: serverTimestamp(),
  });
}

export async function updateUserProfile(profile) {
  const user = auth.currentUser;
  try {
    if (user.displayName !== profile.displayName) {
      await updateProfile(user, {
        displayName: profile.displayName,
        // description: profile.description
      });
    }
    return await updateDoc(doc(db, `users/${user.uid}`), profile);
  } catch (error) {
    throw error;
  }
}

export async function updateUserInFirestore(userId, data) {
  // const authUserKeys = ["displayName", "photoUrl", "email"];
  // const auth = getAuth();
  // const user = await auth.getUser(userId);
  // console.log(user);
  // const user = auth.currentUser;

  // const filteredData = Object.keys(data)
  //   .filter((key) => authUserKeys.includes(key))
  //   .reduce((obj, key) => {
  //     obj[key] = data[key];
  //     return obj;
  //   }, {});

  // if (Object.values(filteredData).length > 0) {
  //   await updateProfile(user, filteredData);
  // }

  // console.log(userId, data, filteredData);
  return updateDoc(doc(db, `users`, userId), {
    ...data,
    dateUpdated: serverTimestamp(),
  });
}
/********************* USER PROFILE CRUD END ********************************/
export async function deleteVehicleImage(imageId, vehicleId, mainImgId) {
  //if mainImgId === imageId
  // if no more images remove mainImgId
  //if more images, set random mainImgId
  await deleteImageAndThumb(vehicleId, imageId);
  return updateDoc(doc(db, `vehicles`, vehicleId), {
    [`images.${imageId}`]: deleteField(),
    imgOrder: arrayRemove(imageId),
  });
}
export async function deleteVehicleImages(imageIdArray, vehicleId) {
  for (const imageId of imageIdArray) {
    await deleteImageAndThumb(vehicleId, imageId);
  }

  return updateDoc(doc(db, `vehicles`, vehicleId), {
    ...imageIdArray.reduce((acc, imageId) => {
      return { ...acc, [`images.${imageId}`]: deleteField() };
    }, {}),
    imgOrder: arrayRemove(...imageIdArray),
  });
}
export async function toggleVehicleImageVisibility(value, imageId, vehicleId) {
  return updateDoc(doc(db, `vehicles`, vehicleId), {
    [`images.${imageId}.isPublic`]: value,
  });
}
export async function setVehicleImageVisibility(
  value = false,
  imageIdArray,
  vehicleId
) {
  return updateDoc(doc(db, `vehicles`, vehicleId), {
    ...imageIdArray.reduce((acc, imageId) => {
      return { ...acc, [`images.${imageId}.isPublic`]: value };
    }, {}),
  });
}

export async function setMainVehiclePhoto(imageId, vehicleId) {
  return updateDoc(doc(db, "vehicles", vehicleId), {
    mainImgId: imageId,
  });
}

export async function imageOrderLeft(imageId, vehicleId, imgOrder) {
  const index = imgOrder.indexOf(imageId);
  return updateDoc(doc(db, "vehicles", vehicleId), {
    imgOrder: moved(imgOrder, index, index - 1),
  });
}

export async function imageOrderRight(imageId, vehicleId, imgOrder) {
  const index = imgOrder.indexOf(imageId);
  return updateDoc(doc(db, "vehicles", vehicleId), {
    imgOrder: moved(imgOrder, index, index + 1),
  });
}

export async function setImgOrder(vehicleId, imgOrder) {
  return updateDoc(doc(db, "vehicles", vehicleId), {
    imgOrder,
  });
}

function moved(array, from, to, on = 1) {
  return (
    (array = array.slice()),
    array.splice(to, 0, ...array.splice(from, on)),
    array
  );
}

// export async function createArrayOrder(vehicleId, images) {
//   const arr = Object.keys(images);
//   console.log(arr);
//   return updateDoc(doc(db, "vehicles", vehicleId), {
//     imgOrder: arr,
//   });
// }

/********************* USER AVATAR CRUD START ********************************/
/**
 *
 * @param {*} userId
 * @param {*} fileId
 * @param {*} fileName
 * @param {string} url
 * @returns
 */
export async function addProfilePhoto(userId, fileId, fileName, url) {
  // console.log({path: `users/${userId}/photos/${fileId}`, url, fileName})
  const fileRef = doc(db, `users/${userId}/photos/${fileId}`);
  return await setDoc(fileRef, { url, fileName }, { merge: true });
}

/**
 * Ref to user photos
 * @param {string} userUid
 * @returns
 */
export function getUserPhotos(userUid) {
  return collection(db, `users/${userUid}/photos`);
}

/**
 *
 * @param {string} photoURL
 * @param {string} filename
 * @returns
 */
export async function updateUserProfilePhoto(photoURL, filename) {
  const user = auth.currentUser;
  const userDocRef = doc(db, `users/${user.uid}`);
  try {
    const userDoc = await getDoc(userDocRef);
    if (!userDoc.data().photoURL) await updateDoc(userDocRef, { photoURL });
    return await addDoc(collection(db, `users/${user.uid}/photos"`), {
      filename,
      photoURL,
    });
  } catch (error) {
    throw error;
  }
}
/**
 * Only can delete own photos
 * TODO updateProfile if last photo was deleted
 * @param {*} photoId
 * @returns
 */
export async function deletePhotoFromCollection(photoId) {
  const userUid = auth.currentUser.uid;
  return await deleteDoc(doc(db, `users/${userUid}/photos/${photoId}`));
}

/**
 * Sets current photo to Main avatar
 * @param {object} photo with url key
 * @returns Promise of updateDoc
 */
export async function setMainPhoto(photo, user = auth.currentUser) {
  try {
    await updateDoc(doc(db, "users", user.uid), { photoURL: photo.url });
    return await updateProfile(user, { photoURL: photo.url });
  } catch (error) {
    throw error;
  }
}

/********************* USER AVATAR CRUD END ********************************/
