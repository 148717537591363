const initialState = {
  vehicle: null,
  vehicles: [],
};

export function vehiclesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "LISTEN_TO_VEHICLE":
      return {
        ...state,
        vehicle: payload,
      };
    case "LISTEN_TO_VEHICLES":
      return {
        ...state,
        vehicles: payload,
      };
    default:
      return state;
  }
}
