import React from "react";
import { MdSearch } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../common/modals/modalReducer";
import { signOutFirebase } from "../firestore/firebaseService";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export const NavBar = () => {
  const { authenticated } = useSelector((state) => state.auth);

  return (
    <div className="bg-main text-white py-1 text-[10px] md:text-sm">
      <div className="container flex items-center space-x-4 mx-auto justify-between">
        <Link to="/vehicles" className="px-4 py-2 flex items-center space-x-4">
          <img src="/img/logo.png" alt="logo" className="max-h-5" />
          <span className="hidden sm:flex">Leaf Autos</span>
        </Link>
        {/* <div className="border hidden  border-white w-96 h-7 my-1 rounded lg:flex items-center justify-around space-x-4">
          <input
            type="text"
            className="bg-transparent flex-grow outline-none px-4"
          />
          <span className="text-[8px] leading-[10px] border border-white p-0.5 rounded bg-white bg-opacity-20">
            Alt + z
          </span>
          <span className="pr-2">
            <MdSearch className="" />
          </span>
        </div> */}
        <nav className="flex space-x-4">
          <NavLink to="/board" label="Board" />
          <NavLink to="/add" label="➕ Car" />
          <NavLink to="/vehicles" label="📂 All Cars" />
          <a
            href="https://github.com/jelizarovas/leaf-autos/issues"
            className="px-2 py-1 hover:bg-white hover:bg-opacity-20 hover:rounded"
            target="_blank"
            label="⚠️ Issue"
            rel="noreferrer"
          >
            ⚠️ Issues
          </a>
          <SignedInMenu />
        </nav>
      </div>
    </div>
  );
};

const NavLink = ({ label, to, ...props }) => (
  <Link
    className="px-2 py-1 hover:bg-white hover:bg-opacity-20 hover:rounded "
    to={to}
    {...props}
  >
    {label}
  </Link>
);

const SignedInMenu = (props) => {
  const history = useHistory();
  const { currentUser } = useSelector((state) => state.auth);

  async function handleSignOut() {
    try {
      await signOutFirebase();
      history.push("/");
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <>
      <NavLink
        to={`/user/${currentUser.uid}`}
        label={currentUser?.displayName || currentUser?.email || "User"}
      />
      <button
        onClick={() => handleSignOut()}
        type="button"
        className="px-2 py-1 hover:bg-white hover:bg-opacity-20 hover:rounded"
      >
        👉 Log Out
      </button>
    </>
  );
};

const SignedOutMenu = (props) => {
  const dispatch = useDispatch();
  // const { currentUserProfile } = useSelector((state) => state.profile);

  return (
    <button
      type="button"
      onClick={() => dispatch(openModal({ modalType: "LoginForm" }))}
    >
      Log In
    </button>
  );
};
