import React, { useState } from "react";
import { database } from "../../app/config/firebase";
import {
  ref as databaseRef,
  push,
  set,
  update,
  remove,
  onValue,
  off,
} from "firebase/database";
import { useDatabaseEntry } from "../../app/hooks/useDatabaseEntry";

const ref = databaseRef(database, "public/keys");
const Keys = () => {
  const [publicKeys, setPublicKeys] = useState([]);
  const [newKey, setNewKey] = useState("");

  const data = useDatabaseEntry("public/keys");

  const handleAddKey = () => {
    update(ref, { [newKey]: "label" });
    setNewKey("");
  };

  const handleRemoveKey = (key) => {
    remove(databaseRef(database, `public/keys/${key}`));
  };

  const handleMoveUp = (key) => {
    // onValue(ref, (snapshot) => {
    //   let keys = snapshot.val();
    //   const keyIndex = keys.indexOf(key);
    //   if (keyIndex > 0) {
    //     keys = [
    //       ...keys.slice(0, keyIndex - 1),
    //       key,
    //       keys[keyIndex - 1],
    //       ...keys.slice(keyIndex + 1),
    //     ];
    //     set(ref, keys);
    //     setPublicKeys([...keys]);
    //   }
    // });
  };

  const handleMoveDown = (key) => {
    // onValue(ref, (snapshot) => {
    //   let keys = snapshot.val();
    //   const keyIndex = keys.indexOf(key);
    //   if (keyIndex < keys.length - 1) {
    //     keys = [
    //       ...keys.slice(0, keyIndex),
    //       keys[keyIndex + 1],
    //       key,
    //       ...keys.slice(keyIndex + 2),
    //     ];
    //     set(ref, keys);
    //     setPublicKeys([...keys]);
    //   }
    // });
  };

  const handleEditKey = (key, oldText, newText) => {
    console.log(oldText, newText);

    update(ref, { [key]: newText });
    // onValue(ref, (snapshot) => {
    //   const keys = snapshot.val();
    //   const keyIndex = keys.indexOf(oldKey);
    //   keys[keyIndex] = newKey;
    //   set(ref, keys);
    //   setPublicKeys([...keys]);
    // });
  };

  const setKeys = async () => {
    return await set(ref, { make: "Make", model: "Model" });
  };

  React.useEffect(() => {
    setPublicKeys(data);
  }, [data]);

  return (
    <div>
      <h2>Public Keys Manager</h2>
      <div>
        <input
          type="text"
          value={newKey}
          onChange={(e) => setNewKey(e.target.value)}
        />
        <button onClick={handleAddKey}>Add Key</button>
      </div>
      <button type="button" onClick={() => setKeys()}>
        Set keys
      </button>

      <table>
        {publicKeys &&
          publicKeys.length > 0 &&
          publicKeys.map(({ key, label }) => (
            <tr>
              <td
                contentEditable="true"
                onBlur={(e) => handleEditKey(key, key, e.target.textContent)}
              >
                {key}
              </td>
              <td
                contentEditable="true"
                onBlur={(e) => handleEditKey(key, label, e.target.textContent)}
              >
                {label}
              </td>
              <td>
                {" "}
                <button onClick={() => handleRemoveKey(key)}>Remove</button>
                <button onClick={() => handleMoveUp(key)}>Move Up</button>
                <button onClick={() => handleMoveDown(key)}>Move Down</button>
              </td>
            </tr>
          ))}
      </table>
    </div>
  );
};

export default Keys;
