import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Link, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { BoardPage } from "../../features/board/BoardPage";
import { InputTest } from "../../features/board/InputTest";
import Keys from "../../features/keys/Keys";
import { ProfilePage } from "../../features/profiles/profilePage/ProfilePage";
import { UserPage } from "../../features/users/UserPage";
import { AddVehicle } from "../../features/vehicles/AddVehicle";
import { ListVehicle } from "../../features/vehicles/ListVehicle";
import { ListVehicles } from "../../features/vehicles/ListVehicles";
import { Pages } from "../../features/vehicles/Pages";
import { SellVehicle } from "../../features/vehicles/SellVehicle";
import { Test } from "../../features/vehicles/Test";
import { VehicleDocs } from "../../features/vehicles/VehicleDocs";
import { VehicleExpenses } from "../../features/vehicles/VehicleExpenses";
import { ComboBox } from "../common/form/ComboBox";
import { DateSelect } from "../common/form/DateSelect";
import { UserSelect } from "../common/form/UserSelect";
import { ModalManager } from "../common/modals/ModalManager";
import { NavBar } from "./NavBar";
import { PrivateRoute } from "./PrivateRoute";

const routes = [
  { path: "/", component: ListVehicles, exact: true, public: true },
  { path: "/v/:vehicleId", component: ListVehicle, exact: true },
  { path: "/v/:vehicleId/sell", component: SellVehicle, exact: true },
  { path: "/v/:vehicleId/docs", component: VehicleDocs, exact: true },
  { path: "/v/:vehicleId/expenses", component: VehicleExpenses, exact: true },
  { path: "/add", component: AddVehicle, exact: true },
  { path: "/vehicles", component: ListVehicles, exact: true },
  { path: "/board", component: BoardPage, exact: true },
  {
    path: ["/p/:userId", "/u/:userId", "/user/:userId"],
    component: UserPage,
    exact: true,
  },
  { path: "/pages", component: Pages, exact: true },
  { path: "/keys", component: Keys, exact: true },
  {
    path: "/test",
    component: () => (
      <div className="container mx-auto bg-white rounded py-20 px-4">
        <ComboBox />
      </div>
    ),
    exact: true,
  },
];

export const App = () => {
  const { authenticated } = useSelector((state) => state.auth);

  return (
    <div className="h-full  relative flex flex-col">
      <ModalManager />
      <ToastContainer position="bottom-right" hideProgressBar />
      {authenticated && <NavBar />}
      <Switch>
        {routes.map((r, i) => (
          <PrivateRoute key={i} {...r} />
        ))}
      </Switch>
    </div>
  );
};
