import { format, parse } from "date-fns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Input } from "../../app/common/form/Input";
import {
  getUser,
  updateUserInFirestore,
} from "../../app/firestore/firestoreService";
import useFirestoreDoc from "../../app/hooks/useFirestoreDoc";
import { sampleUsers } from "../vehicles/VehicleExpenses";
import { PhotoUploadWidget } from "./PhotoUploadWidget";
import { listenToSelectedUser } from "./userActions";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "UPDATE_INPUT":
      return { ...state, [payload.field]: payload.value };
    case "UPDATE_ALL":
      return { ...state, ...payload };
    case "SET_PUBLIC_VISIBILITY":
      return { ...state, isPublic: payload };
    case "CLEAR":
      return {};
    default:
      return state;
  }
};

export const UserPage = ({ match }) => {
  const dispatch = useDispatch();
  const { selectedUserProfile, currentUserProfile } = useSelector(
    (state) => state.users
  );
  const { currentUser } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.async);

  let profile;

  if (match.params.userId === currentUser.uid) {
    profile = currentUserProfile;
  } else {
    profile = selectedUserProfile;
  }

  const [state, localDispatch] = React.useReducer(reducer, profile);

  const save = (e) => {
    if (e.target.value === profile[e.target.name]) return;
    console.log(`Updating ${e.target.name} for ${profile.id}`);
    updateUserInFirestore(profile.id, {
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleOnChange = (e) => {
    localDispatch({
      type: "UPDATE_INPUT",
      payload: { field: e.target.name, value: e.target.value },
    });
  };

  useFirestoreDoc({
    query: () => getUser(match.params.userId),
    data: (user) => {
      dispatch(listenToSelectedUser(user));
    },
    deps: [dispatch, match.params.userId],
    shouldExecute: match.params.userId !== currentUser.uid,
  });

  React.useEffect(() => {
    localDispatch({
      type: "UPDATE_ALL",
      payload:
        match.params.userId === currentUser.uid
          ? currentUserProfile
          : selectedUserProfile,
    });
    return () => {
      localDispatch({ type: "CLEAR" });
    };
  }, [
    localDispatch,
    match.params.userId,
    currentUser.uid,
    currentUserProfile,
    selectedUserProfile,
  ]);

  if ((loading && !profile) || (!profile && !error))
    return <div>Loading profile... </div>;

  if (error) return <Redirect to="/error" />;

  return (
    <div
      key={match.params.userId}
      className="mx-auto container rounded bg-white my-2 px-2 py-2"
    >
      <h1>UserPage</h1>
      <div className="flex flex-col py-10">
        {sampleUsers.map((u) => (
          <Link
            to={`/user/${u.id}`}
            key={u.id}
            className="border px-2 py-1 my-1"
          >
            {u.displayName}
          </Link>
        ))}
      </div>
      <section className="flex items-start space-x-4">
        <img
          className="w-32"
          alt="profile"
          src={profile?.photoURL || "/img/user.png"}
        />
        <div className="flex flex-col">
          <Input
            name="displayName"
            value={state?.displayName}
            defaultValue={profile?.displayName}
            label="Full Name"
            placeholder="Full Name"
            onChange={handleOnChange}
            onBlur={save}
          />
          <div>Email: {profile?.email}</div>
          <div>Archived: {profile?.isArchived ? "Yes" : "No"}</div>
          <div>
            Created:
            {profile?.dateCreated}
          </div>
          <div>
            Last Updated:{" "}
            {profile?.dateUpdated && format(profile.dateUpdated, "MM/dd/yyyy")}{" "}
            {}
          </div>
        </div>
        
      </section>
      <PhotoUploadWidget />
      <div>
        <pre className="text-xs">{JSON.stringify(profile, null, 2)}</pre>
        <pre className="text-xs">{JSON.stringify(state, null, 2)}</pre>
      </div>
    </div>
  );
};
