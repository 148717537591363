import cuid from "cuid";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { uploadUserPhotoToFirebaseStorage } from "../../app/firestore/firebaseService";
import { updateUserProfilePhoto } from "../../app/firestore/firestoreService";
// import { getFileExtension } from "../util/util";
import { PhotoWidgetCropper } from "./PhotoWidgetCropper";
import { PhotoWidgetDropzone } from "./PhotoWidgetDropzone";
import { MdClear, MdAdd } from "react-icons/md";

export const PhotoUploadWidget = ({ setEditMode }) => {
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  function handleUploadImage() {
    setLoading(true);
    const shortId = cuid.slug();
    const filename = files[0].name;
    const uploadTask = uploadUserPhotoToFirebaseStorage(image, shortId);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress} % done`);
      },
      (error) => {
        toast.error(error.message);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          updateUserProfilePhoto(downloadURL, filename)
            .then(() => {
              setLoading(false);
              handleCancelCrop();
              setEditMode(false);
            })
            .catch((error) => {
              toast.error(error.message);
              setLoading(false);
            });
        });
      }
    );
  }

  function handleCancelCrop() {
    setFiles([]);
    setImage(null);
  }

  return (
    <div>
      <div width={4}>
        <h4 color="teal">Step 1 - Add Photo</h4>
        <PhotoWidgetDropzone setFiles={setFiles} />
      </div>
      <div width={1} />
      <div width={4}>
        <h4 color="teal">Step 2 - Resize</h4>
        {files.length > 0 && (
          <PhotoWidgetCropper
            setImage={setImage}
            imagePreview={files[0].preview}
          />
        )}
      </div>
      <div width={1} />
      <div width={4}>
        <h4 color="teal">Step 3 - Preview & Upload</h4>
        {files.length > 0 && (
          <>
            <div
              className="img-preview"
              style={{ minHeight: 200, minWidth: 200, overflow: "hidden" }}
            />
            <div>
              <button
                // loading={loading}
                onClick={handleUploadImage}
                style={{ width: 100 }}
                positive
              >
                <MdAdd /> Upload
              </button>
              <button
                // disabled={loading}
                onClick={handleCancelCrop}
                style={{ width: 100 }}
              >
                <MdClear /> Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
