import React from "react";
import { MdPerson, MdUnfoldLess, MdUnfoldMore } from "react-icons/md";
import { IoReload } from "react-icons/io5";
import { BiLoader } from "react-icons/bi";
import { RiErrorWarningLine } from "react-icons/ri";

import { DropDown } from "./DropDown";

const users = [
  {
    id: "ISr26clyThbq45HxSBZYJqcepXj2",
    photoUrl: "https://randomuser.me/api/portraits/men/59.jpg",
    displayName: "Arnas Jelizarovas",
    email: "jelizarovas@gmail.com",
  },
  {
    id: "Fk5EBwWXE2X5odI3iUrPWJ3U5Pq2",
    photoUrl: "https://randomuser.me/api/portraits/men/58.jpg",
    displayName: "Robert Tomasevskij",
    email: "robert@leafautos.com",
  },
  {
    id: "wp4sjE4C5FMZx8weEJ2gkdRXyMX2",
    photoUrl: "https://randomuser.me/api/portraits/men/57.jpg",
    displayName: "Julius Matusevicius",
    email: "julius@leafautos.com",
  },
  {
    id: "wp4sjE4C5FMZx8weEJ2gkdRXyMX2",
    photoUrl: "https://randomuser.me/api/portraits/men/37.jpg",
    displayName: "Julius Matusevicius",
    email: "julius@leafautos.com",
  },
  {
    id: "fwre54twe56",
    photoUrl: "https://randomuser.me/api/portraits/women/7.jpg",
    displayName: "Angie Jelizarovas",
    email: "kingang23@gmail.com",
  },
  {
    id: "wehytjr6345232wertwertwer",
    photoUrl: "https://randomuser.me/api/portraits/men/1.jpg",
    displayName: "Benas Jelizarovas",
    email: "bjelizarovas@gmail.com",
  },
  {
    id: "tsee5e65yserer32fasvzxbgngf",
    photoUrl: "https://randomuser.me/api/portraits/men/37.jpg",
    displayName: "Alvin Wong",
    email: "alvin@leafautos.com",
  },
];

const ListUserItem = (item) => {
  return (
    <div className="flex items-center space-x-2 text-sm m-2">
      <div>
        <img
          className="min-w-[2em] min-h-[2em] w-6 h-6 rounded-full"
          src={item?.photoUrl}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "img/user.png";
          }}
          alt=""
        />
      </div>
      <div>{item?.displayName}</div>
    </div>
  );
};

const selectUser = (u) => {
  return null;
};

const SelectButton = ({
  buttonRef,
  isOpen,
  close,
  open,
  selected,
  handleKeyDown,
  keyboardShortcut,
  label = "",
  Icon,
  isLoading,
  error,
  retryLoadData,
}) => (
  <button
    ref={buttonRef}
    type="button"
    onClick={isOpen ? close : open}
    onKeyDown={handleKeyDown}
    className={`w-full flex flex-nowrap items-center group  text-sm space-x-2 px-2 py-0.5 border rounded ${
      isOpen ? "ring-blue-100 ring-1" : ""
    } ring-blue-300 focus:ring-1  transition-all bg-opacity-0 bg-black hover:bg-opacity-5  select-none   `}
    disabled={!!error}
  >
    {selected ? (
      <>
        {selected?.photoUrl ? (
          <img
            className="w-6 h-6 rounded-full"
            src={selected?.photoUrl}
            alt=""
          />
        ) : (
          <MdPerson />
        )}
        <span className="flex-grow px-2 text-left">
          {selected?.displayName}
        </span>
        {keyboardShortcut && (
          <kbd className="text-[8px] invisible group-focus:visible border uppercase leading-tight p-0.5 rounded text-gray-400">
            {keyboardShortcut}
          </kbd>
        )}
      </>
    ) : (
      <>
        {Icon && <Icon />}
        <span className="text-gray-600 text-xs py-1 flex-grow text-left transition-all">
          Please select {label}...
        </span>
      </>
    )}
    <span
      onClick={error && retryLoadData}
      className="p-1 text-gray-500 group-hover:text-gray-700 transition-all bg-opacity-0 bg-black hover:bg-opacity-5 rounded-full "
    >
      {error ? (
        <span className="text-red-400 relative">
          <RiErrorWarningLine className="visible group-hover:invisible" />
          <IoReload className="invisible group-hover:visible absolute top-0 " />
        </span>
      ) : isLoading ? (
        <BiLoader className="animate-spin" />
      ) : !isOpen ? (
        <MdUnfoldMore />
      ) : (
        <MdUnfoldLess />
      )}
    </span>
  </button>
);

export const UserSelect = ({
  options = users,
  renderItem = ListUserItem,
  renderButton = SelectButton,
  defaultValue = null,
  onChange,
  value,
  onSelect = selectUser,
  label,
  disableSearch = false,
  Icon = null,
}) =>
  DropDown({
    options,
    renderItem,
    renderButton,
    defaultValue,
    value,
    onChange,
    onSelect,
    label,
    disableSearch,
    Icon,
  });
