import { format } from "date-fns";
import React, { useState } from "react";
import {
  MdCalendarToday,
  MdClear,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdSettings,
} from "react-icons/md";
import { DropDown } from "./DropDown";

export const DateSelect = ({
  renderButton = CalendarInput,
  RenderListContainer = Calendar,
  defaultValue = null,
  onChange = (e) => {
    console.log(e);
  },
  value,
  onSelect = () => {},
  label,
  disableSearch = false,
  Icon = null,
}) =>
  DropDown({
    RenderListContainer,
    renderButton,
    defaultValue,
    value,
    onChange,
    onSelect,
    label,
    disableSearch,
    Icon,
  });

const CalendarInput = ({
  value,
  buttonRef,
  isOpen,
  close,
  open,
  selected,
  onSelect,
  keyboardShortcut,
  label = "",
  Icon,
  isLoading,
  error,
  retryLoadData,
}) => {
  const [input, setInput] = React.useState(value || "");
  React.useEffect(() => {
    setInput(value);
  }, [value]);

  const handleChange = (e) => setInput(e.target.value);

  const handleKeyDown = (e) => {
    const { key } = e;

    if (!!e.target.value && key === "Tab") {
      e.preventDefault();
      e.stopPropagation();
      console.log(typeof e.target.value);
      if (isNaN(Number(e.target.value)))
        onSelect(parseDateText(e.target.value));
    }
  };
  // const date = new Date(input * 1000);
  // const dateString = format(new Date(date), "MM/dd/yyyy");

  return (
    <div className="flex items-center h-full w-full space-x-2 no-scrollbar overflow-x-hidden   text-xs">
      <button type="button" className="pl-2" onClick={isOpen ? close : open}>
        {Icon ? <Icon /> : <MdCalendarToday />}
      </button>
      <input
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={
          isNaN(Number(input))
            ? input
            : format(new Date(input * 1000), "EEE, MM/dd")
        }
        className="h-full w-full bg-transparent "
        type="search"
      />
    </div>
  );
};

export const Calendar = ({ close, onSelect, value, ...props }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(selectedDate.getMonth());
  const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
  const [startsOnMonday, setStartsOnMonday] = useState(true);
  const [showSettings, setShowSettings] = useState(false);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const focusContainer = React.useRef(null);

  const handleSelectDate = (day) => (e) => {
    console.log(day);
    const timestampSeconds =
      new Date(day.year, day.month, day.dayNumber).getTime() / 1000;
    onSelect(timestampSeconds);
    // setSelectedDate();
  };

  React.useEffect(() => {
    setSelectedDate(new Date(value * 1000));
  }, [value]);

  React.useEffect(() => {
    if (!focusContainer.current) return;
    focusContainer.current.focus();
  }, [selectedIndex]);

  const colCount = 7;
  const rowCount = 6;

  const handleKeyDown = (e) => {
    e.stopPropagation();

    const cellIndex = e.target.getAttribute("data-cellindex");
    const cellDate = e.target.getAttribute("data-date");

    if (e.key === "Tab" && e.shiftKey) {
      e.preventDefault();
      return setSelectedIndex((prevIndex) =>
        prevIndex % colCount === 0 ? prevIndex : prevIndex - 1
      );
    }
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();

        if ((Number(cellIndex) + 1) / 6 >= 6) {
          handleNextMonth(cellDate);
        }
        setSelectedIndex((prevIndex) =>
          prevIndex + colCount >= rowCount * colCount
            ? prevIndex
            : prevIndex + colCount
        );

        break;
      case "ArrowUp":
        e.preventDefault();
        if ((Number(cellIndex) + 1) / 6 <= 1) {
          handlePrevMonth(cellDate);
        }
        setSelectedIndex((prevIndex) =>
          prevIndex - colCount < 0 ? prevIndex : prevIndex - colCount
        );
        break;
      case "Escape":
        close(true);
        break;
      case "ArrowRight":
      case "Tab":
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          (prevIndex + 1) % colCount === 0 ? prevIndex : prevIndex + 1
        );
        break;
      case "ArrowLeft":
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex % colCount === 0 ? prevIndex : prevIndex - 1
        );
        break;
      case "Home":
        e.preventDefault();
        setSelectedIndex(
          (prevIndex) => Math.floor(prevIndex / colCount) * colCount
        );
        break;
      case "End":
        e.preventDefault();
        setSelectedIndex(
          (prevIndex) => Math.ceil((prevIndex + 1) / colCount) * colCount - 1
        );
        break;
      case "Enter":
      case " ":
        e.preventDefault();
        console.log(e.target);
        e.target.click();
        break;
      default:
        break;
    }
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayNames = startsOnMonday
    ? [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ]
    : [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

  const getToday = (t = new Date()) => ({
    dayNumber: t.getDate(),
    month: t.getMonth(),
    year: t.getFullYear(),
  });

  const today = getToday();

  const isToday = (day) =>
    day.dayNumber === today.dayNumber &&
    day.month === today.month &&
    day.year === today.year;

  const isSelected = (day) =>
    day.dayNumber === selectedDate.getDate() &&
    day.month === selectedDate.getMonth() &&
    day.year === selectedDate.getFullYear();

  const renderDayHeaders = (stringLength = 1) =>
    dayNames.map((dayName) => (
      <th key={dayName} className="day-header px-2 py-2">
        {stringLength ? dayName.substring(0, stringLength) : dayName}
      </th>
    ));

  React.useEffect(() => {
    setSelectedMonth(selectedDate.getMonth());
    setSelectedYear(selectedDate.getFullYear());
    // setSelectedIndex(null);
  }, [selectedDate]);
  React.useEffect(() => {
    setSelectedIndex((lastSelectedIndex) => {
      const monthStartingDayIndex = new Date(
        selectedYear,
        selectedMonth,
        1
      ).getDay();
      console.log({ lastSelectedIndex, monthStartingDayIndex });
      return startsOnMonday
        ? monthStartingDayIndex === 0
          ? 6
          : monthStartingDayIndex - 1
        : monthStartingDayIndex;
    });
  }, [selectedMonth, selectedYear, startsOnMonday, setSelectedIndex]);

  const handlePrevMonth = (cellDate) => {
    // console.log(cellDate);
    let newMonth = selectedMonth - 1;
    let newYear = selectedYear;
    if (newMonth < 0) {
      newMonth = 11;
      newYear -= 1;
    }
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
  };

  const handleNextMonth = (cellDate) => {
    let newMonth = selectedMonth + 1;
    let newYear = selectedYear;
    if (newMonth > 11) {
      newMonth = 0;
      newYear += 1;
    }
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
  };

  const handlePrevYear = () => {
    setSelectedYear(selectedYear - 1);
  };

  const handleNextYear = () => {
    setSelectedYear(selectedYear + 1);
  };

  const handleToggleWeekStarts = () => {
    setStartsOnMonday(!startsOnMonday);
  };

  if (showSettings)
    return (
      <div className="w-64 max-w-fit select-none rounded  p-1">
        <Settings
          handleToggleWeekStarts={handleToggleWeekStarts}
          startsOnMonday={startsOnMonday}
          close={() => setShowSettings(false)}
        />
      </div>
    );

  return (
    <div
      className="border  select-none rounded no-scrollbar overflow-x-hidden  "
      onKeyDown={handleKeyDown}
    >
      <div className="header bg-gray-200 pt-2">
        <div className="flex justify-around py-1  text-xs">
          <div className="flex items-center space-x-2">
            <ChangeButton onClick={handlePrevYear} Icon={MdKeyboardArrowLeft} />
            <span> {selectedYear}</span>
            <ChangeButton
              onClick={handleNextYear}
              Icon={MdKeyboardArrowRight}
            />
          </div>
          <div className="flex items-center  space-x-2">
            <ChangeButton
              onClick={handlePrevMonth}
              Icon={MdKeyboardArrowLeft}
            />
            <span className="w-8 text-center">
              {monthNames[selectedMonth]?.substring(0, 3)}
            </span>
            <ChangeButton
              onClick={handleNextMonth}
              Icon={MdKeyboardArrowRight}
            />
          </div>
          <div>
            <ChangeButton onClick={() => close(true)} Icon={MdClear} />
          </div>
        </div>
      </div>
      <table className="w-full max-w-[14rem]  ">
        <thead className="text-xs leading-none bg-gray-200">
          <tr>{renderDayHeaders()}</tr>
        </thead>
        <tbody className="">
          {renderMonth({ selectedYear, selectedMonth, startsOnMonday }).map(
            (week, wI) => (
              <tr key={wI}>
                {week.map((day, dI) => {
                  const cellIndex = wI * 7 + dI;
                  return (
                    <td
                      key={dI}
                      data-cellindex={cellIndex}
                      data-date={new Date(day.year, day.month, day.dayNumber)}
                      tabIndex={0}
                      // tabIndex={cellIndex === 0 ? 0 : -1}
                      ref={cellIndex === selectedIndex ? focusContainer : null}
                      onClick={handleSelectDate(day)}
                      onMouseDown={() => setSelectedIndex(cellIndex)}
                      onKeyDown={handleKeyDown}
                      className={`h-px bg-black bg-opacity-0  hover:bg-opacity-20 rounded-full cursor-pointer text-xs border-collapse box-content ring-inset focus:ring-1  focus:ring-red-500 focus-within:ring-1 focus-within:ring-red-300  ${
                        isSelected(day) ? "bg-blue-900 bg-opacity-50" : ""
                      } ${isToday(day) ? "font-bold text-blue-600" : ""}   ${
                        day.grayedOut ? "text-gray-400" : ""
                      } ${selectedIndex === cellIndex ? " bg-red-50" : ""}`}
                    >
                      <div className=" flex items-center justify-center w-8 h-8  ">
                        {day.dayNumber}
                      </div>
                    </td>
                  );
                })}
              </tr>
            )
          )}
        </tbody>
      </table>
      <div className="flex justify-between items-center">
        <ChangeButton
          label="Today"
          onClick={handleSelectDate(today)}
          className="text-gray-400"
        />
        <span className="text-xs text-gray-400">
          Selected: {selectedDate && selectedDate.toLocaleDateString()}
        </span>
        <ChangeButton
          Icon={MdSettings}
          onClick={() => setShowSettings(true)}
          className="opacity-25 px-2"
        />
      </div>
    </div>
  );
};

const ChangeButton = ({ Icon, className, label, onClick, ...props }) => (
  <button
    type="button"
    onClick={onClick && onClick}
    className={
      "py-1 px-1 text-xs flex items-center rounded-full bg-black bg-opacity-0 hover:bg-opacity-20 transition-all " +
      className
    }
    {...props}
  >
    {Icon && <Icon />}{" "}
    {label && <span className="text-xs px-1 py-0.5">{label}</span>}
  </button>
);

const Settings = ({
  startsOnMonday,
  handleToggleWeekStarts,
  close,
  ...props
}) => {
  return (
    <div className=" flex items-start flex-col w-48 h-48 border rounded p-2">
      <div className="header w-full flex items-center justify-between text-xs mb-4">
        <div className="flex space-x-2 items-center ">
          <MdSettings /> <span>Calendar Settings</span>
        </div>
        <button type="button" onClick={close}>
          <MdClear />
        </button>
      </div>
      <div className="settings flex space-x-2 text-xs">
        <input
          type="checkbox"
          checked={startsOnMonday}
          onChange={handleToggleWeekStarts}
          id="week-start-toggle"
        />
        <label htmlFor="week-start-toggle">Starts on Monday</label>
      </div>
    </div>
  );
};

const renderMonth = ({ selectedYear, selectedMonth, startsOnMonday }) => {
  // console.time("Render Month");
  let days = [];
  const firstDayOfMonth = new Date(selectedYear, selectedMonth, 1).getDay();
  const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
  const daysInPrevMonth = new Date(selectedYear, selectedMonth, 0).getDate();
  let currentDay = startsOnMonday
    ? firstDayOfMonth === 0
      ? 6
      : firstDayOfMonth - 1
    : firstDayOfMonth;
  let index = 1;

  for (let i = 0; i < 6; i++) {
    let week = [];
    for (let j = 0; j < 7; j++) {
      let day = {};
      if (i === 0 && j < currentDay) {
        day = {
          dayNumber: daysInPrevMonth - currentDay + j + 1,
          month: selectedMonth === 0 ? 11 : selectedMonth - 1,
          year: selectedMonth === 0 ? selectedYear - 1 : selectedYear,
          grayedOut: true,
        };
      } else if (index <= daysInMonth) {
        day = {
          dayNumber: index,
          month: selectedMonth,
          year: selectedYear,
          grayedOut: false,
        };
        index++;
      } else {
        day = {
          dayNumber: index - daysInMonth,
          month: selectedMonth === 11 ? 0 : selectedMonth + 1,
          year: selectedMonth === 11 ? selectedYear + 1 : selectedYear,
          grayedOut: true,
        };
        index++;
      }
      week.push(day);
    }
    days.push(week);
  }
  // console.timeEnd("Render Month");
  return days;
};

function parseDateText(str) {
  const now = new Date();
  switch (str) {
    case "today":
    case "td":
      return now.getTime() / 1000;
    default:
      return now.getTime() / 1000;
  }
}
