import { combineReducers } from "redux";
import authReducer from "../../features/auth/authReducer";
import { asyncReducer } from "../async/asyncReducer";
import modalReducer from "../common/modals/modalReducer";
import { connectRouter } from "connected-react-router";
import { vehiclesReducer } from "../../features/vehicles/vehiclesReducer";
import { userReducer } from "../../features/users/userReducer";
import { boardReducer } from "../../features/board/boardReducer";

// import { inventoryReducer, manageReducer } from "../../features/manage/manageReducer";

export const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    modals: modalReducer,
    auth: authReducer,
    async: asyncReducer,
    vehicles: vehiclesReducer,
    users: userReducer,
    board: boardReducer,
  });
