import React from "react";
import ReactDatePicker from "react-datepicker";
import {
  MdAdd,
  MdAttachMoney,
  MdCalendarToday,
  MdClear,
  MdCopyAll,
  MdDashboard,
  MdDeleteForever,
  MdEdit,
  MdMoney,
  MdPerson,
  MdTextSnippet,
  MdUploadFile,
} from "react-icons/md";
import { AiFillCalculator } from "react-icons/ai";
import { Input } from "../../app/common/form/Input";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import cuid from "cuid";
import useFirestoreDoc from "../../app/hooks/useFirestoreDoc";
import {
  listenToVehicleFromFirestore,
  updateVehicleInFirestore,
} from "../../app/firestore/firestoreService";
import { listenToVehicle } from "./vehiclesActions";
import { useParams } from "react-router-dom";
import { deleteField } from "firebase/firestore";
import { ComboBox } from "../../app/common/form/ComboBox";
import categories from "./categories";
import { UserSelect } from "../../app/common/form/UserSelect";

const sumExpenses = (expenses) =>
  round(
    Object.values(expenses).reduce((total, expense) => {
      return total + Number(expense?.amount);
    }, 0)
  );

export const VehicleExpenses = ({ vId }) => {
  const d = useDispatch();
  const { vehicle } = useSelector((state) => state.vehicles);
  const { loading, error } = useSelector((state) => state.async);
  let { vehicleId } = useParams();

  let expenses = vehicle?.expenses;

  useFirestoreDoc({
    query: () => listenToVehicleFromFirestore(vehicleId),
    data: (vehicle) => {
      d(listenToVehicle(vehicle));
    },
    deps: [d, vehicleId],
  });

  const [sortedExpenses, setSortedExpenses] = React.useState([]);
  const [sortBy, setSortBy] = React.useState("date");
  const [addExpenseOpen, setAddExpenseOpen] = React.useState(true);

  const addExpense = (newExpense) => {
    return updateVehicleInFirestore(vehicleId, {
      [`expenses.${cuid()}`]: newExpense,
    });
  };

  const removeExpense = (expenseId) => {
    return updateVehicleInFirestore(vehicleId, {
      [`expenses.${expenseId}`]: deleteField(),
    });
  };
  const updateExpenseField = (expenseId, field, value) => {
    return updateVehicleInFirestore(vehicleId, {
      [`expenses.${expenseId}.${field}`]: value,
    });
  };
  const deleteExpenseField = (expenseId, field) => {
    return updateVehicleInFirestore(vehicleId, {
      [`expenses.${expenseId}.${field}`]: deleteField(),
    });
  };

  const duplicateExpense = (expense) => {
    return updateVehicleInFirestore(vehicleId, {
      [`expenses.${cuid()}`]: expense,
    });
  };

  React.useEffect(() => {
    expenses && setSortedExpenses(sortData(expenses, sortBy));
  }, [expenses, sortBy]);

  if (loading) return <div>loading...</div>;
  if (error) return <div>error loading</div>;

  return (
    <div className="container mx-auto bg-white rounded px-2 my-2 lg:px-4 py-2 shadow-md">
      <div className="flex sm:items-center sm:justify-between flex-col sm:flex-row border-b px-2">
        <h1 className="uppercase text-sm font-semibold py-2 ">
          Vehicle Expenses
        </h1>
        {sortedExpenses.length === 0 && !addExpenseOpen && (
          <ActionButton
            Icon={MdAdd}
            label="New Expense"
            onClick={() => setAddExpenseOpen(true)}
          />
        )}
        {sortedExpenses.length > 0 && (
          <div className="flex space-x-2 items-center">
            <span className="text-xs">Sort by: </span>
            <ActionButton
              Icon={MdCalendarToday}
              className={
                sortBy === "date"
                  ? "bg-indigo-500 bg-opacity-100 hover:bg-opacity-80 text-white"
                  : ""
              }
              label="Date"
              onClick={() => setSortBy("date")}
            />
            <ActionButton
              Icon={MdMoney}
              label="Amount"
              className={
                sortBy === "amount"
                  ? "bg-indigo-500 bg-opacity-100 hover:bg-opacity-80 text-white"
                  : ""
              }
              onClick={() => setSortBy("amount")}
            />
          </div>
        )}
      </div>

      {sortedExpenses.length > 0 ? (
        <>
          <table className="w-full my-2">
            <thead>
              <tr className="text-left uppercase text-xs text-indigo-800  ">
                <th>File</th>
                <th>Decription</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Category</th>
                <th>By User</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedExpenses.map(([expenseId, expense]) => (
                <tr key={expenseId} className=" ">
                  <td>
                    {expense?.file ? (
                      <img src={expense.file?.thumb} alt="something" />
                    ) : (
                      <ActionButton
                        Icon={MdUploadFile}
                        label="Edit"
                        hideTextOnSmall
                        onClick={() => alert("Sorry, currently disabled!")}
                      />
                    )}
                  </td>
                  <td>{expense?.description}</td>
                  <td className="text-right px-2">{expense?.amount}</td>
                  <td>{expense?.date && format(expense.date, "MM/dd/yyyy")}</td>
                  <td>{expense?.category}</td>
                  <td>{expense?.user?.displayName?.split(" ")?.[0]}</td>
                  <td className="flex justify-around">
                    {/* <ActionButton Icon={MdEdit} label="Edit" hideTextOnSmall /> */}
                    <ActionButton
                      Icon={MdCopyAll}
                      label="Duplicate"
                      hideTextOnSmall
                      onClick={() => duplicateExpense(expense)}
                    />
                    <ActionButton
                      Icon={MdDeleteForever}
                      label="Delete"
                      onClick={() => removeExpense(expenseId)}
                      hideTextOnSmall
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2" className="text-xs ">
                  <div className="flex justify-between items-center  pr-4 py-2 ">
                    <ActionButton
                      Icon={MdAdd}
                      label="New Expense"
                      onClick={() => setAddExpenseOpen(true)}
                    />
                    <strong>Total</strong>
                  </div>
                </td>
                <td>
                  <strong>{sumExpenses(expenses)}</strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </>
      ) : (
        <div className="text-center flex flex-col items-center justify-center py-10">
          <img className="w-36" src="/img/spend-money.png" alt="no money" />
          <span className="text-xl mt-4">No expenses. </span>
          <span className="text-base text-gray-500">
            Consider spending some money
          </span>{" "}
        </div>
      )}
      {addExpenseOpen && (
        <AddExpense
          addExpense={addExpense}
          close={() => setAddExpenseOpen(false)}
        />
      )}
    </div>
  );
};

const ActionButton = ({
  Icon,
  label,
  className,
  hideTextOnSmall = false,
  ...props
}) => (
  <button
    type="button"
    className={
      `flex items-center space-x-2 text-xs py-1 px-2 rounded border bg-black bg-opacity-0  hover:bg-opacity-10 ` +
      className
    }
    {...props}
  >
    {Icon && <Icon />}{" "}
    <span className={hideTextOnSmall ? "hidden lg:block" : ""}>{label}</span>
  </button>
);

const AddExpense = ({ addExpense, close, ...props }) => {
  const { currentUser } = useSelector((state) => state.auth);
  const initialState = {
    date: new Date(),
    user: {
      name: "Arnas" || currentUser?.displayName || currentUser?.email,
      uid: currentUser?.uid,
    },
    // category: "Labor",
    // hours: 0,
    // amount: 0,
    // rate: 0,
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [flow, setFlow] = React.useState(true);

  React.useEffect(() => {
    if (state.hours && state.rate)
      dispatch({
        type: "UPDATE_DATA",
        payload: { amount: round(Number(state.hours) * Number(state.rate)) },
      });

    return () => {};
  }, [state.hours, state.rate]);

  React.useEffect(() => {
    if (state.category !== "Labor" && (state.hours || state.rate)) {
      dispatch({
        type: "UPDATE_DATA",
        payload: { hours: "", rate: "", amount: "" },
      });
    }
    return () => {};
  }, [state.category, state.hours, state.rate]);

  const onAmountChange = (e) => {
    const amount = e.target.value;
    if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
      return dispatch({
        type: "UPDATE_INPUT",
        payload: { field: e.target.name, value: amount },
      });
    }
  };

  const handleOnChange = (e) => {
    return dispatch({
      type: "UPDATE_INPUT",
      payload: { field: e.target.name, value: e.target.value },
    });
  };
  const handleSelect = ({ id, displayName, ...item }) => {
    return dispatch({
      type: "UPDATE_INPUT",
      payload: { field: "user", value: { uid: id, displayName } },
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const result = { ...state };

    const validate = (result) => {
      let isValid = false,
        error = "Something went wrong";

      if (!result.amount) error = "Please enter amount";
      if (!result.description) error = "Please add a description";

      if (result.amount && result.description) isValid = true;

      return [isValid, error];
    };

    const [isValid, error] = validate(result);

    if (result.hours && result.rate) {
      result.description += ` ${result.hours} hrs x $${result.rate}/hr`;
      result.description.trim();
      delete result.rate;
      delete result.hours;
    }
    result.date = result.date.getTime();
    if (!result.category) result.category = "Other";

    if (isValid) dispatch({ type: "CLEAR", payload: initialState });
    return isValid ? addExpense(result) : alert(error);
  };

  return (
    <div className="container mx-auto bg-slate-50 rounded border my-2 ">
      <form onSubmit={onSubmit} className="px-4 my-4 py-4  rounded">
        <div className="flex items-center justify-between">
          <h2>Adding a new expense</h2>
          <ActionButton
            label="Close"
            Icon={MdClear}
            onClick={close}
            hideTextOnSmall
          />
        </div>

        <ActionButton
          label="Upload File or Photo"
          Icon={MdUploadFile}
          name="file"
          onClick={(e) => alert("Sorry, currently disabled")}
        />

        <ComboBox
          Icon={MdDashboard}
          name="category"
          label="Category"
          value={state.category}
          onChange={handleOnChange}
          items={categories}
        />

        <Input
          Icon={MdTextSnippet}
          name="description"
          label="Description"
          value={state.description}
          onChange={handleOnChange}
        />

        <UserSelect
          label="User"
          disableSearch={true}
          Icon={MdPerson}
          options={sampleUsers}
          defaultValue={sampleUsers.find((user) => user.id === currentUser.uid)}
          value={state.user}
          onSelect={handleSelect}
        />

        {state?.category?.toLowerCase() === "labor" ? (
          <div className="max-w-lg">
            <label htmlFor="amount" className="text-xs px-2">
              Amount
            </label>
            <div className="flex text-sm items-center px-2 space-x-2 border rounded bg-white">
              <AiFillCalculator />
              <input
                type="number"
                name="hours"
                step="0.01"
                placeholder="Hours"
                className="w-16 py-1 px-2"
                value={state.hours}
                onChange={onAmountChange}
              />
              <span>×</span>
              <input
                type="number"
                name="rate"
                step="0.01"
                placeholder="Rate $/hr"
                className="w-20 py-1 px-2"
                value={state.rate}
                onChange={onAmountChange}
              />
              <span>=</span>
              <span className="text-gray-500">{!!state.amount && "$"}</span>
              <input
                type="number"
                name="amount"
                step="0.01"
                placeholder="Total amount"
                className="w-24 p-1"
                value={state.amount}
                onChange={onAmountChange}
              />
            </div>
          </div>
        ) : (
          <Input
            Icon={MdAttachMoney}
            name="amount"
            step="0.01"
            label="Amount"
            type="number"
            value={state.amount}
            onChange={onAmountChange}
          />
        )}

        <div className="py-0 max-w-lg">
          <label htmlFor="date" className="text-xs px-2">
            Date
          </label>
          <div className="flex items-center px-2 border text-sm bg-white  rounded">
            <MdCalendarToday />
            <ReactDatePicker
              className="w-full px-2 py-1"
              name="date"
              selected={state.date}
              onChange={(value) =>
                dispatch({
                  type: "UPDATE_INPUT",
                  payload: { field: "date", value },
                })
              }
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </div>
        </div>
        {/* <div>
          <label htmlFor="user" className="text-xs px-2">
            By User
          </label>
          <div className="flex items-center border rounded px-2 space-x-2 py-1 max-w-lg  ">
            <div className="img w-4 h-4 rounded-lg border bg-slate-200"></div>
            <div className="flex flex-col text-sm">
              <span>{currentUser?.displayName || currentUser?.email} </span>
              {/* <span className="text-[10px] text-gray-500">
              {currentUser?.uid}{" "}
            </span> 
            </div>
          </div>
        </div> */}
        <div className="flex space-x-6 mt-10">
          <ActionButton label="Cancel" />
          <ActionButton
            label="Clear"
            onClick={() => dispatch({ type: "CLEAR" })}
          />
          <ActionButton
            label="Add Expense"
            type="submit"
            className="bg-green-400 bg-opacity-30"
          />
        </div>
      </form>
    </div>
  );
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "UPDATE_INPUT":
      return { ...state, [payload.field]: payload.value };
    case "UPDATE_DATA":
      return { ...state, ...payload };
    case "CLEAR":
      return { ...payload, date: new Date() };
    default:
      return state;
  }
};

const sortData = (dataSetObj, sortBy) =>
  Object.entries(dataSetObj).sort(sortFun(sortBy));
const sortFun = (key) => {
  //   console.log(key);

  return ([aId, a], [bId, b]) => {
    // console.log({ a, b });
    // console.log(new Date(a.dateAdded) - new Date(b.dateAdded));
    switch (key) {
      case "amount":
        return Number(a.amount) - Number(b.amount);
      default:
        return new Date(a.date) - new Date(b.date);
    }
  };
};

const round = (value, decimals = 2) => {
  if (!value) throw new Error("Must include value to be rounded");
  if (!(typeof value === "string" || typeof value === "number"))
    throw new Error("Value must me a number or string");
  if (!(typeof decimals === "string" || typeof decimals === "number"))
    throw new Error("Decimals [2nd arg] must me a number or string");
  if (isNaN(value) || isNaN(decimals))
    throw new Error("Args need to be numbers");
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

const sampleExpenses = {
  asdfasdf435: {
    description: "Gas",
    amount: 50,
    date: 1673497843859,
    user: { id: "sdfasdfasdfasd", name: "Arnas" },
  },
  awer43455346654: {
    description: "Bumper",
    amount: 250,
    date: 1673497813359,
    user: { id: "34grwtwert", name: "Julius" },
  },
  f45wgswerfsd: {
    description: "Fuse",
    amount: 5,
    date: 1673497813859,
    user: { id: "34grwtwert", name: "Julius" },
  },
};

export const sampleUsers = [
  {
    id: "Fk5EBwWXE2X5odI3iUrPWJ3U5Pq2",
    photoUrl:
      "https://scontent-sea1-1.xx.fbcdn.net/v/t1.18169-1/13321801_1021307791297115_7750120596116337246_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=106&ccb=1-7&_nc_sid=7206a8&_nc_ohc=n5RsukFa2ScAX_BLkv4&tn=l2_ROlbKLHBaMtG6&_nc_ht=scontent-sea1-1.xx&oh=00_AfAknb4GP8gPQsNacf6Ught6fGL8VC260mcBGtvUm8qYFg&oe=63FD012C",
    displayName: "Robert Tomasevskij",
    email: "robert@leafautos.com",
  },
  {
    id: "wp4sjE4C5FMZx8weEJ2gkdRXyMX2",
    photoUrl:
      "https://scontent-sea1-1.xx.fbcdn.net/v/t1.18169-1/10710680_722256081145019_5307196988397725922_n.jpg?stp=cp0_dst-jpg_p50x50&_nc_cat=109&ccb=1-7&_nc_sid=41a7af&_nc_ohc=SxON5njbSzEAX-QW9I9&_nc_ht=scontent-sea1-1.xx&oh=00_AfBIi_SgPxfbPHvc26l3znGI9TroS5hn8vWHuTYLalIeRg&oe=63FD235E",
    displayName: "Julius Matusevicius",
    email: "julius@leafautos.com",
  },
  {
    id: "ISr26clyThbq45HxSBZYJqcepXj2",
    photoUrl:
      "https://scontent-sea1-1.xx.fbcdn.net/v/t1.6435-1/70656451_1285416798304882_3079391336760082432_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=111&ccb=1-7&_nc_sid=7206a8&_nc_ohc=XF-MVA__4kMAX-o-gPa&_nc_ht=scontent-sea1-1.xx&oh=00_AfAJBD38ipjwmXdGWT1qb1edgqbHi4KTK3yJHL_JjzBp8Q&oe=63FD3211",
    displayName: "Arnas Jelizarovas",
    email: "jelizarovas@gmail.com",
  },
];
