import {
  LISTEN_TO_CURRENT_USER,
  LISTEN_TO_SELECTED_USER,
  LISTEN_TO_USER_PHOTOS,
} from "./userConstants";

export function listenToCurrentUser(user) {
  return {
    type: LISTEN_TO_CURRENT_USER,
    payload: user,
  };
}
export function listenToSelectedUser(user) {
  return {
    type: LISTEN_TO_SELECTED_USER,
    payload: user,
  };
}

export function listenToUserPhotos(photos) {
  return {
    type: LISTEN_TO_USER_PHOTOS,
    payload: photos,
  };
}
