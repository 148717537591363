import { toast } from "react-toastify";
import {
  storage,
  functions,
  auth,
  database,
  customStorage,
} from "../../app/config/firebase";
import {
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
  updatePassword,
} from "firebase/auth";
import { setUserProfileData } from "./firestoreService";
import {
  deleteObject,
  getDownloadURL,
  ref,
  listAll,

  // uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import {
  ref as databaseRef,
  push,
  set,
  update,
  remove,
} from "firebase/database";

// import cuid from "cuid";

export async function newUploadSession(sessionId, data) {
  const user = auth.currentUser;
  console.log({ sessionId });
  return set(databaseRef(database, `uploads/${sessionId}`), {
    user: user.displayName,
    created: Date.now(),
    ...data,
  });
}
export async function updateUploadSession(sessionId, data) {
  console.log(data);
  return update(databaseRef(database, `uploads/${sessionId}`), {
    ...data,
    updated: Date.now(),
  });
}
export async function deleteUploadSession(sessionId) {
  //@TODO check if there were any files uploaded, and then delete them.

  return remove(databaseRef(database, `uploads/${sessionId}`));
}

export async function getFileDownloadUrl(refPath) {
  const pathReference = ref(storage, refPath);
  return await getDownloadURL(pathReference);
}

export function firebaseObjectToArray(snapshot) {
  if (snapshot) {
    return Object.entries(snapshot).map((e) =>
      Object.assign({}, e[1], { id: e[0] })
    );
  }
}

export function uploadFileToFirebaseStorage({
  storagePath = "/temp/",
  file,
  fileId,
  sessionId,
  bucket,
}) {
  var metadata = {
    customMetadata: {
      fileId,
      sessionId,
    },
  };

  const storageRef = ref(
    bucket ? customStorage(bucket) : storage,
    `${storagePath}/${fileId}/${file.name}`
  );
  return uploadBytesResumable(storageRef, file, metadata);
}

export function signInWithEmail(creds) {
  return signInWithEmailAndPassword(auth, creds.email, creds.password);
}

export function signOutFirebase() {
  return signOut(auth);
}

export async function registerInFirebase(creds) {
  try {
    const result = await createUserWithEmailAndPassword(
      auth,
      creds.email,
      creds.password
    );
    await updateProfile(result.user, { displayName: creds.displayName });
    return await setUserProfileData(result.user);
    // return await setUserProfileData(result.user);
  } catch (error) {
    throw error;
  }
}

export async function socialLogin(selectedProvider) {
  let provider;

  if (selectedProvider === "facebook") {
    provider = new FacebookAuthProvider();
  }
  if (selectedProvider === "google") {
    provider = new GoogleAuthProvider();
  }

  try {
    const result = await signInWithPopup(auth, provider);
    console.log(result);
    if (result.additionalUserInfo.isNewUser) {
      //TODO check if user/email is authorised - if not kick him out
      // await checkIfAutorisedSignUp()
      await setUserProfileData(result.user);
      //   await setUserProfileData(result.user);
    }
  } catch (error) {
    toast.error(error.message);
  }
}

export function updateUserPassword(creds) {
  const user = auth.currentUser;
  return updatePassword(user, creds.newPassword);
}

export async function deleteImageAndThumb(
  vehicleId,
  imageId,
  bucket = "leafautosphotos"
) {
  const storage = customStorage(bucket);
  const storageFolderRef = ref(storage, `vehicles/${vehicleId}/${imageId}`);

  return listAll(storageFolderRef).then(function (result) {
    result.items.forEach(function (fileRef) {
      return deleteFileFromFirebaseStorage(fileRef);
    });
  });
}

export function deleteFileFromFirebaseStorage(storagePath) {
  const storageRef = ref(customStorage("leafautosphotos"), storagePath);
  console.log("DELETING", storagePath);
  return deleteObject(storageRef);
}

export function uploadUserPhotoToFirebaseStorage(file, fileId) {
  const user = auth.currentUser;
  return uploadFileToFirebaseStorage({
    storagePath: `user_images/${user.uid}/profile`,
    file,
    fileId,
  });
}
