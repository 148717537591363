import { LISTEN_TO_ACTIVE_SALES } from "./boardConstants";

const initialState = {
  activeSales: null,
};

export function boardReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LISTEN_TO_ACTIVE_SALES:
      return {
        ...state,
        activeSales: payload,
      };
    default:
      return state;
  }
}
