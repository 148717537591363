export function listenToVehicle(vehicle) {
  return {
    type: "LISTEN_TO_VEHICLE",
    payload: vehicle,
  };
}
export function listenToVehicles(vehicles) {
  return {
    type: "LISTEN_TO_VEHICLES",
    payload: vehicles,
  };
}
