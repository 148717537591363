import {
  LISTEN_TO_CURRENT_USER,
  LISTEN_TO_SELECTED_USER,
  LISTEN_TO_USER_PHOTOS,
} from "./userConstants";

const initialState = {
  currentUserProfile: null,
  selectedUserProfile: null,
  photos: [],
};

export function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LISTEN_TO_CURRENT_USER:
      return {
        ...state,
        currentUserProfile: payload,
      };
    case LISTEN_TO_SELECTED_USER:
      return {
        ...state,
        selectedUserProfile: payload,
      };
    case LISTEN_TO_USER_PHOTOS:
      return {
        ...state,
        photos: payload,
      };
    default:
      return state;
  }
}
