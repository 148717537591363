import React from "react";
import ReactDOM from "react-dom";
import "react-toastify/dist/ReactToastify.min.css";
import "react-calendar/dist/Calendar.css";
import "./app/layout/styles.css";
import { App } from "./app/layout/App";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { configureStore, history } from "./app/store/configureStore";
import ScrollToTop from "./app/layout/ScrollToTop";
import { ConnectedRouter } from "connected-react-router";

const store = configureStore();

const rootEl = document.getElementById("root");

function render() {
  ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ScrollToTop />
        
        <App />
      </ConnectedRouter>
    </Provider>,
    // </React.StrictMode>
    rootEl
  );
}
//GIVING ERRORS ON REFRESH HAD TO DISABLE IT
// if (module.hot) {
//   module.hot.accept(<App />, function () {
//     setTimeout(render);
//   });
// }

render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
