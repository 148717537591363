import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AddressInput } from "../../app/common/form/AddressInput";
import { Input } from "../../app/common/form/Input";
import { money_round } from "../../app/common/util/helperFunctions";
import {
  deleteVehicleInFirestore,
  listenToVehicleFromFirestore,
} from "../../app/firestore/firestoreService";
import useFirestoreDoc from "../../app/hooks/useFirestoreDoc";
import { listenToVehicle } from "./vehiclesActions";
import { PDFDocument, rgb, TextAlignment } from "pdf-lib";
import download from "downloadjs";
import { MdClear } from "react-icons/md";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "UPDATE_INPUT":
      return { ...state, [payload.field]: payload.value };
    case "UPDATE_ALL":
      return { ...state, ...payload };
    case "SET_ADDRESS":
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const SellVehicle = ({ match }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    salesTaxRate: 10.55,
    registration: 200,
    docFee: 150,
    cardFeeRate: 3,
    address: "",
    address2: "",
    mileage: 0,
    tradeinAllowance: 0,
    tradeinPayoff: 0,
    date: getFormattedDate(new Date()),
    ...dealer,
  });

  const [showTradeIn, setShowTradeIn] = React.useState(false);
  const [showSecondaryContact, setShowSecondaryContact] = React.useState(false);
  const [loadingPDF, setLoadingPDF] = React.useState(false);

  const handleOnChange = (e) =>
    dispatch({
      type: "UPDATE_INPUT",
      payload: { field: e.target.name, value: e.target.value },
    });

  const d = useDispatch();
  const { vehicle } = useSelector((state) => state.vehicles);
  const { loading, error } = useSelector((state) => state.async);
  useFirestoreDoc({
    query: () => listenToVehicleFromFirestore(match.params.vehicleId),
    data: (vehicle) => {
      dispatch({ type: "UPDATE_ALL", payload: vehicle });
      return d(listenToVehicle(vehicle));
    },
    deps: [d, match.params.vehicleId],
  });

  const recalculateSellingPrice = (e) => {
    //@TODO account for tradeIN
    // alert("This doesn't work with tradeins yet");
    return null;
    if (
      e.target.value ===
      money_round(
        Number(state.sellingPrice) +
          Number(state.salesTax) +
          Number(state.registration) +
          Number(state.docFee)
      )
    )
      return null;
    console.log(e.target.value);

    dispatch({
      type: "UPDATE_INPUT",
      payload: {
        field: "sellingPrice",
        value: money_round(
          (Number(e.target.value) - Number(state.registration)) /
            (1 + Number(state.salesTaxRate) / 100) -
            Number(state.docFee)
        ),
      },
    });
  };

  React.useEffect(() => {
    dispatch({
      type: "UPDATE_INPUT",
      payload: {
        field: "salesTax",
        value: money_round(
          ((Number(state.sellingPrice) +
            Number(state.docFee) -
            Number(state.tradeinAllowance)) *
            Number(state.salesTaxRate)) /
            100
        ),
      },
    });
  }, [
    state.sellingPrice,
    state.salesTaxRate,
    state.docFee,
    state.tradeinAllowance,
  ]);
  React.useEffect(() => {
    dispatch({
      type: "UPDATE_INPUT",
      payload: {
        field: "cardFee",
        value: money_round(
          (Number(state.totalBeforeAdjustment) * Number(state.cardFeeRate)) /
            100
        ),
      },
    });
  }, [state.cardFeeRate, state.totalBeforeAdjustment]);
  React.useEffect(() => {
    dispatch({
      type: "UPDATE_INPUT",
      payload: {
        field: "totalBeforeAdjustment",
        value: money_round(
          Number(state.sellingPrice) -
            Number(state.tradeinAllowance) +
            Number(state.tradeinPayoff) +
            Number(state.salesTax) +
            Number(state.registration) +
            Number(state.docFee)
        ),
      },
    });
  }, [
    state.sellingPrice,
    state.salesTax,
    state.registration,
    state.docFee,
    state.tradeinAllowance,
    state.tradeinPayoff,
  ]);
  React.useEffect(() => {
    dispatch({
      type: "UPDATE_INPUT",
      payload: {
        field: "totalToCollect",
        value: money_round(
          Number(state.totalBeforeAdjustment) + Number(state.cardFee)
        ),
      },
    });
  }, [state.totalBeforeAdjustment, state.cardFee]);

  if (!vehicle) return <div>Loading vehicle</div>;

  return (
    <div className="container mx-auto px-4 py-2 md:py-10 md:px-10 bg-white md:rounded-xl md:mt-2 shadow-lg flex flex-col space-y-2">
      <h1>
        Selling {vehicle?.ModelYear} {vehicle?.Make} {vehicle?.Model}
      </h1>
      <ClientInfo
        state={state}
        handleOnChange={handleOnChange}
        dispatch={dispatch}
      />
      {showSecondaryContact ? (
        <ClientInfo2
          state={state}
          handleOnChange={handleOnChange}
          dispatch={dispatch}
          setShowSecondaryContact={setShowSecondaryContact}
        />
      ) : (
        <button
          className="w-64 border rounded text-sm bg-black bg-opacity-0 hover:bg-opacity-20 focus:bg-opacity-10 focus:bg-main transition-all"
          type="text"
          onClick={() => setShowSecondaryContact(true)}
        >
          Add Person
        </button>
      )}
      {showTradeIn ? (
        <TradeIn
          state={state}
          handleOnChange={handleOnChange}
          dispatch={dispatch}
          setShowTradeIn={setShowTradeIn}
        />
      ) : (
        <button
          className="w-64 border rounded text-sm bg-black bg-opacity-0 hover:bg-opacity-20 focus:bg-opacity-10 focus:bg-main transition-all"
          type="text"
          onClick={() => setShowTradeIn(true)}
        >
          Add TradeIn
        </button>
      )}
      <Input
        label="Selling price"
        name="sellingPrice"
        value={state.sellingPrice}
        placeholder="5995"
        type="number"
        onChange={handleOnChange}
      />
      <Input
        label="Sales Tax Rate"
        name="salesTaxRate"
        value={state.salesTaxRate}
        placeholder="10"
        type="number"
        onChange={handleOnChange}
      />
      <Input
        label="Sales Tax"
        name="salesTax"
        value={state.salesTax}
        type="number"
        onChange={handleOnChange}
        readOnly
      />

      <Input
        label="Registration"
        name="registration"
        value={state.registration}
        placeholder="200"
        type="number"
        onChange={handleOnChange}
      />
      <Input
        label="Documentary Service Fee"
        name="docFee"
        value={state.docFee}
        placeholder="150"
        type="number"
        onChange={handleOnChange}
      />
      <Input
        label="Total Before Adjustment"
        name="totalBeforeAdjustment"
        value={state.totalBeforeAdjustment}
        placeholder="Total Before Adjustment"
        type="number"
        onChange={handleOnChange}
        onBlur={recalculateSellingPrice}
      />
      <Input
        label="Credit Card Fee Rate"
        name="cardFeeRate"
        value={state.cardFeeRate}
        placeholder="Usually 3%"
        type="number"
        onChange={handleOnChange}
      />
      <Input
        label="Credit Card Fee"
        name="cardFee"
        value={state.cardFee}
        placeholder={state.sellingPrice * 0.03}
        type="number"
        onChange={handleOnChange}
      />
      <Input
        label="Total To Collect"
        name="totalToCollect"
        value={state.totalToCollect}
        placeholder="Money in the bank!!!"
        type="number"
        onChange={handleOnChange}
      />
      <div>
        <ul>
          <li>
            {" "}
            {`${vehicle["year"]} ${vehicle["make"]} ${vehicle["model"]}`}
          </li>
          <li>{vehicle["vin"]}</li>
          <li>
            <Input
              type="number"
              name="mileage"
              onChange={handleOnChange}
              label="Mileage"
              value={state.mileage}
            />
          </li>
        </ul>
      </div>
      <div>
        <button
          type="button"
          className="bg-indigo-700 text-white px-4 py-2 rounded my-2"
          onClick={async () => {
            setLoadingPDF(true);
            await fillPDF({ showSecondaryContact, ...state });
            setLoadingPDF(false);
          }}
        >
          {loadingPDF ? "Loading" : "Get paperwork"}
        </button>
      </div>
      {/* <div className="text-xs">
        <pre>{JSON.stringify(state, null, 2)}</pre>
      </div> */}
    </div>
  );
};

const ClientInfo = ({ state, handleOnChange, dispatch }) => {
  return (
    <div>
      <h2>Main Contact</h2>
      <Input
        label="Full Name"
        name="fullName"
        value={state.fullName}
        onChange={handleOnChange}
      />
      <Input
        label="Phone"
        name="phone"
        value={state.phone}
        onChange={handleOnChange}
      />
      <AddressInput
        name="address"
        label="Address"
        value={state.address}
        dispatch={dispatch}
      />
    </div>
  );
};
const ClientInfo2 = ({
  state,
  handleOnChange,
  dispatch,
  setShowSecondaryContact,
}) => {
  return (
    <div>
      <div className="flex justify-between w-96">
        <h2>Secondary Contact</h2>
        <button
          tabIndex={-1}
          className="px-1 focus:bg-opacity-20 focus:bg-red-500 hover:bg-opacity-20 bg-black bg-opacity-0 rounded-full transition-all"
          onClick={() => {
            setShowSecondaryContact(false);
            dispatch({
              type: "UPDATE_ALL",
              payload: {
                fullName2: "",
                phone2: "",
                address2: "",
              },
            });
          }}
          type="text"
        >
          <MdClear />
        </button>
      </div>
      <Input
        label="Full Name"
        name="fullName2"
        value={state.fullName2}
        onChange={handleOnChange}
        autoFocus={true}
      />
      <Input
        label="Phone"
        name="phone2"
        value={state.phone2}
        onChange={handleOnChange}
      />
      <AddressInput
        label="Address"
        name="address2"
        value={state.address2}
        dispatch={dispatch}
      />
    </div>
  );
};

const TradeIn = ({ state, handleOnChange, dispatch, setShowTradeIn }) => {
  return (
    <div>
      <div className="flex justify-between w-96">
        <h2>Trade In</h2>
        <button
          tabIndex={-1}
          className="px-1 focus:bg-opacity-20 focus:bg-red-500 hover:bg-opacity-20 bg-black bg-opacity-0 rounded-full transition-all"
          onClick={() => {
            setShowTradeIn(false);
            dispatch({
              type: "UPDATE_ALL",
              payload: {
                tradeinYear: "",
                tradeinMake: "",
                tradeinModel: "",
                tradeinColor: "",
                tradeinBodyStyle: "",
                tradeinVin: "",
                tradeinMileage: "",
                tradeinAllowance: 0,
                tradeinPayoff: 0,
              },
            });
          }}
          type="text"
        >
          <MdClear />
        </button>
      </div>

      <Input
        label="Year"
        name="tradeinYear"
        value={state.tradeinYear}
        onChange={handleOnChange}
      />
      <Input
        label="Make"
        name="tradeinMake"
        value={state.tradeinMake}
        onChange={handleOnChange}
      />
      <Input
        label="Model"
        name="tradeinModel"
        value={state.tradeinModel}
        onChange={handleOnChange}
      />
      <Input
        label="Color"
        name="tradeinColor"
        value={state.tradeinColor}
        onChange={handleOnChange}
      />
      <Input
        label="Body Style"
        name="tradeinBodyStyle"
        value={state.tradeinBodyStyle}
        onChange={handleOnChange}
      />
      <Input
        label="Vin"
        name="tradeinVin"
        value={state.tradeinVin}
        onChange={handleOnChange}
      />
      <Input
        label="Mileage"
        name="tradeinMileage"
        value={state.tradeinMileage}
        onChange={handleOnChange}
      />
      <Input
        label="Allowance"
        name="tradeinAllowance"
        value={state.tradeinAllowance}
        onChange={handleOnChange}
      />
      <Input
        label="Payoff"
        name="tradeinPayoff"
        value={state.tradeinPayoff}
        onChange={handleOnChange}
      />
    </div>
  );
};

async function fillPDF(data) {
  // const pdfDoc = await PDFDocument.create();
  // const form = pdfDoc.getForm();

  // const pdfs = ["pdf/Arbitration agreement.pdf", "Buyers Guide mk.pdf","As Is Disclaimer.pdf","pdf/Buyers order.PDF", ];

  const baseUrl =
    window.location.origin.toString() + process.env.PUBLIC_URL + "/";
  const mergeDoc = await PDFDocument.create();

  for (const pdf of pdfs) {
    const formUrl = baseUrl + pdf.name;
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const form = pdfDoc.getForm();
    Object.entries(pdf.fields).forEach(([fieldName, f]) => {
      //   console.log({ fieldName, value: data[f] });
      setField(fieldName, data[f], form);
    });

    form.flatten();
    await pdfDoc.save();

    const coppiedTempPages = await mergeDoc.copyPages(
      pdfDoc,
      pdfDoc.getPageIndices()
    );

    coppiedTempPages.forEach((page) => mergeDoc.addPage(page));
  }

  const now = new Date();

  return download(
    await mergeDoc.save(),
    `DOCs ${data.year} ${data.make} ${data.model} ${
      data.fullName
    } ${getFormattedDate(now, " ")}.pdf`,
    "application/pdf"
  );
}

const setField = (pdfField, value, form, condition = false) => {
  if (!pdfField || !value || condition) return;
  if (Array.isArray(value)) value = value.join(", ");
  const f = form.getTextField(pdfField);
  if (!f) return;
  return f.setText(value.toString());
};

const pdfs = [
  {
    name: "pdf/Arbitration agreement.pdf",
    fields: {
      "DEALER NAME": "dealerName",
      "DEALER ADDRESS": "dealerAddress",
      NAME: "fullName",
      ADDRESS: "address",
      NAME2: "fullName2",
      ADDRESS2: "address2",
      DATE: "date",
    },
  },
  {
    name: "pdf/Buyers Guide.pdf",
    fields: {
      "DEALER NAME": "dealerName",
      "DEALER ADDRESS": "dealerAddress",
      "DEALER PHONE": "dealerPhone",
      "DEALER EMAIL": "dealerEmail",
      DATE: "date",
      MAKE: "make",
      MODEL: "model",
      YEAR: "year",
      VIN: "vin",
    },
  },
  {
    name: "pdf/As Is Disclaimer.pdf",
    fields: {
      "DEALER NAME": "dealerName",
      "DEALER ADDRESS": "dealerAddress",
      DATE: "date",
      NAME: "fullName",
      NAME2: "fullName2",
      ADDRESS: "address",
      YEAR: "year",
      MAKE: "make",
      MODEL: "model",
      VIN: "vin",
      COLOR: "exteriorColor",
      MILEAGE: "mileage",
    },
  },
  {
    name: "pdf/Buyers order.PDF ",
    fields: {
      "DEALER NAME": "dealerName",
      "DEALER ADDRESS": "dealerAddress",
      "DEALER PHONE": "dealerPhone",
      DATE: "date",
      NAME: "fullName",
      ADDRESS: "address",
      PHONE: "phone",
      "NAME 2": "fullName2",
      "ADDRESS 2": "address2",
      "PHONE 2": "phone2",
      Vehicle_Used_Yes: true,
      YEAR: "year",
      MAKE: "make",
      MODEL: "model",
      VIN: "vin",
      COLOR: "exteriorColor",
      MILEAGE: "mileage",
      "BODY STYLE": "body",
      "TRADEIN YEAR": "tradeinYear",
      "TRADEIN MAKE": "tradeinMake",
      "TRADEIN MODEL": "tradeinModel",
      "TRADEIN COLOR": "tradeinColor",
      "TRADEIN BODYSTYLE": "tradeinBodyStyle",
      "TRADEIN VIN": "tradeinVin",
      Vehicle_TradeIn_Mileage: "tradeinMileage",
      Loan_Paid_TradeIn_Amount: "tradeinAllowance",
      Loan_Paid_TradeInLien_Amount: "tradeinPayoff",
      Vehicle_TradeIn_Payoff_Amount: "tradeinPayoff",
      PRICE: "sellingPrice",
      "SALES TAX": "salesTax",
      //   SUBTOTAL: "",
      REGISTRATION: "registration",
      "DOCUMENTARY FEE": "docFee",
      "CREDIT CARD FEE": "cardFee",
      TOTAL: "totalToCollect",
    },
  },
];

const dealer = {
  dealerName: "Leaf Autos LLC",
  dealerPhone: "(206) 602-4363",
  dealerAddress: "2501 B Harbor Ave SW, Seattle, WA 98126",
  dealerEmail: "leafautos@gmail.com",
};

function getFormattedDate(date, separator = "/") {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  return month + separator + day + separator + year;
}
