import { useDispatch } from "react-redux";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { ModalWrapper } from "../../../app/common/modals/ModalWrapper";

export const ErrorModal = (props) => {
  const modalDispatch = useDispatch();
  return (
    <ModalWrapper header="Error">
      <div className="bg-white p-4 relative flex flex-col">
        <div className="py-0 pb-10">Modal Doesn't Exist</div>
        <button color="red" label="Close" onClick={() => modalDispatch(closeModal())} />
      </div>
    </ModalWrapper>
  );
};
