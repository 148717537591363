import React from "react";
import { VehicleExpenses } from "./VehicleExpenses";

export const VehicleDocs = () => {
  return (
    <div className="mx-auto container bg-white">
      <h1>VehicleDocs</h1>
      <VehicleExpenses />
    </div>
  );
};
