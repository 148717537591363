import React, { useEffect } from "react";
import { database } from "../../app/config/firebase";
import {
  ref as databaseRef,
  onValue,
  off,
  update,
  remove,
  set,
} from "firebase/database";
import {
  MdCalendarToday,
  MdClear,
  MdDeleteForever,
  MdLocalCarWash,
  MdPin,
  MdPushPin,
} from "react-icons/md";

import cuid from "cuid";

import ReactDatePicker from "react-datepicker";
import { SalesTable } from "./SalesTable";
import { formatDistance } from "date-fns";
import { WiStrongWind } from "react-icons/wi";
import { GiReceiveMoney } from "react-icons/gi";
import { FaWrench } from "react-icons/fa";
import { GrCompliance } from "react-icons/gr";

import { useHotkeys } from "react-hotkeys-hook";
import { AddSale } from "./AddSale";
import { ConditionalFormattingPage } from "./ConditionalFormattingPage";
import { BoardVehicleButton, CarSelect } from "../../app/common/form/CarSelect";
import { UserSelect } from "../../app/common/form/UserSelect";
import { sampleUsers } from "../vehicles/VehicleExpenses";
import { DropDown } from "../../app/common/form/DropDown";
import { DateSelect } from "../../app/common/form/DateSelect";
import { Toolbar } from "./Toolbar";

export const BoardPage = () => {
  // const dispatch = useDispatch();

  const [activeSales, dispatch] = React.useReducer(tableReducer, []);
  // const { activeSales } = useSelector((state) => state.board);
  const [panelOpen, setPanelOpen] = React.useState(false);

  const [vehicles, setVehicles] = React.useState(null);

  useEffect(() => {
    const data = localStorage.getItem("inventoryData");
    if (data) {
      // If the data is already stored, parse it and use it in your component
      setVehicles(JSON.parse(data));
    } else {
      // If the data is not stored, fetch it from the server
      fetchInventoryData();
    }
  }, []);

  // Fetch the inventory data from the server
  const fetchInventoryData = () => {
    fetch("https://us-central1-leafautos.cloudfunctions.net/getChoiceInventory")
      .then((response) => response.json())
      .then((data) => {
        // Store the data in local storage for future use
        console.log(data);
        localStorage.setItem("inventoryData", JSON.stringify(data));
        setVehicles(data);
      })
      .catch((error) => {
        // Handle any errors that occur during the fetch request
        console.error(error);
      });
  };

  const handleAddSale = async (data) => {
    const ref = databaseRef(database, `admin/sales`);
    try {
      await update(ref, { [cuid()]: data });
      console.log("Added sale!");
    } catch (error) {
      alert(JSON.stringify(error, null, 2));
    }
  };
  const handleRemoveSale = async (saleId) => {
    const ref = databaseRef(database, `admin/sales/${saleId}`);
    try {
      await remove(ref);
      console.log("Removed sale!");
    } catch (error) {
      alert(JSON.stringify(error, null, 2));
    }
  };

  const save =
    ({ saleId }) =>
    async (e) => {
      console.log(
        `admin/sales/${saleId}/${e.target.name.replaceAll(".", "/")}`
      );
      const ref = databaseRef(
        database,
        `admin/sales/${saleId}/${e.target.name.replaceAll(".", "/")}`
      );
      try {
        return await set(ref, e.target.value);
        // return console.log(e.target.name, e.target.value);
      } catch (error) {
        console.log(error);
      }
    };
  const setCheckedValue =
    ({ saleId }) =>
    async (e) => {
      console.log(
        `admin/sales/${saleId}/${e.target.name.replaceAll(".", "/")}`
      );
      // console.log(e.target.value);
      const ref = databaseRef(
        database,
        `admin/sales/${saleId}/${e.target.name.replaceAll(".", "/")}`
      );
      try {
        return await set(ref, e.target.checked);
        // return console.log(e.target.name, e.target.value);
      } catch (error) {
        console.log(error);
      }
    };

  useEffect(() => {
    const ref = databaseRef(database, "admin/sales");
    onValue(ref, (snapshot) => {
      console.log("got new values");
      const array = [];
      snapshot.forEach((el) => {
        array.push({ id: el.key, ...el.val() });
      });
      dispatch({ type: "UPDATE_DATA", payload: array });
      // dispatch(listenToActiveSales(array));
    });

    return () => off(ref);
  }, [dispatch]);

  // const columns = React.useMemo(
  //   () =>
  //     columnArray.map((column) => {
  //       return {
  //         Header: titleCase(column),
  //         accessor: column,
  //       };
  //     }),
  //   []
  // );

  // const columns = [
  const columns = React.useMemo(
    () => [
      {
        Header: "Sold",
        Cell: UserCell(save),
        width: 40,
      },
      {
        Header: "Car",
        accessor: "vehicle",
        Cell: CarCell(save, vehicles),
        width: 150,
        // Cell: ({ value }) => (
        //   <div className="flex space-x-2">
        //     {value?.photoURL && (
        //       <div
        //         className="w-6 h-6 rounded"
        //         style={{
        //           backgroundSize: "cover",
        //           backgroundImage: `url(${value.photoURL})`,
        //         }}
        //       ></div>
        //     )}
        //     <span className="flex-grow px-2 text-sm text-left truncate w-10">
        //       {value?.year} {value?.make} {value?.model}
        //     </span>
        //   </div>
        // ),
      },
      {
        Header: "Client",
        accessor: "clients",
        Cell: ClientCell(save),
        width: 180,
      },
      {
        Header: "Payment",
        accessor: "paymentMethod",
        Cell: InputCell("paymentMethod", save),
        width: 100,
      },
      {
        Header: "Notes",
        accessor: "notes",
        Cell: InputCell("notes", save),
        width: 250,
      },
      {
        Header: "Delivery",
        accessor: "deliveryDate",
        columnId: "deliveryDate",
        sortType: priorityTimestampSort,
        Cell: DeliveryDateCell(save),
        width: 100,
      },
      {
        Header: <WiStrongWind />,
        accessor: "emissions",
        Cell: CheckBoxCell("emissions", save),
      },
      {
        Header: <FaWrench />,
        accessor: "inspection",
        Cell: CheckBoxCell("inspection", save),
      },
      {
        Header: <MdLocalCarWash />,
        accessor: "detail",
        Cell: CheckBoxCell("detail", save),
      },
      {
        Header: <GiReceiveMoney />,
        accessor: "funded",
        Cell: CheckBoxCell("funded", save),
      },
      {
        Header: <GrCompliance />,
        accessor: "delivered",
        Cell: CheckBoxCell("delivered", save),
      },
      {
        Header: "Actions",
        Cell: ActionsCell(save, handleRemoveSale),
        // Cell: ({ row }) => (
        //   <div className="flex">
        //     <button
        //       className="p-1 rounded-full bg-slate-50 hover:rotate-45 -rotate-180 transition-all"
        //       onClick={() => console.log(row.original.id)}
        //       type="button"
        //     >
        //       <MdPushPin />
        //     </button>
        //     <button
        //       className="p-1 rounded-full bg-slate-50 "
        //       onClick={() => handleRemoveSale(row.original.id)}
        //       type="button"
        //     >
        //       <MdClear />
        //     </button>
        //   </div>
        // ),
        // accessor: "col2",
      },
      // ];
    ],
    [vehicles]
  );

  const initialState = React.useMemo(
    () => ({
      sortBy: [
        {
          id: "deliveryDate",
          desc: false,
        },
      ],
    }),
    []
  );

  const sortTypes = React.useMemo(
    () => ({
      priority: (rowA, rowB, columnId) => {
        const priorityA =
          rowA.values[columnId] === "pinned"
            ? 1
            : rowA.values[columnId] === "delivered"
            ? 3
            : 2;
        const priorityB =
          rowB.values[columnId] === "pinned"
            ? 1
            : rowB.values[columnId] === "delivered"
            ? 3
            : 2;

        return priorityA - priorityB;
      },
    }),
    []
  );

  return (
    <div className="relative flex flex-col justify-between items-stretch flex-grow ">
      <div className="table mx-auto">
        <Toolbar
          panelOpen={panelOpen}
          setPanelOpen={setPanelOpen}
          activeSales={activeSales}
        />
        {activeSales && activeSales.length > 0 ? (
          <>
            <SalesTable
              data={activeSales}
              columns={columns}
              initialState={initialState}
              sortTypes={sortTypes}
            />
            {/* <table /> */}
            {/* <pre className="whitespace-pre-wrap break-words overflow-hidden text-xs">
              {JSON.stringify(activeSales, null, 2)}
            </pre> */}
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
      {/* <ConditionalFormattingPage /> */}

      {panelOpen && (
        <div className="absolute right-0 panel h-full min-h-fit overflow-y-auto bg-white border max-w-lg w-full shadow-sm ">
          <AddSale
            close={() => setPanelOpen(false)}
            handleAddSale={handleAddSale}
          />
        </div>
      )}
    </div>
  );
};

const tableReducer = (state, { type, payload }) => {
  switch (type) {
    case "UPDATE_DATA":
      return payload;

    default:
      return state;
  }
};

const ClientCell =
  (save) =>
  ({ value, row: { original }, ...props }) =>
    (
      <div className="flex flex-col space-x-2 w-full h-full overflow-x-auto no-scrollbar">
        {Object.entries(value).map(([clientId, client], index) => (
          <CustomInput
            name={`clients.${clientId}.displayName`}
            value={client?.displayName || ""}
            key={clientId}
            onBlur={(e) =>
              client?.displayName !== e.target.value &&
              save({ saleId: original.id })(e)
            }
          />
        ))}
      </div>
    );
const InputCell =
  (name, save) =>
  ({ value, row: { original }, ...props }) =>
    (
      <div className="flex flex-col space-x-2 w-full h-full overflow-x-auto no-scrollbar">
        <CustomInput
          name={name}
          value={value}
          onBlur={(e) =>
            value !== e.target.value && save({ saleId: original.id })(e)
          }
        />
      </div>
    );

const CustomInput = ({ value, name, onBlur, ...props }) => {
  const [inputValue, setInputValue] = React.useState(value || "");

  React.useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      return e.preventDefault();
    }
    if (e.key === "Enter") return e.target.blur();
    e.stopPropagation();
  };

  return (
    <input
      name={name}
      value={inputValue || ""}
      onChange={(e) => setInputValue(e.target.value)}
      onFocus={(e) => e.stopPropagation()}
      onBlur={onBlur}
      onKeyDown={handleKeyDown}
      className="flex-grow min-w-max w-full p-2 bg-transparent h-full text-xs text-left focus:bg-blue-200 focus:bg-opacity-5 focus:ring-1 focus:ring-inset transition-all"
    />
  );
};

// admin/sales/cldkos6te0019356e1xfvrgio/clients/cldkor44d0005356em9krfl5h/displayName
// /admin/sales/cldkos6te0019356e1xfvrgio/clients/cldkor44d0005356em9krfl5h/displayName

const CarCell =
  (save, vehicles) =>
  ({ value, row: { original }, ...props }) =>
    (
      <CarSelect
        options={vehicles}
        name="vehicle"
        defaultValue={value}
        renderButton={BoardVehicleButton}
        value={value}
        onSelect={(value) => {
          save({ saleId: original.id })({
            target: {
              name: "vehicle",
              value,
            },
          });
        }}
        // disableSearch
      />
    );

const UserCell =
  (save) =>
  ({ row: { original }, ...props }) =>
    (
      <UserSelect
        name="user"
        options={sampleUsers}
        defaultValue={original?.soldBy}
        value={original?.soldBy}
        onSelect={(value) => {
          save({ saleId: original.id })({
            target: {
              name: "soldBy",
              value,
            },
          });
        }}
        renderButton={({ buttonRef, isOpen, close, open }) => (
          <button
            ref={buttonRef}
            onClick={isOpen ? close : open}
            className="bg-blue-500 flex space-x-2 items-center justify-center mx-auto h-full bg-opacity-0 hover:bg-opacity-5 hover:bg-black transition-all w-full "
          >
            <span
              className="bg-lime-500 bg-cover bg-center w-6 h-6 rounded-full text-xs  items-center flex justify-center select-none"
              style={{
                backgroundImage: `url(${
                  original?.soldBy?.photoUrl || original?.soldBy?.photoURL
                }), url("img/user.png")`,
              }}
            >
              {!original?.soldBy?.photoUrl &&
                !original?.soldBy?.photoURL &&
                original?.soldBy?.displayName?.split(" ")[0][0] +
                  original?.soldBy?.displayName?.split(" ")?.[1][0]}
            </span>
            {/* <span className="text-sm">
      {original?.soldBy?.displayName?.split(" ")[0]}{" "}
    </span> */}
          </button>
        )}
        disableSearch
      />
    );

const CheckBoxCell =
  (name, save) =>
  ({ value, row: { original } }) =>
    (
      <div className="w-full h-full flex items-center justify-center ">
        <button
          name={name}
          onClick={(e) =>
            save({ saleId: original.id })({
              target: {
                name,
                value: !value,
              },
            })
          }
          className="border border-black w-4 h-4 rounded m-1"
          type="button"
        >
          {value && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="black"
            >
              <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
            </svg>
          )}
        </button>
      </div>
    );
const ActionsCell =
  (save, handleRemoveSale) =>
  ({ row: { original } }) =>
    DropDown({
      popperPlacement: "bottom-end",
      options: [
        {
          label: "Pin",
          Icon: MdPin,
          onClick: (e) => console.log("I pin you down"),
        },
        {
          label: "Delete",
          Icon: MdDeleteForever,
          onClick: () => handleRemoveSale(original?.id),
        },
      ],
      renderItem: ({ label, Icon, ...props }) => (
        <div
          className="px-4 py-2 flex justify-center items-center space-x-2"
          {...props}
        >
          {Icon && <Icon />} <span>{label}</span>
        </div>
      ),
      renderButton: ({ isOpen, open, close, props }) => (
        <button
          onClick={isOpen ? close : open}
          {...props}
          className={`w-full h-full flex flex-nowrap items-center justify-center group text-center  text-sm space-x-2 px-2 py-0.5   ${
            isOpen ? "ring-blue-100 ring-1" : ""
          } ring-blue-300 focus:ring-1  transition-all bg-opacity-0 bg-black hover:bg-opacity-5  select-none   `}
        >
          <span>...</span>
        </button>
      ),
      onSelect: console.log,
      disableSearch: true,
    });

const DeliveryDateCell =
  (save) =>
  ({ value, row: { original } }) =>
    DateSelect({
      value,
      onSelect: (v) =>
        save({ saleId: original.id })({
          target: {
            name: "deliveryDate",
            value: v,
          },
        }),
    });

function priorityTimestampSort(rowA, rowB, id, desc) {
  const dateA = new Date(rowA.original.deliveryDate * 1000);
  const dateB = new Date(rowB.original.deliveryDate * 1000);
  const today = new Date();

  if (dateA.toDateString() === today.toDateString()) {
    return -1;
  } else if (dateB.toDateString() === today.toDateString()) {
    return 1;
  } else if (dateA < today && dateB >= today) {
    return 1;
  } else if (dateA >= today && dateB < today) {
    return -1;
  } else {
    return dateA - dateB;
  }
}
