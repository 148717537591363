import React from "react";
import {
  MdArrowBack,
  MdArrowForward,
  MdDeleteForever,
  MdDownload,
  MdEast,
  MdHighQuality,
  MdImage,
  MdOpenInNew,
  MdStar,
  MdStarRate,
  MdStars,
  MdVisibility,
  MdVisibilityOff,
  MdWest,
} from "react-icons/md";
import { BsGridFill, BsGrid } from "react-icons/bs";
import { BiSelectMultiple } from "react-icons/bi";
import { FileUpload } from "./FileUpload";
import cuid from "cuid";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import {
  createArrayOrder,
  deleteVehicleImage,
  deleteVehicleImages,
  imageOrderLeft,
  imageOrderRight,
  setImgOrder,
  setMainVehiclePhoto,
  setVehicleImageVisibility,
  toggleVehicleImageVisibility,
} from "../../app/firestore/firestoreService";
import { Images } from "./Images";
import download from "downloadjs";

const defaultImages = Object.fromEntries(
  [...Array(1).keys()].map((n) => [
    cuid(),
    {
      src: "https://storage.googleapis.com/leafautosphotos/vehicles/images/cl4hh5cxx0002356eif4x5vtb/cl6322e0k0000356lw8so93sj/02dodgeram.jpg",
    },
    // `/img/cruize/cruize (${n + 1}).jpg`,
  ])
);

export const VehiclePhotos = ({ images, mainImgId, docId, imgOrder }) => {
  const hasMainPhoto = images && mainImgId;

  const [rearrangeMode, setRearrangeMode] = React.useState(false);
  const [selected, setSelected] = React.useState([]);

  const toggleSelection = (imageId) => {
    setSelected((s) =>
      s.includes(imageId) ? s.filter((v) => v !== imageId) : [...s, imageId]
    );
  };

  function selectAll() {
    return setSelected(imgOrder);
  }
  function selectNone() {
    return setSelected([]);
  }

  React.useEffect(() => {
    console.log("Running Effect");
    let isMounted = true;
    //if deleted deselect
    if (isMounted) {
      setSelected((s) => s.filter((v) => imgOrder.includes(v)));
    }

    return () => {
      // 👇️ when component unmounts, set isMounted to false
      isMounted = false;
    };
  }, [imgOrder]);

  const toggleRearangeMode = () => setRearrangeMode((v) => !v);

  const [lightBox, setLightBox] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(0);

  return (
    <div className="mx-auto flex flex-wrap justify-center items-start w-full h-full">
      <FileUpload
        title="Photos"
        accept={{
          "image/jpeg": [],
          "image/png": [],
          // "application/pdf": [],
        }}
        docKey="images"
        hasMainPhoto={hasMainPhoto}
        bucket="leafautosphotos"
        docId={docId}
        storagePath="vehicles/"
        addToImgOrder={true}
        dbTargets={[
          {
            docPath: `vehicles/${docId}`,
            docKey: "images",
            condition: true,
          },
        ]}
      >
        {images && imgOrder && (
          <div className="toolbar pt-1 w-full bg-slate-300 flex justify-center items-center">
            <ToolbarButton
              onClick={toggleRearangeMode}
              text="Rearrange"
              className={rearrangeMode && "text-indigo-600"}
              Icon={rearrangeMode ? BsGridFill : BsGrid}
            />

            <ToolbarButton
              onClick={selectAll}
              text="Select All"
              Icon={BiSelectMultiple}
              disabled={selected.length === imgOrder.length}
            />

            <ToolbarButton
              onClick={selectNone}
              text="Select None"
              Icon={BiSelectMultiple}
              disabled={selected.length === 0}
            />
            <ToolbarButton
              onClick={() => setVehicleImageVisibility(true, selected, docId)}
              text={`Make ${selected.length} Public`}
              Icon={MdVisibility}
              disabled={selected.length === 0}
            />
            <ToolbarButton
              onClick={() => setVehicleImageVisibility(false, selected, docId)}
              text={`Make ${selected.length} Private`}
              Icon={MdVisibilityOff}
              disabled={selected.length === 0}
            />
            <ToolbarButton
              onClick={() => {
                const result = window.confirm(
                  `Delete ${selected.length} image${
                    selected.length > 1 ? "s" : ""
                  }?`
                );
                if (result) {
                  deleteVehicleImages(selected, docId);
                  selectNone();
                }
              }}
              text={`Delete (${selected.length})`}
              Icon={MdDeleteForever}
              disabled={selected.length === 0}
            />
          </div>
        )}
        {/* <button type="button" onClick={() => setLightBox(true)}>
          Open Lightbox
        </button> */}

        {images && imgOrder && (
          <Images
            key={docId}
            order={imgOrder}
            images={images}
            setOrder={(order) => setImgOrder(docId, order)}
            mainImgId={mainImgId}
            // onRemove={onRemove}
            rearrangeMode={rearrangeMode}
            toggleSelection={toggleSelection}
            selected={selected}
            toggleVehicleImageVisibility={(v, imageId) =>
              toggleVehicleImageVisibility(v, imageId, docId)
            }
            setMainVehiclePhoto={(imageId) =>
              setMainVehiclePhoto(imageId, docId)
            }
            deleteVehicleImage={(imageId) => {
              const result = window.confirm("Delete image?");
              if (result) {
                deleteVehicleImage(imageId, docId);
              }
            }}
            openImage={(imageId) => {
              setImageIndex(imgOrder.indexOf(imageId));
              setLightBox(true);
            }}
          />
        )}
        {/* <div className="text-xs bg-slate-200">
          <pre>{JSON.stringify(images[imgOrder[imageIndex]].url, null, 2)}</pre>
        </div> */}

        {(!images || Object.keys(images)?.length === 0) && (
          <div className="flex flex-col justify-center items-center text-gray-600">
            <img
              src="/img/car.png"
              alt=""
              className="h-24 w-36 opacity-70   border-black px-4 py-2 rounded-xl select-none"
            />
            <span> No images found. </span>
          </div>
        )}

        {lightBox && (
          <Lightbox
            mainSrc={
              images[imgOrder[imageIndex]].thumbs?.[1]?.url ||
              images[imgOrder[imageIndex]].url
            }
            mainSrcThumbnail={images[imgOrder[imageIndex]].thumbs[0].url}
            nextSrc={
              images[imgOrder[(imageIndex + 1) % imgOrder.length]].thumbs?.[1]
                ?.url ||
              images[imgOrder[(imageIndex + 1) % imgOrder.length]].url
            }
            nextSrcThumbnail={
              images[imgOrder[(imageIndex + 1) % imgOrder.length]].thumbs[0].url
            }
            prevSrc={
              images[
                imgOrder[(imageIndex + imgOrder.length - 1) % imgOrder.length]
              ].thumbs?.[1]?.url ||
              images[
                imgOrder[(imageIndex + imgOrder.length - 1) % imgOrder.length]
              ].url
            }
            prevSrcThumbnail={
              images[
                imgOrder[(imageIndex + imgOrder.length - 1) % imgOrder.length]
              ].thumbs[0].url
            }
            onCloseRequest={() => setLightBox(false)}
            onMovePrevRequest={() =>
              setImageIndex((n) => (n + imgOrder.length - 1) % imgOrder.length)
            }
            onMoveNextRequest={() =>
              setImageIndex((n) => (n + 1) % imgOrder.length)
            }
            toolbarButtons={[
              mainImgId !== imgOrder[imageIndex] ? (
                <ToolbarButton
                  Icon={MdStarRate}
                  onClick={() =>
                    setMainVehiclePhoto(imgOrder[imageIndex], docId)
                  }
                />
              ) : (
                <ToolbarButton
                  className="text-yellow-400"
                  Icon={MdStarRate}
                  disabled
                />
              ),

              <ToolbarButton
                Icon={
                  images[imgOrder[imageIndex]].isPublic
                    ? MdVisibility
                    : MdVisibilityOff
                }
                className={
                  !images[imgOrder[imageIndex]].isPublic && "text-red-500"
                }
                onClick={() =>
                  toggleVehicleImageVisibility(
                    !images[imgOrder[imageIndex]].isPublic,
                    imgOrder[imageIndex],
                    docId
                  )
                }
                disabled={mainImgId === imgOrder[imageIndex]}
              />,
              <ToolbarButton
                Icon={MdDeleteForever}
                onClick={(e) => {
                  const result = window.confirm("Delete image?");
                  if (result) {
                    deleteVehicleImage(imgOrder[imageIndex], docId);
                    setImageIndex((n) => (n + 1) % imgOrder.length);
                  }
                }}
              />,

              <a
                href={images[imgOrder[imageIndex]].url}
                download
                target="_blank"
                className="text-3xl  px-1 mx-1 h-9 flex opacity-60 hover:opacity-100 transition-all"
                rel="noreferrer"
              >
                <MdDownload />
              </a>,
            ]}
          />
        )}
        {/* <div className="mx-auto flex flex-wrap justify-center items-start w-full">
          {images &&
            imgOrder &&
            Object.keys(images).length > 0 &&
            imgOrder.map((imageId, i) => (
              <ImageCard
                key={imageId}
                imageId={imageId}
                mainImgId={mainImgId}
                docId={docId}
                i={i}
                images={images}
                imgOrder={imgOrder}
              />
            ))}
        </div> */}
      </FileUpload>
    </div>
  );
};

const ToolbarButton = ({
  Icon,
  text = "",
  className = "",
  onClick = () => {},
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      className={`select-none ${
        props?.disabled
          ? "text-slate-400"
          : "hover:text-slate-900 hover:bg-opacity-60 hover:bg-white rounded"
      }  w-18 h-full   flex-col flex items-center justify-center opacity-60  transition-all text-xl mx-2 mb-1 p-1
      ${className}`}
      {...props}
    >
      {Icon && <Icon />}
      <span className="bg-transparent leading-[10px] text-[8px] mt-1 ">
        {text}
      </span>
    </button>
  );
};

const ImageCard = ({ imageId, mainImgId, docId, i, images, imgOrder }) => {
  return (
    <div className="relative m-1 rounded overflow-hidden">
      <img
        src={images[imageId]?.thumbs?.[0].url || ""}
        draggable={false}
        imageid={imageId}
        alt={`no. ${i}`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = "/img/logo.png";
        }}
        className="h-48  bg-gray-300  hover:scale-105 transition-all select-none"
      />
      <div className="order absolute flex justify-between z-10 bottom-2 w-full">
        {imgOrder.indexOf(imageId) !== 0 ? (
          <button
            className="text-white bg-black ml-2 p-1 rounded bg-opacity-40 hover:bg-opacity-80 transition-opacity"
            onClick={() => imageOrderLeft(imageId, docId, imgOrder)}
          >
            <MdWest />
          </button>
        ) : (
          <span className="w-6"></span>
        )}
        <button
          className="text-white bg-black mr-2 p-1 rounded bg-opacity-40 hover:bg-opacity-80 transition-opacity"
          onClick={() =>
            toggleVehicleImageVisibility(
              !images[imageId]?.isPublic,
              imageId,
              docId
            )
          }
        >
          {images[imageId]?.isPublic ? <MdVisibility /> : <MdVisibilityOff />}
        </button>
        {imgOrder.indexOf(imageId) !== imgOrder.length - 1 ? (
          <button
            className="text-white bg-black mr-2 p-1 rounded bg-opacity-40 hover:bg-opacity-80 transition-opacity"
            onClick={() => imageOrderRight(imageId, docId, imgOrder)}
          >
            <MdEast />
          </button>
        ) : (
          <span className="w-6"></span>
        )}
      </div>
      <div
        id="toolbar"
        className="text-white w-full px-2 pt-2 flex justify-between absolute  top-0 z-10"
      >
        {mainImgId === imageId ? (
          <span className=" text-xl flex text-yellow-400  p-1 ">
            <MdStarRate />
          </span>
        ) : (
          <button
            className="text-white text-lg flex bg-black  p-1 rounded bg-opacity-40 hover:bg-opacity-80 transition-opacity"
            onClick={() => setMainVehiclePhoto(imageId, docId)}
          >
            <MdStarRate />
          </button>
        )}
        <button
          className="text-white text-lg flex bg-black  p-1 rounded bg-opacity-40 hover:bg-opacity-80 transition-opacity"
          onClick={() => {
            const result = window.confirm("Delete image?");
            if (result) {
              deleteVehicleImage(imageId, docId);
            }
          }}
        >
          <MdDeleteForever />
        </button>

        <a
          target="_BLANK"
          href={images[imageId]?.url}
          rel="noreferrer"
          draggable={false}
          className="text-white text-lg flex bg-black  p-1 rounded bg-opacity-40 hover:bg-opacity-80 transition-opacity"
        >
          <MdHighQuality />
          {/* <MdOpenInNew /> */}
        </a>
      </div>
    </div>
  );
};
