import React from "react";
import { MdDirectionsCar, MdUnfoldLess, MdUnfoldMore } from "react-icons/md";
import { IoReload } from "react-icons/io5";
import { BiLoader } from "react-icons/bi";
import { RiErrorWarningLine } from "react-icons/ri";

import { DropDown } from "./DropDown";
import vehicles from "../../data/vehicles";

export const VehicleButton = ({
  buttonRef,
  isOpen,
  close,
  open,
  selected,
  handleKeyDown,
  keyboardShortcut,
  label = "",
  Icon,
  isLoading,
  error,
  retryLoadData,
}) => (
  <>
    <button
      ref={buttonRef}
      type="button"
      onClick={isOpen ? close : open}
      onKeyDown={handleKeyDown}
      className={`w-full flex flex-nowrap items-center group  text-sm space-x-2 px-2 py-0.5 border rounded ${
        isOpen ? "ring-blue-100 ring-1" : ""
      } ring-blue-300 focus:ring-1  transition-all bg-opacity-0 bg-black hover:bg-opacity-5  select-none   `}
      disabled={!!error}
    >
      {selected ? (
        <>
          {selected?.mainImgId && selected?.images ? (
            <div
              className="w-6 h-6 rounded"
              style={{
                backgroundSize: "cover",
                backgroundImage: `url(${
                  selected.images[selected.mainImgId].thumbs[0].url
                })`,
              }}
            ></div>
          ) : (
            <MdDirectionsCar />
          )}
          <span className="flex-grow px-2 text-left">
            {`${selected?.year} ${selected?.make} ${selected?.model}`}
          </span>
          {keyboardShortcut && (
            <kbd className="text-[8px] invisible group-focus:visible border uppercase leading-tight p-0.5 rounded text-gray-400">
              {keyboardShortcut}
            </kbd>
          )}
        </>
      ) : (
        <>
          {Icon && <Icon />}
          <span className="text-gray-600 text-xs py-1 flex-grow text-left transition-all">
            Please select {label}...
          </span>
        </>
      )}
      <span
        onClick={error && retryLoadData}
        className="p-1 text-gray-500 group-hover:text-gray-700 transition-all bg-opacity-0 bg-black hover:bg-opacity-5 rounded-full "
      >
        {error ? (
          <span className="text-red-400 relative">
            <RiErrorWarningLine className="visible group-hover:invisible" />
            <IoReload className="invisible group-hover:visible absolute top-0 " />
          </span>
        ) : isLoading ? (
          <BiLoader className="animate-spin" />
        ) : !isOpen ? (
          <MdUnfoldMore />
        ) : (
          <MdUnfoldLess />
        )}
      </span>
    </button>
  </>
);

export const BoardVehicleButton = ({
  buttonRef,
  isOpen,
  close,
  open,
  selected,
  handleKeyDown,
  keyboardShortcut,
  label = "",
  Icon,
  isLoading,
  error,
  retryLoadData,
}) => (
  <>
    <button
      ref={buttonRef}
      type="button"
      onClick={isOpen ? close : open}
      onKeyDown={handleKeyDown}
      className={` w-full h-full flex ring-inset flex-nowrap items-center group text-xs    ${
        isOpen ? "ring-blue-100  ring-1" : ""
      } ring-blue-300 focus:ring-1 p-0.5  transition-all bg-opacity-0 bg-black hover:bg-opacity-5  select-none   `}
      disabled={!!error}
    >
      {selected ? (
        <>
          {selected?.photoURL ? (
            <div
              className="min-w-[32px] w-8 h-full bg-center"
              style={{
                backgroundSize: "cover",
                backgroundImage: `url(${selected.photoURL})`,
              }}
            ></div>
          ) : (
            <MdDirectionsCar />
          )}
          <span className="flex-grow px-2 text-left truncate">
            {`${selected?.year} ${selected?.make} ${selected?.model}`}
          </span>
          {keyboardShortcut && (
            <kbd className="text-[8px] invisible group-focus:visible border uppercase leading-tight p-0.5 rounded text-gray-400">
              {keyboardShortcut}
            </kbd>
          )}
        </>
      ) : (
        <>
          {Icon && <Icon />}
          <span className="text-gray-600 text-xs py-1 flex-grow text-left transition-all">
            Please select {label}...
          </span>
        </>
      )}
      <span
        onClick={error && retryLoadData}
        className="p-1 text-gray-500 group-hover:text-gray-700 transition-all bg-opacity-0 bg-black hover:bg-opacity-5 rounded-full "
      >
        {error ? (
          <span className="text-red-400 relative">
            <RiErrorWarningLine className="visible group-hover:invisible" />
            <IoReload className="invisible group-hover:visible absolute top-0 " />
          </span>
        ) : isLoading ? (
          <BiLoader className="animate-spin" />
        ) : !isOpen ? (
          <MdUnfoldMore />
        ) : (
          <MdUnfoldLess />
        )}
      </span>
    </button>
  </>
);

export const VehicleListItem = (vehicle) => {
  return (
    <div className="flex items-center space-x-2 text-sm m-0.5">
      <span
        className="w-10 h-10 bg-center"
        style={{
          backgroundSize: "cover",
          backgroundImage: `url(${vehicle.photoURL})`,
        }}
      ></span>
      <span className="text-xs truncate">{`${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`}</span>
    </div>
  );
};

const handleSelectVehicle = ({
  id,
  year,
  make,
  model,
  mainImgId,
  images,
  ...item
}) => {
  return console.log({
    type: "UPDATE_INPUT",
    payload: {
      field: "vehicle",
      value: {
        id,
        year,
        make,
        model,
        photoURL: images[mainImgId].thumbs[0].url,
      },
    },
  });
};

export const CarSelect = ({
  options = vehicles.map((v) => ({
    make: v?.make,
    model: v?.model,
    year: v?.year,
    vin: v?.vin,
    photoURL: v?.images[v?.mainImgId]?.thumbs?.[0]?.url,
  })),
  renderItem = VehicleListItem,
  renderButton = BoardVehicleButton,
  defaultValue = null,
  onChange,
  value,
  onSelect = handleSelectVehicle,
  label,
  disableSearch = false,
  Icon = MdDirectionsCar,
}) =>
  DropDown({
    options,
    renderItem,
    renderButton,
    defaultValue,
    value,
    onChange,
    onSelect,
    label,
    disableSearch,
    Icon,
  });
