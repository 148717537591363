import cuid from "cuid";
import React from "react";
import { TextArea } from "../../app/common/form/TextArea";
import { UserSelect } from "../../app/common/form/UserSelect";
import { sampleUsers } from "../vehicles/VehicleExpenses";
import { useSelector } from "react-redux";
import {
  MdCalendarToday,
  MdClear,
  MdDirectionsCar,
  MdPerson,
  MdUnfoldLess,
  MdUnfoldMore,
} from "react-icons/md";
import { Input } from "../../app/common/form/Input";
import vehicles from "../../app/data/vehicles";
import { RiErrorWarningLine } from "react-icons/ri";
import { IoReload } from "react-icons/io5";
import { BiLoader } from "react-icons/bi";
import ReactDatePicker from "react-datepicker";

export const AddSale = ({ close, handleAddSale, flow = true, ...props }) => {
  const { currentUser } = useSelector((state) => state.auth);
  const initialState = {
    dateSold: new Date(),
    soldBy: sampleUsers.find((user) => user.id === currentUser.uid) || null,
    clients: {
      [cuid()]: {
        dateCreated: new Date(),
      },
    },
  };
  const [loading, setLoading] = React.useState(false);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const handleOnChange = (e) => {
    return dispatch({
      type: "UPDATE_INPUT",
      payload: { field: e.target.name, value: e.target.value },
    });
  };
  const handleDateChange = (name) => (value) => {
    return dispatch({
      type: "UPDATE_INPUT",
      payload: { field: name, value },
    });
  };

  const handleSelect = ({ id, displayName, ...item }) => {
    return dispatch({
      type: "UPDATE_INPUT",
      payload: { field: "soldBy", value: { id, displayName } },
    });
  };
  const handleSelectVehicle = ({
    id,
    year,
    make,
    model,
    mainImgId,
    images,
    ...item
  }) => {
    return dispatch({
      type: "UPDATE_INPUT",
      payload: {
        field: "vehicle",
        value: {
          id,
          year,
          make,
          model,
          photoURL: images[mainImgId].thumbs[0].url,
        },
      },
    });
  };

  const addClient = (e) => {
    e.preventDefault();
    return dispatch({ type: "ADD_CLIENT" });
  };
  const removeClient = (clientId) => {
    return (e) => {
      e.preventDefault();
      return dispatch({ type: "REMOVE_CLIENT", payload: clientId });
    };
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await handleAddSale(state);
    setLoading(false);
    close();
  };

  const clients = Object.entries(state.clients);

  return (
    <div className="flex flex-col">
      <PanelHeader close={close} label="Add Sale" />
      <form onSubmit={onSubmit} className="px-2 py-4">
        <div className="flex space-x-2">
          <UserSelect
            label="Sold By"
            disableSearch
            Icon={MdPerson}
            options={sampleUsers}
            defaultValue={sampleUsers.find(
              (user) => user.id === currentUser.uid
            )}
            value={state.soldBy}
            onSelect={handleSelect}
          />
          <SelectDate
            onChange={handleDateChange}
            selected={state.dateSold}
            name="dateSold"
            label="Date Sold"
          />
        </div>
        <GroupHeader
          label={clients.length > 0 ? "Clients" : "Client"}
          action={addClient}
          actionLabel="+ Another Client"
        />
        {state?.clients &&
          clients.length > 0 &&
          clients
            .sort(
              ([, a], [, b]) =>
                a.dateCreated.getTime() - b.dateCreated.getTime()
            )
            .map(([clientId, client], index) => (
              <div key={clientId} className="flex space-x-2 items-center">
                <Input
                  onChange={handleOnChange}
                  value={client.displayName}
                  name={`clients.${clientId}.displayName`}
                  label={
                    clients.length > 1 ? `Client #${index + 1} Name` : "Name"
                  }
                />
                <Input
                  onChange={handleOnChange}
                  value={client.phone}
                  name={`clients.${clientId}.phone`}
                  label="Phone Number"
                />
                <button
                  onClick={removeClient(clientId)}
                  disabled={clients.length <= 1}
                  type="button"
                  className="text-xs px-1 disabled:hidden"
                >
                  <MdClear />
                </button>
              </div>
            ))}
        <GroupHeader label="Payment" action={prevent} actionLabel="+ Payment" />
        <div className="flex space-x-2">
          <Input
            onChange={handleOnChange}
            value={state.paymentMethod}
            name="paymentMethod"
            label="General Payment Method"
          />
        </div>

        <UserSelect
          label="Vehicle"
          options={vehicles}
          value={state.vehicle}
          onSelect={handleSelectVehicle}
          renderButton={VehicleButton}
          renderItem={VehicleListItem}
          Icon={MdDirectionsCar}
        />

        <SelectDate
          onChange={handleDateChange}
          selected={state.deliveryDate}
          name="deliveryDate"
          label="Estimated Delivery Date"
        />
        <TextArea
          label="Delivery Notes"
          onChange={handleOnChange}
          value={state.notes}
          name="notes"
        />
        {/* <div className="flex space-x-2">
            <Input
              onChange={handleOnChange}
              value={state.emissions}
              name="emissions"
              label="Emissions"
            />
            <Input
              onChange={handleOnChange}
              value={state.recondition}
              name="recondition"
              label="Reconditioned"
            />
            <Input
              onChange={handleOnChange}
              value={state.detail}
              name="detail"
              label="Detailed"
            />
          </div> */}
        <div className="flex space-x-4 mt-4 text-sm">
          <button className="px-4 py-2 border rounded">Clear</button>
          <button className="flex-grow px-4 py-2 rounded bg-green-700 hover:bg-green-500 transition-all text-white">
            {loading ? <BiLoader className="animate-spin" /> : "Create Sale"}
          </button>
        </div>
      </form>
    </div>
  );
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "UPDATE_INPUT":
      if (!payload.field.includes("."))
        return { ...state, [payload.field]: payload.value };
      const keys = payload.field.split(".");
      let updatedState = { ...state };
      let current = updatedState;
      keys.forEach((key, i) => {
        if (i === keys.length - 1) {
          current[key] = payload.value;
        } else {
          current[key] = { ...current[key] };
          current = current[key];
        }
      });
      return updatedState;
    case "UPDATE_DATA":
      return { ...state, ...payload };
    case "CLEAR":
      return { ...payload, date: new Date() };
    case "ADD_CLIENT":
      return {
        ...state,
        clients: { ...state.clients, [cuid()]: { dateCreated: new Date() } },
      };
    case "REMOVE_CLIENT":
      const next = { ...state };
      delete next.clients[payload];
      return next;
    default:
      return state;
  }
};

const prevent = (e) => e?.preventDefault();

const VehicleButton = ({
  buttonRef,
  isOpen,
  close,
  open,
  selected,
  handleKeyDown,
  keyboardShortcut,
  label = "",
  Icon,
  isLoading,
  error,
  retryLoadData,
}) => (
  <>
    <button
      ref={buttonRef}
      type="button"
      onClick={isOpen ? close : open}
      onKeyDown={handleKeyDown}
      className={`w-full flex flex-nowrap items-center group  text-sm space-x-2 px-2 py-0.5 border rounded ${
        isOpen ? "ring-blue-100 ring-1" : ""
      } ring-blue-300 focus:ring-1  transition-all bg-opacity-0 bg-black hover:bg-opacity-5  select-none   `}
      disabled={!!error}
    >
      {selected ? (
        <>
          {selected?.mainImgId && selected?.images ? (
            <div
              className="w-6 h-6 rounded"
              style={{
                backgroundSize: "cover",
                backgroundImage: `url(${
                  selected.images[selected.mainImgId].thumbs[0].url
                })`,
              }}
            ></div>
          ) : (
            <MdDirectionsCar />
          )}
          <span className="flex-grow px-2 text-left">
            {`${selected?.year} ${selected?.make} ${selected?.model}`}
          </span>
          {keyboardShortcut && (
            <kbd className="text-[8px] invisible group-focus:visible border uppercase leading-tight p-0.5 rounded text-gray-400">
              {keyboardShortcut}
            </kbd>
          )}
        </>
      ) : (
        <>
          {Icon && <Icon />}
          <span className="text-gray-600 text-xs py-1 flex-grow text-left transition-all">
            Please select {label}...
          </span>
        </>
      )}
      <span
        onClick={error && retryLoadData}
        className="p-1 text-gray-500 group-hover:text-gray-700 transition-all bg-opacity-0 bg-black hover:bg-opacity-5 rounded-full "
      >
        {error ? (
          <span className="text-red-400 relative">
            <RiErrorWarningLine className="visible group-hover:invisible" />
            <IoReload className="invisible group-hover:visible absolute top-0 " />
          </span>
        ) : isLoading ? (
          <BiLoader className="animate-spin" />
        ) : !isOpen ? (
          <MdUnfoldMore />
        ) : (
          <MdUnfoldLess />
        )}
      </span>
    </button>
  </>
);
const VehicleListItem = (vehicle) => {
  return (
    <div className="flex items-center space-x-2 text-sm m-0.5">
      <div
        className="w-10 h-10 rounded"
        style={{
          backgroundSize: "cover",
          backgroundImage: `url(${
            vehicle.images[vehicle.mainImgId].thumbs[0].url
          })`,
        }}
      ></div>
      <div>{`${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`}</div>
    </div>
  );
};

const PanelHeader = (props) => (
  <div className="header flex items-center justify-between bg-slate-600 text-slate-50">
    <h2 className="py-2 px-4 text-xs uppercase">{props?.label}</h2>
    <button onClick={props?.close} className="px-2 py-2">
      <MdClear />
    </button>
  </div>
);

const GroupHeader = ({ flow = true, ...props }) => (
  <div className="header flex items-center justify-between ">
    <h3 className="py-1 px-2 text-xs uppercase">{props?.label}</h3>
    {props?.action && (
      <button
        onClick={props.action}
        tabIndex={flow ? "-1" : null}
        className="px-2 py-2 text-xs hover:text-blue-600"
      >
        {props?.ActionIcon && <props.ActionIcon />}
        {props?.actionLabel && <span>{props.actionLabel} </span>}
      </button>
    )}
  </div>
);

const SelectDate = ({ name, label, onChange, selected }) => (
  <div className="py-0 min-w-fit max-w-lg w-full">
    <label
      htmlFor="date"
      className="text-xs font-sans   text-justify pl-2 flex justify-between items-center"
    >
      {label}
    </label>
    <div className="flex items-center px-2 border text-sm  rounded">
      <MdCalendarToday />
      <ReactDatePicker
        className="w-full px-2 py-1"
        name={name}
        selected={selected}
        onChange={onChange(name)}
        timeCaption="time"
        dateFormat="MMMM d, yyyy"
      />
    </div>
  </div>
);
