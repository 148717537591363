import { PublicOff } from "@mui/icons-material";
import React from "react";
import { MdPublicOff } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listenToVehiclesFromFirestore } from "../../app/firestore/firestoreService";
import useFirestoreCollection from "../../app/hooks/useFirestoreCollection";
import { listenToVehicles } from "./vehiclesActions";

const fn = (val) => {
  return val;
};

export const ListVehicles = ({ match }) => {
  const dispatch = useDispatch();
  const { vehicles } = useSelector((state) => state.vehicles);
  const { loading, error } = useSelector((state) => state.async);

  const [activeSort, setActiveSort] = React.useState(fn);
  const [activeFilter, setActiveFilter] = React.useState(fn);

  const [showSold, setShowSold] = React.useState(false);

  useFirestoreCollection({
    query: () => listenToVehiclesFromFirestore(showSold),
    data: (vehicles) => dispatch(listenToVehicles(vehicles)),
    deps: [dispatch, showSold],
  });
  if (!vehicles) return <div>Loading vehicles</div>;
  //   console.log(vehicles);
  return (
    <div className="container mx-auto py-2 pb-4 px-0.5 md:py-10 md:px-10 bg-white md:rounded-xl md:mt-2 shadow-lg flex flex-col space-y-2">
      <button
        type="text"
        onClick={() => setShowSold((v) => !v)}
        className="border px-4 py-2 rounded text-xs w-64 mx-auto"
      >
        {!showSold ? "Show All Vehicles" : "Show Inventory on Hand"}
      </button>
      {/* <div>{vehicles && <pre>{JSON.stringify(vehicles, null, 2)}</pre>}</div> */}
      {vehicles && vehicles.length > 0 ? (
        vehicles
          .filter(fn)
          .sort(fn)
          .map((vehicle) => <VehicleCard key={vehicle.id} vehicle={vehicle} />)
      ) : (
        <div className="flex flex-col justify-center items-center text-gray-600">
          <img
            src="img/car.png"
            alt=""
            className="h-24 w-36 opacity-70   border-black px-4 py-2 rounded-xl select-none"
          />
          <span>
            {" "}
            No vehicles found.{" "}
            <Link className="underline" to="/add">
              Add one.
            </Link>
          </span>
        </div>
      )}
    </div>
  );
};

const VehicleCard = ({ vehicle }) => {
  return (
    <div className=" flex  space-x-1 md:space-x-4 w-full md:w-[36rem] md:min-w-[24rem] hover:bg-slate-100 md:rounded-xl ">
      <img
        src={
          vehicle?.mainImgId &&
          vehicle?.images[vehicle.mainImgId]?.thumbs?.[0]?.url
            ? vehicle?.images[vehicle.mainImgId]?.thumbs?.[0]?.url
            : "img/car.png"
        }
        alt=""
        imageid={vehicle?.mainImgId}
        className="h-8 md:h-24 md:w-36      rounded-sm md:rounded-xl select-none"
      />
      <div className="w-full md:px-2 md:py-1 flex md:flex-col">
        <div className="flex flex-col flex-grow ">
          <Link to={`/v/${vehicle.id}`}>
            <div className=" uppercase truncate text-xs md:text-xl md:h-8 md:mt-1 hover:text-green-800 flex items-center ">
              {!vehicle?.isPublic && (
                <MdPublicOff className="text-xs mr-1   " />
              )}
              {vehicle?.year} {vehicle?.make} {vehicle?.model}{" "}
            </div>
          </Link>
          <div className="text-[9px] md:text-sm text-gray-500 h-4 truncate">
            {vehicle?.vin}{" "}
          </div>
        </div>
        <div className="flex flex-col shrink-0 mx-4 md:mx-0 md:flex-row md:flex justify-around items-end md:justify-start md:space-x-4 text-xs md:text-sm py-0 md:py-2">
          <div
            className={`${
              vehicle?.sellingPrice ? "price" : ""
            } whitespace-nowrap`}
          >
            {vehicle?.sellingPrice || "N/A"}
          </div>
          <div className="text-gray-500 text-[8px] md:text-sm">
            {(vehicle?.mileage &&
              Math.ceil(parseInt(vehicle?.mileage) / 1000) + "K") ||
              "N/A"}
          </div>
          <span className="hidden md:block text-gray-500">{vehicle?.trim}</span>
        </div>
      </div>
      {/* <details>
        <summary>
          {vehicle?.vin} {vehicle?.year} {vehicle?.make} {vehicle?.model}
        </summary>
        <pre className="text-xs">{JSON.stringify(vehicle, null, 2)}</pre>
      </details> */}
    </div>
  );
};
