import React from "react";
import { Field } from "react-final-form";
import { MdClear, MdUndo } from "react-icons/md";

const pasteHandler = (onChange) => {
  return function (event) {
    event.preventDefault();
    let selectionStart = event.target.selectionStart;
    let selectionEnd = event.target.selectionEnd;
    let pasteText = event.clipboardData.getData("text").trim();
    let initialValue = event.target.value;
    event.target.value =
      event.target.value.substring(0, selectionStart) +
      pasteText +
      event.target.value.substring(selectionEnd, initialValue.length);
    onChange(event);
    event.target.selectionStart = selectionStart;
    event.target.selectionEnd = selectionStart + pasteText.length;
  };
};

const revertHandler = (onChange, defaultValue, name) => {
  return function (event) {
    event.stopPropagation();
    onChange({ target: { name, value: defaultValue } });
  };
};

export const TextArea = ({
  name,
  placeholder = "",
  Icon = undefined,
  type = "text",
  inputMode,
  step,
  value,
  defaultValue,
  onChange,
  readOnly = false,
  units = "",
  align = "left",
  label = "",
  min = 0,
  onBlur = () => {},
  helperText = "",
  autoFocus = false,
  Helper = () => {
    return null;
  },
  ActionButton = () => {
    return null;
  },
  meta,
  labelAction = null,
  className = "",
  containerClassName = "",
  onKeyDown = null,
  pattern = null,
  input,
  rows = "10",
  disableSelectOnFocus = false,
  ...rest
}) => {
  const textAlign = {
    center: "text-center",
    left: "text-left",
    right: "text-right",
  };

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (autoFocus) inputRef.current.focus();
  }, [autoFocus]);

  return (
    <div
      className={`w-full  ${
        defaultValue && defaultValue !== value ? "bg-green-100 rounded-lg" : ""
      }  focus-within:text-green-600 max-w-lg box-border mb-2v transition-all ${containerClassName}`}
    >
      {!!label && type !== "hidden" && (
        <label
          htmlFor={name}
          className="text-xs font-sans   text-justify pl-2 flex justify-between items-center"
        >
          {label}{" "}
          {labelAction && (
            <button type="button" onClick={labelAction}>
              <MdClear />
            </button>
          )}
          {defaultValue && defaultValue !== value && (
            <button
              className="px-4 py-1 flex items-center space-x-2 hover:bg-black  hover:bg-opacity-5 rounded-md text-[10px]"
              onClick={revertHandler(
                onChange ? onChange : input?.onChange,
                defaultValue,
                name
              )}
            >
              <MdUndo />
              {/* <span>{defaultValue}</span> */}
            </button>
          )}
        </label>
      )}
      <div className="w-full flex justify-between items-center  bg-white bg-opacity-70  hover:border-green-300 hover:focus-within:border-green-500  rounded-md  border focus-within:border-green-400 transition-all  ">
        {!!Icon && <Icon className="ml-2 min-w-fit" />}
        <textarea
          {...rest}
          onKeyDown={onKeyDown}
          ref={inputRef}
          type={type}
          name={name}
          placeholder={placeholder?.toString()}
          autoComplete="off"
          value={value || ""}
          rows={value ? rows : 2}
          // onChange={onChange}
          step={step}
          readOnly={readOnly}
          inputMode={inputMode}
          min={min}
          onBlur={onBlur}
          pattern={pattern}
          // onFocus={(e) => !disableSelectOnFocus && e.target.select()}
          {...input}
          onChange={onChange ? onChange : input?.onChange}
          onPaste={pasteHandler(onChange ? onChange : input?.onChange)}
          className={`w-full px-2 py-1 flex-grow text-sm focus:text-gray-900 ${textAlign[align]} bg-transparent outline-none ${className}`}
        />
      </div>
      {helperText && (
        <div className="text-xs text-gray-600 mx-2">{helperText}</div>
      )}
      {!!Helper && <Helper />}
      {meta?.touched && meta?.error && (
        <span className="text-xs text-red-400 mx-2">{meta.error}</span>
      )}
    </div>
  );
};

//FINAL FORM INPUT WRAPPER
export const FFTextArea = (props) => {
  return (
    <Field name={props.name} parse={props?.parse}>
      {({ input, meta }) => <TextArea {...props} input={input} meta={meta} />}
    </Field>
  );
};
