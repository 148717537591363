import React from "react";
import { Login } from "./LoginForm";

export const UnauthModal = ({ history, setModalOpen = () => {} }) => {
  return (
    <div className="mx-auto container  px-10 h-full flex items-center flex-col justify-center  rounded">
      <div className="bg-white rounded shadow-md   flex flex-col items-center justify-center">
        <div className="flex flex-col bg-main p-6 rounded-t items-center justify-center w-full">
          <img src="img/logo.png" alt="Logo" className="" />
          <h1 className="uppercase text-white pt-4">Leaf Autos Admin Panel</h1>
        </div>
        <p className="px-4 py-2 text-sm">
          If you are trying to view our cars,{" "}
          <a className="text-main underline" href="https://leafautos.com">
            click here
          </a>
          .
        </p>
        <Login />
      </div>
    </div>
  );
};
