import { useEffect, useState } from "react";
import { database } from "../config/firebase";
import { ref as databaseRef, onValue, off } from "firebase/database";

export const useDatabaseEntry = (entry) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const ref = databaseRef(database, entry);
    onValue(ref, (snapshot) => {
      const array = [];
      // For each data in the entry
      snapshot.forEach((el) => {
        // Push the object to the array
        // If you also need to store the unique key from firebase,
        // You can use array.push({ ...el.val(), key: el.key });
        array.push({ key: el.key, label: el.val() });
      });
      setData(array);
    });
    // Clean-up function
    return () => off(ref);
  }, [entry]);

  return data;
};
