import React from "react";
import {
  MdAdd,
  MdClear,
  MdHistory,
  MdImportExport,
  MdList,
  MdPrint,
  MdSearch,
  MdSort,
  MdViewColumn,
} from "react-icons/md";

export const Toolbar = ({ panelOpen, setPanelOpen, activeSales }) => {
  return (
    <div className="w-full flex  flex-row-reverse  flex-wrap bg-black bg-opacity-0 mt-2 mb-1">
      <ToolbarButton
        onClick={() => setPanelOpen((v) => !v)}
        label={panelOpen ? "Cancel" : "New Sale"}
        Icon={panelOpen ? MdClear : MdAdd}
      />
      <ToolbarButton
        Icon={MdList}
        onClick={() => console.log(activeSales)}
        label="Console Log"
      />
      <ToolbarButton
        Icon={MdList}
        onClick={() => console.log(activeSales)}
        label="Filter"
        disabled
      />
      <ToolbarButton
        Icon={MdSort}
        onClick={() => console.log(activeSales)}
        label="Sort"
        disabled
      />
      <ToolbarButton
        Icon={MdPrint}
        onClick={() => console.log(activeSales)}
        label="Print"
        disabled
      />
      <ToolbarButton
        Icon={MdImportExport}
        onClick={() => console.log(activeSales)}
        label="Export"
        disabled
      />
      <ToolbarButton
        Icon={MdHistory}
        onClick={() => console.log(activeSales)}
        label="History"
        disabled
      />
      <ToolbarButton
        Icon={MdViewColumn}
        onClick={() => console.log(activeSales)}
        label="Columns"
        disabled
      />
      <ToolbarButton
        Icon={MdSearch}
        onClick={() => console.log(activeSales)}
        label="Search"
        disabled
      />
    </div>
  );
};

const ToolbarButton = ({ onClick, label, Icon, disabled }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={`select-none px-2 py-1 flex items-center space-x-2 text-xs transition-all hover:bg-opacity-10 bg-black border-spacing-0 bg-opacity-0 border-x border-black border-opacity-5 ${
        disabled ? "opacity-25 bg-transparent hover:bg-transparent" : ""
      }`}
    >
      {Icon && <Icon />}{" "}
      {label && <span className="hidden md:block">{label}</span>}
    </button>
  );
};
