import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  deleteVehicleInFirestore,
  listenToVehicleFromFirestore,
  updateVehicleInFirestore,
} from "../../app/firestore/firestoreService";
import useFirestoreDoc from "../../app/hooks/useFirestoreDoc";
import { listenToVehicle } from "./vehiclesActions";
import {
  MdOutlineArrowBack,
  MdSave,
  MdAttachMoney,
  MdPublic,
  MdPublicOff,
  MdBuild,
  MdBuildCircle,
  MdSell,
  MdCopyAll,
} from "react-icons/md";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { IoMdTrash } from "react-icons/io";
import { Input } from "../../app/common/form/Input";
import { VehiclePhotos } from "./VehiclePhotos";
import { TextArea } from "../../app/common/form/TextArea";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "UPDATE_INPUT":
      return { ...state, [payload.field]: payload.value };
    case "UPDATE_ALL":
      return { ...state, ...payload };
    case "SET_PUBLIC_VISIBILITY":
      return { ...state, isPublic: payload };
    default:
      return state;
  }
};

export const ListVehicle = ({ match, location }) => {
  const history = useHistory();

  const handleOnChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      payload: { field: e.target.name, value: e.target.value },
    });
  };

  const d = useDispatch();
  const { vehicle } = useSelector((state) => state.vehicles);
  const { loading, error } = useSelector((state) => state.async);

  useFirestoreDoc({
    shouldExecute:
      /* match.params.vehicleId !== vehicle?.id && */ location.pathname !==
      "/add",
    query: () => listenToVehicleFromFirestore(match.params.vehicleId),
    data: (vehicle) => {
      d(listenToVehicle(vehicle));
      dispatch({
        type: "UPDATE_ALL",
        payload: vehicle,
      });
    },
    deps: [d, match.params.vehicleId],
  });

  const setPublic = (v) => {
    updateVehicleInFirestore(vehicle.id, { isPublic: v });
  };
  const setCommingSoon = (v) => {
    updateVehicleInFirestore(vehicle.id, { commingSoon: v });
  };
  const setSold = (v) => {
    updateVehicleInFirestore(vehicle.id, { isSold: v });
  };
  const setShowHistory = (v) => {
    updateVehicleInFirestore(vehicle.id, { showHistory: v });
  };

  const save = (e) => {
    if (e.target.value === vehicle[e.target.name]) return;
    console.log(`Updating ${e.target.name} for ${vehicle.id}`);
    updateVehicleInFirestore(vehicle.id, {
      [e.target.name]: e.target.value.trim(),
    });
  };

  // React.useEffect(() => {
  //   dispatch({
  //     type: "UPDATE_ALL",
  //     payload: vehicle,
  //   });

  //   return () => {};
  // }, [vehicle]);

  const [state, dispatch] = React.useReducer(reducer, {});

  if (!vehicle || loading)
    return (
      <div className="container mx-auto px-4 py-10 h-64 md:py-10 md:px-10 bg-white md:rounded-xl md:mt-2 shadow-lg flex flex-col  space-y-2">
        LOADING {match.params.vehicleId}
      </div>
    );

  return (
    <div
      key={match.params.vehicleId}
      className="container mx-auto   bg-white md:rounded-md md:mt-2 shadow-lg flex flex-col lg:flex-row "
    >
      <div className=" w-full lg:w-1/2 pb-4">
        <div className=" flex items-center justify-between px-2 py-1 bg-slate-600 border-b border-slate-400 h-10">
          <div className="uppercase text-sm  font-medium ml-4 text-slate-50 select-none py-1">
            Vehicle Details
          </div>
          {/* <button
          type="button"
          onClick={open}
          className="bg-slate-500 hover:bg-lime-600 transition-all flex items-center space-x-2 text-white text-sm px-4 py-2 mr-1 rounded-md uppercase"
        >
          <MdAddPhotoAlternate />
          <span className="hidden md:block text-xs ">Add File</span>
        </button> */}
        </div>
        {/* <div className="flex justify-between text-gray-300 text-xs bg-slate-700 "> */}
        {/* <button
            onClick={history.goBack}
            className="flex items-center space-x-2"
          >
            <MdOutlineArrowBack /> <span>Go Back</span>
          </button> */}
        {/* <span className="">ID: {match?.params?.vehicleId}</span>
        </div> */}
        <Toolbar
          match={match}
          deleteVehicleInFirestore={deleteVehicleInFirestore}
          isPublic={vehicle?.isPublic}
          setPublic={setPublic}
          commingSoon={vehicle?.commingSoon}
          setCommingSoon={setCommingSoon}
          isSold={vehicle?.isSold}
          setSold={setSold}
          showHistory={vehicle?.showHistory}
          setShowHistory={setShowHistory}
        />

        {/* {vehicle && (
        <div className="max-h-96 overflow-scroll text-xs">
        <pre>{JSON.stringify(vehicle, null, 2)}</pre>
        </div>
      )} */}

        <div className="flex flex-col space-y-2 px-4">
          <h2>Details</h2>
          <Input
            name="vin"
            value={state.vin}
            defaultValue={vehicle.vin}
            label="VIN"
            placeholder="XXXXXXXXXXXXXXXXX"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="year"
            value={state.year}
            defaultValue={vehicle.year}
            label="Year"
            placeholder="2020"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="make"
            value={state.make}
            defaultValue={vehicle.make}
            label="Make"
            placeholder="Honda"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="model"
            value={state.model}
            defaultValue={vehicle.model}
            label="Model"
            placeholder="Civic"
            onChange={handleOnChange}
            onBlur={save}
          />

          <Input
            name="trim"
            value={state.trim}
            defaultValue={vehicle.trim}
            label="Trim"
            placeholder="EX"
            onChange={handleOnChange}
            onBlur={save}
          />

          <Input
            name="mileage"
            value={state.mileage}
            defaultValue={vehicle.mileage}
            label="Odometer, miles"
            placeholder="106,033"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="sellingPrice"
            value={state.sellingPrice}
            defaultValue={vehicle.sellingPrice}
            label="Price, $"
            placeholder="5995"
            onChange={handleOnChange}
            disableSelectOnFocus="true"
            onBlur={save}
            helperText={
              state.sellingPrice !== vehicle.sellingPrice && (
                <>
                  {state.sellingPrice - vehicle.sellingPrice > 0 ? (
                    <span className="text-lime-500">
                      Increasing by $
                      {Math.abs(state.sellingPrice - vehicle.sellingPrice)}
                    </span>
                  ) : (
                    <span className="text-red-500">
                      Reducing by $
                      {Math.abs(state.sellingPrice - vehicle.sellingPrice)}
                    </span>
                  )}
                </>
              )
            }
          />

          <h2>Exterior</h2>

          <Input
            name="exteriorColor"
            value={state.exteriorColor}
            defaultValue={vehicle.exteriorColor}
            label="Exterior Color"
            placeholder="Beige"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="body"
            value={state.body}
            defaultValue={vehicle.body}
            label="Body Type"
            placeholder="Sedan"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="wheels"
            value={state.wheels}
            defaultValue={vehicle.wheels}
            label="Wheel Count"
            placeholder="4"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="doors"
            value={state.doors}
            defaultValue={vehicle.doors}
            label="Door count"
            placeholder="5"
            onChange={handleOnChange}
            onBlur={save}
          />

          <h2>Interior</h2>

          <Input
            name="interiorType"
            value={state.interiorType}
            defaultValue={vehicle.interiorType}
            label="Interior Type"
            placeholder="Leather"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="interiorColor"
            value={state.interiorColor}
            defaultValue={vehicle.interiorColor}
            label="Interior Color"
            placeholder="White"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="seats"
            value={state.seats}
            defaultValue={vehicle.seats}
            label="Seats"
            placeholder="5"
            onChange={handleOnChange}
            onBlur={save}
          />

          <h2>Powertrain</h2>
          <Input
            name="engineHP"
            value={state.engineHP}
            defaultValue={vehicle.engineHP}
            label="Engine HP"
            placeholder="900"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="endgineCylinders"
            value={state.endgineCylinders}
            defaultValue={vehicle.endgineCylinders}
            label="Engine Cylinders"
            placeholder="6"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="engineCC"
            value={state.engineCC}
            defaultValue={vehicle.engineCC}
            label="Engine CC"
            placeholder="1000"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="engineKW"
            value={state.engineKW}
            defaultValue={vehicle.engineKW}
            label="Engine KW"
            placeholder=""
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="driveType"
            value={state.driveType}
            defaultValue={vehicle.driveType}
            label="Drive Type"
            placeholder="4x4"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="transmissionSpeeds"
            value={state.transmissionSpeeds}
            defaultValue={vehicle.transmissionSpeeds}
            label="Transmission Speeds"
            placeholder=""
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="transmission"
            value={state.transmission}
            defaultValue={vehicle.transmission}
            label="Transmission"
            placeholder="Automatic"
            onChange={handleOnChange}
            onBlur={save}
          />

          <h2>History</h2>
          <Input
            name="ownerCount"
            value={state.ownerCount}
            defaultValue={vehicle.ownerCount}
            label="Owners"
            placeholder="1"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="accidentCount"
            value={state.accidentCount}
            defaultValue={vehicle.accidentCount}
            label="Accidents"
            placeholder="0"
            onChange={handleOnChange}
            onBlur={save}
          />
          <h2>Fuel</h2>
          <Input
            name="fuelType"
            value={state.fuelType}
            defaultValue={vehicle.fuelType}
            label="Fuel Type"
            placeholder="Gas"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="fuelCapacity"
            value={state.fuelCapacity}
            defaultValue={vehicle.fuelCapacity}
            label="Fuel Capacity, Gal"
            placeholder="13"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="fuelMileageCity"
            value={state.fuelMileageCity}
            defaultValue={vehicle.fuelMileageCity}
            label="City, Miles/Gal"
            placeholder="18"
            onChange={handleOnChange}
            onBlur={save}
          />
          <Input
            name="fuelMileageHighway"
            value={state.fuelMileageHighway}
            defaultValue={vehicle.fuelMileageHighway}
            label="Highway, Miles/Gal"
            placeholder="22"
            onChange={handleOnChange}
            onBlur={save}
          />

          <h2>Description</h2>
          <TextArea
            name="description"
            value={state.description}
            defaultValue={vehicle.description}
            // label="Description"
            placeholder=""
            onChange={handleOnChange}
            onBlur={save}
          />
        </div>

        <div className="px-2">
          <button
            className="bg-lime-700 w-full  py-2 px-4 mt-4  rounded text-white flex items-center space-x-2 justify-center disabled:bg-slate-200 hover:bg-lime-500 transition-all"
            onClick={() => {
              updateVehicleInFirestore(vehicle.id, diff(state, vehicle));
            }}
            disabled={Object.keys(diff(state, vehicle)).length === 0}
          >
            <MdSave />
            <span>Save Changes</span>
          </button>
        </div>
        {/* <pre>
        <code>{JSON.stringify(diff(state, vehicle), null, 2)}</code>
      </pre> */}
      </div>
      <div className="w-full bg-slate-600 ">
        <VehiclePhotos
          images={vehicle?.images}
          docId={vehicle.id}
          mainImgId={vehicle?.mainImgId}
          imgOrder={vehicle?.imgOrder}
        />
      </div>
    </div>
  );
};

function diff(o2, o1) {
  return Object.keys(o2).reduce((diff, key) => {
    if (o1[key] === o2[key]) return diff;
    return {
      ...diff,
      [key]: o2[key],
    };
  }, {});
}

const Toolbar = ({
  match,
  deleteVehicleInFirestore,
  isPublic,
  setPublic,
  commingSoon,
  setCommingSoon,
  setSold,
  isSold,
  showHistory,
  setShowHistory,
}) => {
  const history = useHistory();

  return (
    <div className="flex justify-center pt-1  bg-slate-300    ">
      <ToolbarButton
        text={isSold ? "Sold" : "Mark Sold"}
        Icon={MdSell}
        onClick={() => {
          setSold(!isSold);
        }}
        className={isSold ? "text-green-700" : ""}
        // disabled
      />
      <ToolbarButton
        text="Delete"
        Icon={IoMdTrash}
        onClick={() => {
          const result = window.confirm("Delete car?");
          console.log(result);
          if (result) {
            deleteVehicleInFirestore(match.params.vehicleId);
            history.push("/vehicles");
          }
        }}
        // disabled
      />
      <ToolbarButton
        text="Copy URL"
        Icon={MdCopyAll}
        onClick={() =>
          navigator.clipboard.writeText(
            `https://leafautos.com/vehicle/${match.params.vehicleId}`
          )
        }
      />
      <ToolbarButton
        text={showHistory ? "Show History" : "Hide History"}
        Icon={showHistory ? MdPublic : MdPublicOff}
        onClick={() => setShowHistory(!showHistory)}
      />
      <ToolbarButton
        text={isPublic ? "Public" : "Private"}
        Icon={isPublic ? MdPublic : MdPublicOff}
        onClick={() => setPublic(!isPublic)}
      />
      <ToolbarButton
        text={commingSoon ? "Comming Soon" : "Not Soon"}
        Icon={commingSoon ? MdBuildCircle : MdBuild}
        onClick={() => setCommingSoon(!commingSoon)}
        // className={commingSoon ? "text-green-700" : ""}
        disabled={isPublic}
      />
      <ToolbarButton
        text="Sell"
        Icon={MdAttachMoney}
        onClick={() => history.push(`/v/${match.params.vehicleId}/sell`)}
      />
      <ToolbarButton
        text="Expenses"
        Icon={FaFileInvoiceDollar}
        onClick={() => history.push(`/v/${match.params.vehicleId}/expenses`)}
      />

      {/* <Link
        to={`/v/${match.params.vehicleId}/sell`}
        className="bg-lime-400 px-2 py-1  rounded  text-center hover:bg-lime-300 transition-all flex items-center justify-center space-x-2"
      >
        <MdAttachMoney />
        <span> Sell</span>
      </Link> */}
    </div>
  );
};

const ToolbarButton = ({
  Icon,
  text = "",
  className = "",
  onClick = () => {},
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      className={`select-none ${
        props?.disabled
          ? "text-slate-400"
          : "hover:text-slate-900 hover:bg-opacity-60 hover:bg-white rounded"
      }  w-18 h-full   flex-col flex items-center justify-center opacity-60  transition-all text-xl mx-2 mb-1 p-1 px-2
      ${className}`}
      {...props}
    >
      {Icon && <Icon />}
      <span className="bg-transparent leading-[10px] text-[8px] mt-1 ">
        {text}
      </span>
    </button>
  );
};
