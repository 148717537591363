import React from "react";
import {
  MdDirectionsCar,
  MdKeyboardArrowDown,
  MdUnfoldLess,
  MdUnfoldMore,
} from "react-icons/md";
import { IoReload } from "react-icons/io5";
import { BiLoader } from "react-icons/bi";
import { RiErrorWarningLine } from "react-icons/ri";

import { DropDown } from "./DropDown";

export const ComboInput = ({
  buttonRef,
  isOpen,
  close,
  open,
  selected,
  handleKeyDown,
  keyboardShortcut,
  label = "",
  Icon,
  isLoading,
  error,
  retryLoadData,
}) => (
  <>
    <div
      ref={buttonRef}
      type="button"
      onClick={isOpen ? close : open}
      onKeyDown={handleKeyDown}
      className={` w-full h-full flex ring-inset flex-nowrap items-center group text-xs    ${
        isOpen ? "ring-blue-100  ring-1" : ""
      } ring-blue-300 focus:ring-1   transition-all bg-opacity-0 bg-black hover:bg-opacity-5  select-none   `}
    >
      <input
        className="w-full bg-transparent px-2 py-1"
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        //value save on blur or onSelect
      />
      <span
        onClick={error && retryLoadData}
        className="p-1 text-gray-500 group-hover:text-gray-700 transition-all bg-opacity-0 bg-black hover:bg-opacity-5 rounded-full "
      >
        {error ? (
          <span className="text-red-400 relative">
            <RiErrorWarningLine className="visible group-hover:invisible" />
            <IoReload className="invisible group-hover:visible absolute top-0 " />
          </span>
        ) : isLoading ? (
          <BiLoader className="animate-spin" />
        ) : (
          <MdKeyboardArrowDown
            className={isOpen ? "transition-all rotate-180" : ""}
          />
        )}
      </span>
    </div>
  </>
);

export const ComboListItem = (item) => {
  return (
    <div className="flex items-center space-x-2 text-sm m-0.5">
      <span className="text-xs px-2 truncate">{`${item?.label}`}</span>
    </div>
  );
};

const handleSelect = ({ id, label, value, ...item }) => {
  return console.log(id, label, value);
};

export const ComboBox = ({
  options = sampleLenders,
  renderItem = ComboListItem,
  renderButton = ComboInput,
  defaultValue = null,
  onChange,
  value,
  onSelect = handleSelect,
  label,
  disableSearch = true,
}) =>
  DropDown({
    options,
    renderItem,
    renderButton,
    defaultValue,
    value,
    onChange,
    onSelect,
    label,
    disableSearch,
  });

const sampleLenders = [
  {
    id: 1,
    label: "Signal Lending",
    shortLabel: "Signal",
    value: "signal",
  },
  {
    id: 1,
    label: "Sensible Auto Lending",
    shortLabel: "Signal",
    value: "sensible",
  },
  {
    id: 1,
    label: "Credit Acceptance",
    shortLabel: "CAC",
    value: "cac",
  },
  {
    id: 1,
    label: "Westlake Auto Lending",
    shortLabel: "Westlake",
    value: "westlake",
  },
];
