const vehicles = [
  {
    body: "Sedan/Saloon",
    description:
      "Great daily driver. Runs and drives without issues. New front brakes. Good gas mileage.\n\nRebuilt Title and Firm on the Price.\n\nDealership: Leaf Autos LLC\nAddress: 2501 B Harbor Ave SW, Seattle, WA 98126\nPhone: (206) 602-4363\n\nThe price listed for this vehicle does not include charges such as: License, Title, Registration Fees, State or Local Taxes. A dealer documentary service fee of up to $200 may be added to the sale price or capitalized cost.",
    doors: "4",
    driveType: "FWD",
    endgineCylinders: "4",
    engineCC: "2000",
    engineHP: "147",
    engineKW: "109.6179",
    exteriorColor: "Tan",
    fuelType: "Gasoline",
    id: "clakqsdo80002356acocle263",
    images: {
      clakqsr080003356aocp2hiyr: {
        completed: true,
        failed: false,
        fileName: "20221116_151711.jpg",
        isPublic: true,
        main: true,
        size: 2148040,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr080003356aocp2hiyr/thumb_x200_20221116_151711.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080003356aocp2hiyr%2Fthumb_x200_20221116_151711.jpg?alt=media&token=clakqtcrf000301s63hwae1yn",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr080003356aocp2hiyr/thumb_x800_20221116_151711.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080003356aocp2hiyr%2Fthumb_x800_20221116_151711.jpg?alt=media&token=clakqtcw2000401s6e88l8uo1",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr080003356aocp2hiyr/thumb_x1600_20221116_151711.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080003356aocp2hiyr%2Fthumb_x1600_20221116_151711.jpg?alt=media&token=clakqtd14000501s6g1vr8qki",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080003356aocp2hiyr%2F20221116_151711.jpg?alt=media&token=1c3f5c8e-5787-41f3-b52f-aeac8e167fdb",
      },
      clakqsr080004356a0hz5sxl3: {
        completed: true,
        failed: false,
        fileName: "20221116_151721.jpg",
        isPublic: true,
        size: 3959842,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr080004356a0hz5sxl3/thumb_x200_20221116_151721.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080004356a0hz5sxl3%2Fthumb_x200_20221116_151721.jpg?alt=media&token=clakqtht8000c01s6cpxrc3rk",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr080004356a0hz5sxl3/thumb_x800_20221116_151721.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080004356a0hz5sxl3%2Fthumb_x800_20221116_151721.jpg?alt=media&token=clakqthyv000d01s6cv9pd4i8",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr080004356a0hz5sxl3/thumb_x1600_20221116_151721.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080004356a0hz5sxl3%2Fthumb_x1600_20221116_151721.jpg?alt=media&token=clakqti3a000e01s64vu0bmag",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080004356a0hz5sxl3%2F20221116_151721.jpg?alt=media&token=85fc997a-8418-4d43-9172-1df2720f812c",
      },
      clakqsr080005356agwni1vwb: {
        completed: true,
        failed: false,
        fileName: "20221116_151731.jpg",
        isPublic: true,
        size: 2211582,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr080005356agwni1vwb/thumb_x200_20221116_151731.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080005356agwni1vwb%2Fthumb_x200_20221116_151731.jpg?alt=media&token=clakqteos000001s65csw9lj5",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr080005356agwni1vwb/thumb_x800_20221116_151731.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080005356agwni1vwb%2Fthumb_x800_20221116_151731.jpg?alt=media&token=clakqteth000101s62sdlh4ga",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr080005356agwni1vwb/thumb_x1600_20221116_151731.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080005356agwni1vwb%2Fthumb_x1600_20221116_151731.jpg?alt=media&token=clakqtey3000201s6hgzvaihh",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080005356agwni1vwb%2F20221116_151731.jpg?alt=media&token=fc237fb9-a820-4381-908b-e3a25ca95dbf",
      },
      clakqsr080006356a1e6a8luu: {
        completed: true,
        failed: false,
        fileName: "20221116_151749.jpg",
        isPublic: true,
        size: 3726719,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr080006356a1e6a8luu/thumb_x200_20221116_151749.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080006356a1e6a8luu%2Fthumb_x200_20221116_151749.jpg?alt=media&token=clakqtifj000301s6555s0yul",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr080006356a1e6a8luu/thumb_x800_20221116_151749.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080006356a1e6a8luu%2Fthumb_x800_20221116_151749.jpg?alt=media&token=clakqtil1000401s6hhmcdaxs",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr080006356a1e6a8luu/thumb_x1600_20221116_151749.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080006356a1e6a8luu%2Fthumb_x1600_20221116_151749.jpg?alt=media&token=clakqtiqp000501s637aneo3f",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080006356a1e6a8luu%2F20221116_151749.jpg?alt=media&token=79385648-f9ff-4703-8563-4ab527ac65e0",
      },
      clakqsr080007356ay11ndw2l: {
        completed: true,
        failed: false,
        fileName: "20221116_151800.jpg",
        isPublic: true,
        size: 3920392,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr080007356ay11ndw2l/thumb_x200_20221116_151800.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080007356ay11ndw2l%2Fthumb_x200_20221116_151800.jpg?alt=media&token=clakqtj5r000301s6bf0idn8u",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr080007356ay11ndw2l/thumb_x800_20221116_151800.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080007356ay11ndw2l%2Fthumb_x800_20221116_151800.jpg?alt=media&token=clakqtjbi000401s6e9sva7xi",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr080007356ay11ndw2l/thumb_x1600_20221116_151800.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080007356ay11ndw2l%2Fthumb_x1600_20221116_151800.jpg?alt=media&token=clakqtjg2000501s66yu1dau3",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr080007356ay11ndw2l%2F20221116_151800.jpg?alt=media&token=15bf2ebc-c7cb-48c1-87e1-a361ade742f6",
      },
      clakqsr090008356alxl9eu7w: {
        completed: true,
        failed: false,
        fileName: "20221116_151811.jpg",
        isPublic: true,
        size: 4408846,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr090008356alxl9eu7w/thumb_x200_20221116_151811.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr090008356alxl9eu7w%2Fthumb_x200_20221116_151811.jpg?alt=media&token=clakqtk1p000001s65b3hannv",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr090008356alxl9eu7w/thumb_x800_20221116_151811.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr090008356alxl9eu7w%2Fthumb_x800_20221116_151811.jpg?alt=media&token=clakqtk5s000101s6gft62wdb",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr090008356alxl9eu7w/thumb_x1600_20221116_151811.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr090008356alxl9eu7w%2Fthumb_x1600_20221116_151811.jpg?alt=media&token=clakqtkae000201s6dzjd5zjq",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr090008356alxl9eu7w%2F20221116_151811.jpg?alt=media&token=b7ffa3e2-2957-48c0-b20b-eaf668f53217",
      },
      clakqsr090009356ab6y5qtih: {
        completed: true,
        failed: false,
        fileName: "20221116_151822.jpg",
        isPublic: true,
        size: 2410001,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr090009356ab6y5qtih/thumb_x200_20221116_151822.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr090009356ab6y5qtih%2Fthumb_x200_20221116_151822.jpg?alt=media&token=clakqteg3000001s6ab1mbxca",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr090009356ab6y5qtih/thumb_x800_20221116_151822.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr090009356ab6y5qtih%2Fthumb_x800_20221116_151822.jpg?alt=media&token=clakqteli000101s6hhdnfmpv",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr090009356ab6y5qtih/thumb_x1600_20221116_151822.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr090009356ab6y5qtih%2Fthumb_x1600_20221116_151822.jpg?alt=media&token=clakqteqc000201s6fe6z3dhu",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr090009356ab6y5qtih%2F20221116_151822.jpg?alt=media&token=e653a5a7-154e-4d5b-8404-33bfef163d9e",
      },
      clakqsr09000a356arvm1fmgb: {
        completed: true,
        failed: false,
        fileName: "20221116_151838.jpg",
        isPublic: true,
        size: 3896949,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr09000a356arvm1fmgb/thumb_x200_20221116_151838.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr09000a356arvm1fmgb%2Fthumb_x200_20221116_151838.jpg?alt=media&token=clakqtiuo000301s6027zc34x",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr09000a356arvm1fmgb/thumb_x800_20221116_151838.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr09000a356arvm1fmgb%2Fthumb_x800_20221116_151838.jpg?alt=media&token=clakqtizy000401s631jkftlb",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr09000a356arvm1fmgb/thumb_x1600_20221116_151838.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr09000a356arvm1fmgb%2Fthumb_x1600_20221116_151838.jpg?alt=media&token=clakqtj4u000501s6e1qgbv4o",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr09000a356arvm1fmgb%2F20221116_151838.jpg?alt=media&token=37cd2a59-e5e1-44ab-89b8-3e3c86f7bfe2",
      },
      clakqsr09000b356aaapjc00e: {
        completed: true,
        failed: false,
        fileName: "20221116_151846.jpg",
        isPublic: true,
        size: 2782015,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr09000b356aaapjc00e/thumb_x200_20221116_151846.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr09000b356aaapjc00e%2Fthumb_x200_20221116_151846.jpg?alt=media&token=clakqtgdb000001s6gmgg0xdw",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr09000b356aaapjc00e/thumb_x800_20221116_151846.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr09000b356aaapjc00e%2Fthumb_x800_20221116_151846.jpg?alt=media&token=clakqtghn000101s6ca9tf6yv",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr09000b356aaapjc00e/thumb_x1600_20221116_151846.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr09000b356aaapjc00e%2Fthumb_x1600_20221116_151846.jpg?alt=media&token=clakqtgmd000201s67jgq6e7d",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr09000b356aaapjc00e%2F20221116_151846.jpg?alt=media&token=abfa0cab-9781-44ac-ad7e-24ec21036c2f",
      },
      clakqsr0a000c356aofzdf45r: {
        completed: true,
        failed: false,
        fileName: "20221116_151849.jpg",
        isPublic: true,
        size: 3630883,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000c356aofzdf45r/thumb_x200_20221116_151849.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000c356aofzdf45r%2Fthumb_x200_20221116_151849.jpg?alt=media&token=clakqtj8y000001s6flvf6xe0",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000c356aofzdf45r/thumb_x800_20221116_151849.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000c356aofzdf45r%2Fthumb_x800_20221116_151849.jpg?alt=media&token=clakqtjev000101s689my9bep",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000c356aofzdf45r/thumb_x1600_20221116_151849.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000c356aofzdf45r%2Fthumb_x1600_20221116_151849.jpg?alt=media&token=clakqtjjn000201s6da9x4zpj",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000c356aofzdf45r%2F20221116_151849.jpg?alt=media&token=d4d5587c-da0d-495a-ac7a-9df00995fa86",
      },
      clakqsr0a000d356a2w89qeq3: {
        completed: true,
        failed: false,
        fileName: "20221116_151855.jpg",
        isPublic: true,
        size: 2770876,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000d356a2w89qeq3/thumb_x200_20221116_151855.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000d356a2w89qeq3%2Fthumb_x200_20221116_151855.jpg?alt=media&token=clakqtef2000601s6hwwv1ed4",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000d356a2w89qeq3/thumb_x800_20221116_151855.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000d356a2w89qeq3%2Fthumb_x800_20221116_151855.jpg?alt=media&token=clakqtek4000701s6383f6mj4",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000d356a2w89qeq3/thumb_x1600_20221116_151855.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000d356a2w89qeq3%2Fthumb_x1600_20221116_151855.jpg?alt=media&token=clakqteov000801s66quw9q9j",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000d356a2w89qeq3%2F20221116_151855.jpg?alt=media&token=9a8104d0-fde4-4594-85f8-e94985006998",
      },
      clakqsr0a000e356al41eemrk: {
        completed: true,
        failed: false,
        fileName: "20221116_151857.jpg",
        isPublic: true,
        size: 4313972,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000e356al41eemrk/thumb_x200_20221116_151857.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000e356al41eemrk%2Fthumb_x200_20221116_151857.jpg?alt=media&token=clakqtim0000301s6fjjy51t6",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000e356al41eemrk/thumb_x800_20221116_151857.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000e356al41eemrk%2Fthumb_x800_20221116_151857.jpg?alt=media&token=clakqtiqz000401s6e29xhh3r",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000e356al41eemrk/thumb_x1600_20221116_151857.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000e356al41eemrk%2Fthumb_x1600_20221116_151857.jpg?alt=media&token=clakqtivm000501s62obp30p5",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000e356al41eemrk%2F20221116_151857.jpg?alt=media&token=0e4de947-8e5d-4713-879e-aa1f0f9cabf1",
      },
      clakqsr0a000f356ajz38em5x: {
        completed: true,
        failed: false,
        fileName: "20221116_151910.jpg",
        isPublic: true,
        size: 3123131,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000f356ajz38em5x/thumb_x200_20221116_151910.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000f356ajz38em5x%2Fthumb_x200_20221116_151910.jpg?alt=media&token=clakqthqc000001s6a71dbvsy",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000f356ajz38em5x/thumb_x800_20221116_151910.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000f356ajz38em5x%2Fthumb_x800_20221116_151910.jpg?alt=media&token=clakqthuq000101s67371a4u8",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000f356ajz38em5x/thumb_x1600_20221116_151910.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000f356ajz38em5x%2Fthumb_x1600_20221116_151910.jpg?alt=media&token=clakqthzb000201s68elh1bcl",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000f356ajz38em5x%2F20221116_151910.jpg?alt=media&token=cd2c4e0b-55bc-4135-b612-96fd49ac64d4",
      },
      clakqsr0a000g356ayiy7mucq: {
        completed: true,
        failed: false,
        fileName: "20221116_151936.jpg",
        isPublic: true,
        size: 2848278,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000g356ayiy7mucq/thumb_x200_20221116_151936.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000g356ayiy7mucq%2Fthumb_x200_20221116_151936.jpg?alt=media&token=clakqtg45000901s64xvq5yuq",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000g356ayiy7mucq/thumb_x800_20221116_151936.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000g356ayiy7mucq%2Fthumb_x800_20221116_151936.jpg?alt=media&token=clakqtg9c000a01s60p850rky",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000g356ayiy7mucq/thumb_x1600_20221116_151936.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000g356ayiy7mucq%2Fthumb_x1600_20221116_151936.jpg?alt=media&token=clakqtgdv000b01s6fhiqgilx",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000g356ayiy7mucq%2F20221116_151936.jpg?alt=media&token=40a20314-eefe-402b-ba20-9cea01cd6991",
      },
      clakqsr0a000h356ao3k08gsx: {
        completed: true,
        failed: false,
        fileName: "20221116_151954.jpg",
        isPublic: true,
        size: 1226295,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000h356ao3k08gsx/thumb_x200_20221116_151954.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000h356ao3k08gsx%2Fthumb_x200_20221116_151954.jpg?alt=media&token=clakqt9to000001s62ffk6hn5",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000h356ao3k08gsx/thumb_x800_20221116_151954.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000h356ao3k08gsx%2Fthumb_x800_20221116_151954.jpg?alt=media&token=clakqt9z8000101s6gdk01yjs",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000h356ao3k08gsx/thumb_x1600_20221116_151954.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000h356ao3k08gsx%2Fthumb_x1600_20221116_151954.jpg?alt=media&token=clakqta4a000201s646sp0cpy",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000h356ao3k08gsx%2F20221116_151954.jpg?alt=media&token=9dbf8151-7ddc-4290-ae0b-a72f22a7e36f",
      },
      clakqsr0a000i356atapfoks0: {
        completed: true,
        failed: false,
        fileName: "20221116_151959.jpg",
        isPublic: true,
        size: 3710970,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000i356atapfoks0/thumb_x200_20221116_151959.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000i356atapfoks0%2Fthumb_x200_20221116_151959.jpg?alt=media&token=clakqtjly000001s65cudg0oh",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000i356atapfoks0/thumb_x800_20221116_151959.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000i356atapfoks0%2Fthumb_x800_20221116_151959.jpg?alt=media&token=clakqtjqn000101s62jfx1xwh",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0a000i356atapfoks0/thumb_x1600_20221116_151959.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000i356atapfoks0%2Fthumb_x1600_20221116_151959.jpg?alt=media&token=clakqtjvi000201s612ph8ntj",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0a000i356atapfoks0%2F20221116_151959.jpg?alt=media&token=779f6479-ddcb-4c5c-bc90-793cb3659b38",
      },
      clakqsr0b000j356a6drpip48: {
        completed: true,
        failed: false,
        fileName: "20221116_152012.jpg",
        isPublic: true,
        size: 2787401,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0b000j356a6drpip48/thumb_x200_20221116_152012.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0b000j356a6drpip48%2Fthumb_x200_20221116_152012.jpg?alt=media&token=clakqth52000001s6d862afpn",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0b000j356a6drpip48/thumb_x800_20221116_152012.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0b000j356a6drpip48%2Fthumb_x800_20221116_152012.jpg?alt=media&token=clakqth9x000101s6d9z34udv",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0b000j356a6drpip48/thumb_x1600_20221116_152012.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0b000j356a6drpip48%2Fthumb_x1600_20221116_152012.jpg?alt=media&token=clakqthef000201s65v1afpyn",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0b000j356a6drpip48%2F20221116_152012.jpg?alt=media&token=41221840-36a8-4f30-befd-bcbaaae831a0",
      },
      clakqsr0b000k356a01dpc4m9: {
        completed: true,
        failed: false,
        fileName: "20221116_152021.jpg",
        isPublic: true,
        size: 2712121,
        status: "Completed",
        storagePath: "vehicles/clakqsdo80002356acocle263",
        thumbs: [
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0b000k356a01dpc4m9/thumb_x200_20221116_152021.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0b000k356a01dpc4m9%2Fthumb_x200_20221116_152021.jpg?alt=media&token=clakqtgte000001s6gmahgqtt",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0b000k356a01dpc4m9/thumb_x800_20221116_152021.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0b000k356a01dpc4m9%2Fthumb_x800_20221116_152021.jpg?alt=media&token=clakqtgxt000101s69ub871fo",
          },
          {
            storagePath:
              "vehicles/clakqsdo80002356acocle263/clakqsr0b000k356a01dpc4m9/thumb_x1600_20221116_152021.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0b000k356a01dpc4m9%2Fthumb_x1600_20221116_152021.jpg?alt=media&token=clakqth2t000201s6fypl9oje",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclakqsdo80002356acocle263%2Fclakqsr0b000k356a01dpc4m9%2F20221116_152021.jpg?alt=media&token=bff45e05-0222-42e3-bff7-60d7465ae084",
      },
    },
    imgOrder: [
      "clakqsr080003356aocp2hiyr",
      "clakqsr080004356a0hz5sxl3",
      "clakqsr080005356agwni1vwb",
      "clakqsr080006356a1e6a8luu",
      "clakqsr080007356ay11ndw2l",
      "clakqsr090008356alxl9eu7w",
      "clakqsr090009356ab6y5qtih",
      "clakqsr09000a356arvm1fmgb",
      "clakqsr0b000j356a6drpip48",
      "clakqsr09000b356aaapjc00e",
      "clakqsr0a000c356aofzdf45r",
      "clakqsr0a000d356a2w89qeq3",
      "clakqsr0a000e356al41eemrk",
      "clakqsr0a000f356ajz38em5x",
      "clakqsr0a000g356ayiy7mucq",
      "clakqsr0a000h356ao3k08gsx",
      "clakqsr0a000i356atapfoks0",
      "clakqsr0b000k356a01dpc4m9",
    ],
    interiorColor: "Grey",
    interiorType: "",
    isPublic: true,
    isSold: false,
    mainImgId: "clakqsr080003356aocp2hiyr",
    make: "HYUNDAI",
    mileage: "129600",
    model: "Elantra",
    seats: "5",
    sellingPrice: "6999",
    series: "",
    transmissionSpeeds: "",
    transmissionStyle: "",
    trim: "SE Base",
    vin: "KMHD74LF8HU088624",
    wheels: "4",
    year: "2017",
  },
  {
    body: "Truck",
    doors: "3",
    driveType: "4x2",
    endgineCylinders: "6",
    engineCC: "6400.0",
    engineHP: "",
    engineKW: "",
    exteriorColor: "White",
    fuelType: "Diesel",
    id: "clbsw5jzi000135693bgty1rw",
    images: {
      clbsw5uxe00023569gf6qjrmj: {
        completed: true,
        failed: false,
        fileName: "IMG_20221215_125354.jpg",
        isPublic: true,
        main: true,
        size: 3544326,
        status: "Completed",
        storagePath: "vehicles/clbsw5jzi000135693bgty1rw",
        thumbs: [
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxe00023569gf6qjrmj/thumb_x200_IMG_20221215_125354.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe00023569gf6qjrmj%2Fthumb_x200_IMG_20221215_125354.jpg?alt=media&token=clbsw6z7p000301s65iy35i40",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxe00023569gf6qjrmj/thumb_x800_IMG_20221215_125354.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe00023569gf6qjrmj%2Fthumb_x800_IMG_20221215_125354.jpg?alt=media&token=clbsw6zd4000401s66j8nd3tz",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxe00023569gf6qjrmj/thumb_x1600_IMG_20221215_125354.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe00023569gf6qjrmj%2Fthumb_x1600_IMG_20221215_125354.jpg?alt=media&token=clbsw6zid000501s6amcf0h7r",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe00023569gf6qjrmj%2FIMG_20221215_125354.jpg?alt=media&token=371c4e42-7a53-4a01-9719-fc0eaa4d30a3",
      },
      clbsw5uxe00033569wg6gkzzx: {
        completed: true,
        failed: false,
        fileName: "IMG_20221215_125404.jpg",
        isPublic: true,
        size: 4070584,
        status: "Completed",
        storagePath: "vehicles/clbsw5jzi000135693bgty1rw",
        thumbs: [
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxe00033569wg6gkzzx/thumb_x200_IMG_20221215_125404.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe00033569wg6gkzzx%2Fthumb_x200_IMG_20221215_125404.jpg?alt=media&token=clbsw70os000301s605vl6kaw",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxe00033569wg6gkzzx/thumb_x800_IMG_20221215_125404.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe00033569wg6gkzzx%2Fthumb_x800_IMG_20221215_125404.jpg?alt=media&token=clbsw70tu000401s65buycsep",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxe00033569wg6gkzzx/thumb_x1600_IMG_20221215_125404.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe00033569wg6gkzzx%2Fthumb_x1600_IMG_20221215_125404.jpg?alt=media&token=clbsw70zj000501s6bnb1ckmj",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe00033569wg6gkzzx%2FIMG_20221215_125404.jpg?alt=media&token=b20ff0c1-99fa-4c01-8a48-e6286ed297f1",
      },
      clbsw5uxe000435692qj8xtky: {
        completed: true,
        failed: false,
        fileName: "IMG_20221215_125420.jpg",
        isPublic: true,
        size: 3144333,
        status: "Completed",
        storagePath: "vehicles/clbsw5jzi000135693bgty1rw",
        thumbs: [
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxe000435692qj8xtky/thumb_x200_IMG_20221215_125420.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe000435692qj8xtky%2Fthumb_x200_IMG_20221215_125420.jpg?alt=media&token=clbsw6yxh000001s68cri01ia",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxe000435692qj8xtky/thumb_x800_IMG_20221215_125420.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe000435692qj8xtky%2Fthumb_x800_IMG_20221215_125420.jpg?alt=media&token=clbsw6z2l000101s62moe4li5",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxe000435692qj8xtky/thumb_x1600_IMG_20221215_125420.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe000435692qj8xtky%2Fthumb_x1600_IMG_20221215_125420.jpg?alt=media&token=clbsw6z7q000201s63ojy1lb4",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe000435692qj8xtky%2FIMG_20221215_125420.jpg?alt=media&token=a6ba73af-122b-44b1-9c79-eb53edf3b472",
      },
      clbsw5uxe000535695y221rui: {
        completed: true,
        failed: false,
        fileName: "IMG_20221215_125435.jpg",
        isPublic: true,
        size: 4884057,
        status: "Completed",
        storagePath: "vehicles/clbsw5jzi000135693bgty1rw",
        thumbs: [
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxe000535695y221rui/thumb_x200_IMG_20221215_125435.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe000535695y221rui%2Fthumb_x200_IMG_20221215_125435.jpg?alt=media&token=clbsw717p000601s6cx459cbj",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxe000535695y221rui/thumb_x800_IMG_20221215_125435.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe000535695y221rui%2Fthumb_x800_IMG_20221215_125435.jpg?alt=media&token=clbsw71cv000701s6b7kn92cg",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxe000535695y221rui/thumb_x1600_IMG_20221215_125435.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe000535695y221rui%2Fthumb_x1600_IMG_20221215_125435.jpg?alt=media&token=clbsw71i3000801s6c5cfalke",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxe000535695y221rui%2FIMG_20221215_125435.jpg?alt=media&token=2b7d8e69-6aaf-4036-b5a5-35e95c077051",
      },
      clbsw5uxf00063569l39a8th0: {
        completed: true,
        failed: false,
        fileName: "IMG_20221215_125450.jpg",
        isPublic: true,
        size: 3084495,
        status: "Completed",
        storagePath: "vehicles/clbsw5jzi000135693bgty1rw",
        thumbs: [
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxf00063569l39a8th0/thumb_x200_IMG_20221215_125450.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxf00063569l39a8th0%2Fthumb_x200_IMG_20221215_125450.jpg?alt=media&token=clbsw6xz1000001s61j7jehqv",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxf00063569l39a8th0/thumb_x800_IMG_20221215_125450.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxf00063569l39a8th0%2Fthumb_x800_IMG_20221215_125450.jpg?alt=media&token=clbsw6y4j000101s69pkzg2cx",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxf00063569l39a8th0/thumb_x1600_IMG_20221215_125450.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxf00063569l39a8th0%2Fthumb_x1600_IMG_20221215_125450.jpg?alt=media&token=clbsw6y9v000201s63uzh8jqq",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxf00063569l39a8th0%2FIMG_20221215_125450.jpg?alt=media&token=febc0ab8-b49e-490a-be81-31f324d17cb1",
      },
      clbsw5uxi00073569xmid5894: {
        completed: true,
        failed: false,
        fileName: "IMG_20221215_125507.jpg",
        isPublic: true,
        size: 4841235,
        status: "Completed",
        storagePath: "vehicles/clbsw5jzi000135693bgty1rw",
        thumbs: [
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxi00073569xmid5894/thumb_x200_IMG_20221215_125507.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxi00073569xmid5894%2Fthumb_x200_IMG_20221215_125507.jpg?alt=media&token=clbsw71q0000901s604aw7dli",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxi00073569xmid5894/thumb_x800_IMG_20221215_125507.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxi00073569xmid5894%2Fthumb_x800_IMG_20221215_125507.jpg?alt=media&token=clbsw71v8000a01s653gdc08h",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxi00073569xmid5894/thumb_x1600_IMG_20221215_125507.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxi00073569xmid5894%2Fthumb_x1600_IMG_20221215_125507.jpg?alt=media&token=clbsw720a000b01s6hno81z4b",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxi00073569xmid5894%2FIMG_20221215_125507.jpg?alt=media&token=51eaf408-3c38-4a7e-be87-95d20c7a42be",
      },
      clbsw5uxi00083569fyz4oeuz: {
        completed: true,
        failed: false,
        fileName: "IMG_20221215_125519.jpg",
        isPublic: true,
        size: 3773081,
        status: "Completed",
        storagePath: "vehicles/clbsw5jzi000135693bgty1rw",
        thumbs: [
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxi00083569fyz4oeuz/thumb_x200_IMG_20221215_125519.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxi00083569fyz4oeuz%2Fthumb_x200_IMG_20221215_125519.jpg?alt=media&token=clbsw6zyw000601s6g6jxdh8e",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxi00083569fyz4oeuz/thumb_x800_IMG_20221215_125519.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxi00083569fyz4oeuz%2Fthumb_x800_IMG_20221215_125519.jpg?alt=media&token=clbsw703z000701s61ltc0qxn",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxi00083569fyz4oeuz/thumb_x1600_IMG_20221215_125519.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxi00083569fyz4oeuz%2Fthumb_x1600_IMG_20221215_125519.jpg?alt=media&token=clbsw7098000801s6g63d77ea",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxi00083569fyz4oeuz%2FIMG_20221215_125519.jpg?alt=media&token=07568ca0-9817-4fd4-87e5-4cbe43fe0a2d",
      },
      clbsw5uxi000935697ufkiklo: {
        completed: true,
        failed: false,
        fileName: "IMG_20221215_125539.jpg",
        isPublic: true,
        size: 5828957,
        status: "Completed",
        storagePath: "vehicles/clbsw5jzi000135693bgty1rw",
        thumbs: [
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxi000935697ufkiklo/thumb_x200_IMG_20221215_125539.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxi000935697ufkiklo%2Fthumb_x200_IMG_20221215_125539.jpg?alt=media&token=clbsw72si000601s64duwcknc",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxi000935697ufkiklo/thumb_x800_IMG_20221215_125539.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxi000935697ufkiklo%2Fthumb_x800_IMG_20221215_125539.jpg?alt=media&token=clbsw72zr000701s6gbqo8vkg",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxi000935697ufkiklo/thumb_x1600_IMG_20221215_125539.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxi000935697ufkiklo%2Fthumb_x1600_IMG_20221215_125539.jpg?alt=media&token=clbsw734y000801s6fhfc55xe",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxi000935697ufkiklo%2FIMG_20221215_125539.jpg?alt=media&token=ade59821-fe47-4f70-b213-a7570dd23376",
      },
      clbsw5uxj000a35694ty0ebsy: {
        completed: true,
        failed: false,
        fileName: "IMG_20221215_125553.jpg",
        isPublic: true,
        size: 5721450,
        status: "Completed",
        storagePath: "vehicles/clbsw5jzi000135693bgty1rw",
        thumbs: [
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxj000a35694ty0ebsy/thumb_x200_IMG_20221215_125553.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxj000a35694ty0ebsy%2Fthumb_x200_IMG_20221215_125553.jpg?alt=media&token=clbsw730q000001s63a3jfckj",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxj000a35694ty0ebsy/thumb_x800_IMG_20221215_125553.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxj000a35694ty0ebsy%2Fthumb_x800_IMG_20221215_125553.jpg?alt=media&token=clbsw737i000101s65icp7qc6",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxj000a35694ty0ebsy/thumb_x1600_IMG_20221215_125553.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxj000a35694ty0ebsy%2Fthumb_x1600_IMG_20221215_125553.jpg?alt=media&token=clbsw73d1000201s699qohkqm",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxj000a35694ty0ebsy%2FIMG_20221215_125553.jpg?alt=media&token=766c5f0a-551e-4536-8ad9-60f4003ea21e",
      },
      clbsw5uxj000b35694oeubqp8: {
        completed: true,
        failed: false,
        fileName: "IMG_20221215_125616.jpg",
        isPublic: true,
        size: 2465212,
        status: "Completed",
        storagePath: "vehicles/clbsw5jzi000135693bgty1rw",
        thumbs: [
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxj000b35694oeubqp8/thumb_x200_IMG_20221215_125616.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxj000b35694oeubqp8%2Fthumb_x200_IMG_20221215_125616.jpg?alt=media&token=clbsw6w1a000001s6dqwda78c",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxj000b35694oeubqp8/thumb_x800_IMG_20221215_125616.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxj000b35694oeubqp8%2Fthumb_x800_IMG_20221215_125616.jpg?alt=media&token=clbsw6w7d000101s6hwvv3jib",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxj000b35694oeubqp8/thumb_x1600_IMG_20221215_125616.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxj000b35694oeubqp8%2Fthumb_x1600_IMG_20221215_125616.jpg?alt=media&token=clbsw6wcw000201s6aksiey9g",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxj000b35694oeubqp8%2FIMG_20221215_125616.jpg?alt=media&token=bc4feece-4a45-430e-aa7f-e195d205b973",
      },
      clbsw5uxj000c3569miumez53: {
        completed: true,
        failed: false,
        fileName: "IMG_20221215_125619.jpg",
        isPublic: true,
        size: 2916114,
        status: "Completed",
        storagePath: "vehicles/clbsw5jzi000135693bgty1rw",
        thumbs: [
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxj000c3569miumez53/thumb_x200_IMG_20221215_125619.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxj000c3569miumez53%2Fthumb_x200_IMG_20221215_125619.jpg?alt=media&token=clbsw6x2g000001s62xexe5jp",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxj000c3569miumez53/thumb_x800_IMG_20221215_125619.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxj000c3569miumez53%2Fthumb_x800_IMG_20221215_125619.jpg?alt=media&token=clbsw6x9b000101s65m9l50k1",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxj000c3569miumez53/thumb_x1600_IMG_20221215_125619.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxj000c3569miumez53%2Fthumb_x1600_IMG_20221215_125619.jpg?alt=media&token=clbsw6xf4000201s6577vae97",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxj000c3569miumez53%2FIMG_20221215_125619.jpg?alt=media&token=2ec79136-750a-43f2-99e0-51a09f829ad6",
      },
      clbsw5uxk000d35695digefwc: {
        completed: true,
        failed: false,
        fileName: "IMG_20221215_125622.jpg",
        isPublic: true,
        size: 3270105,
        status: "Completed",
        storagePath: "vehicles/clbsw5jzi000135693bgty1rw",
        thumbs: [
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxk000d35695digefwc/thumb_x200_IMG_20221215_125622.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxk000d35695digefwc%2Fthumb_x200_IMG_20221215_125622.jpg?alt=media&token=clbsw6y99000301s6blxihqxq",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxk000d35695digefwc/thumb_x800_IMG_20221215_125622.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxk000d35695digefwc%2Fthumb_x800_IMG_20221215_125622.jpg?alt=media&token=clbsw6yec000401s6ao7qcxbt",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxk000d35695digefwc/thumb_x1600_IMG_20221215_125622.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxk000d35695digefwc%2Fthumb_x1600_IMG_20221215_125622.jpg?alt=media&token=clbsw6yk9000501s68hae0do7",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxk000d35695digefwc%2FIMG_20221215_125622.jpg?alt=media&token=08237a15-f476-4918-bb29-6b3d67b0a3f8",
      },
      clbsw5uxk000e3569sxy47561: {
        completed: true,
        failed: false,
        fileName: "IMG_20221215_125841.jpg",
        isPublic: true,
        size: 5122592,
        status: "Completed",
        storagePath: "vehicles/clbsw5jzi000135693bgty1rw",
        thumbs: [
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxk000e3569sxy47561/thumb_x200_IMG_20221215_125841.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxk000e3569sxy47561%2Fthumb_x200_IMG_20221215_125841.jpg?alt=media&token=clbsw71p1000301s6ck8c8580",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxk000e3569sxy47561/thumb_x800_IMG_20221215_125841.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxk000e3569sxy47561%2Fthumb_x800_IMG_20221215_125841.jpg?alt=media&token=clbsw71tl000401s65gs70ycw",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxk000e3569sxy47561/thumb_x1600_IMG_20221215_125841.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxk000e3569sxy47561%2Fthumb_x1600_IMG_20221215_125841.jpg?alt=media&token=clbsw71yt000501s6e452dh6z",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxk000e3569sxy47561%2FIMG_20221215_125841.jpg?alt=media&token=22be83c7-1dac-4746-bba1-ca955a2050b9",
      },
      clbsw5uxk000f3569nw3qzisb: {
        completed: true,
        failed: false,
        fileName: "IMG_20221215_125846.jpg",
        isPublic: true,
        size: 5276826,
        status: "Completed",
        storagePath: "vehicles/clbsw5jzi000135693bgty1rw",
        thumbs: [
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxk000f3569nw3qzisb/thumb_x200_IMG_20221215_125846.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxk000f3569nw3qzisb%2Fthumb_x200_IMG_20221215_125846.jpg?alt=media&token=clbsw73gt000901s6bk8oc8wm",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxk000f3569nw3qzisb/thumb_x800_IMG_20221215_125846.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxk000f3569nw3qzisb%2Fthumb_x800_IMG_20221215_125846.jpg?alt=media&token=clbsw73pk000a01s67c1fc1r3",
          },
          {
            storagePath:
              "vehicles/clbsw5jzi000135693bgty1rw/clbsw5uxk000f3569nw3qzisb/thumb_x1600_IMG_20221215_125846.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxk000f3569nw3qzisb%2Fthumb_x1600_IMG_20221215_125846.jpg?alt=media&token=clbsw73uw000b01s6felm8y7w",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclbsw5jzi000135693bgty1rw%2Fclbsw5uxk000f3569nw3qzisb%2FIMG_20221215_125846.jpg?alt=media&token=ea388a86-54d2-4f1a-98ac-94921d517327",
      },
    },
    imgOrder: [
      "clbsw5uxe00023569gf6qjrmj",
      "clbsw5uxe00033569wg6gkzzx",
      "clbsw5uxe000435692qj8xtky",
      "clbsw5uxe000535695y221rui",
      "clbsw5uxf00063569l39a8th0",
      "clbsw5uxi00073569xmid5894",
      "clbsw5uxi00083569fyz4oeuz",
      "clbsw5uxi000935697ufkiklo",
      "clbsw5uxj000a35694ty0ebsy",
      "clbsw5uxj000b35694oeubqp8",
      "clbsw5uxj000c3569miumez53",
      "clbsw5uxk000d35695digefwc",
      "clbsw5uxk000f3569nw3qzisb",
      "clbsw5uxk000e3569sxy47561",
    ],
    interiorColor: "Grey",
    interiorType: "Leather",
    isPublic: true,
    isSold: false,
    mainImgId: "clbsw5uxe00023569gf6qjrmj",
    make: "STERLING TRUCK",
    mileage: "266000",
    model: "Acterra",
    ownerCount: "",
    seats: "",
    sellingPrice: "15000",
    series: "",
    transmission: "Automatic",
    transmissionSpeeds: "",
    transmissionStyle: "Automatic",
    trim: "",
    vin: "2FZACFCS97AY54224",
    wheels: "6",
    year: "2007",
  },
  {
    body: "Pickup",
    description:
      "7.3L PowerStroke Diesel. Freshly rebuilt transmission, new batteries and new tires. Truck runs and drives great. Good for towing. Long bed. 4x4. Power windows, mirrors, seat, trailer brakes.\n\nClean Title.\n\nDealership: Leaf Autos LLC\nAddress: 2501 B Harbor Ave SW, Seattle, WA 98126\nPhone: (206) 602-4363\n\nThe price listed for this vehicle does not include charges such as: License, Title, Registration Fees, State or Local Taxes. A dealer documentary service fee of up to $200 may be added to the sale price or capitalized cost.",
    doors: "2",
    driveType: "4WD/4-Wheel Drive/4x4",
    endgineCylinders: "8",
    engineCC: "7292.243480",
    engineHP: "",
    engineKW: "",
    exteriorColor: "Black",
    fuelType: "Diesel",
    id: "clcfe20j1000135690s4y6zsr",
    images: {
      clcfe2a5c00023569a7bvoyl6: {
        completed: true,
        failed: false,
        fileName: "20230102_141543.jpg",
        isPublic: true,
        main: true,
        size: 5542998,
        status: "Completed",
        storagePath: "vehicles/clcfe20j1000135690s4y6zsr",
        thumbs: [
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5c00023569a7bvoyl6/thumb_x200_20230102_141543.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5c00023569a7bvoyl6%2Fthumb_x200_20230102_141543.jpg?alt=media&token=clcfe3rm3000c01s6hv6z356h",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5c00023569a7bvoyl6/thumb_x800_20230102_141543.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5c00023569a7bvoyl6%2Fthumb_x800_20230102_141543.jpg?alt=media&token=clcfe3rr1000d01s642mz0yhf",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5c00023569a7bvoyl6/thumb_x1600_20230102_141543.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5c00023569a7bvoyl6%2Fthumb_x1600_20230102_141543.jpg?alt=media&token=clcfe3rx4000e01s6fzk28kwl",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5c00023569a7bvoyl6%2F20230102_141543.jpg?alt=media&token=00fac3ea-95e1-4ff4-9c53-1dba954409c5",
      },
      clcfe2a5c000335691n04prt4: {
        completed: true,
        failed: false,
        fileName: "20230102_141555.jpg",
        isPublic: true,
        size: 4452583,
        status: "Completed",
        storagePath: "vehicles/clcfe20j1000135690s4y6zsr",
        thumbs: [
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5c000335691n04prt4/thumb_x200_20230102_141555.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5c000335691n04prt4%2Fthumb_x200_20230102_141555.jpg?alt=media&token=clcfe3ki9000301s69m6y04f0",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5c000335691n04prt4/thumb_x800_20230102_141555.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5c000335691n04prt4%2Fthumb_x800_20230102_141555.jpg?alt=media&token=clcfe3kn8000401s6f0ho8ai4",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5c000335691n04prt4/thumb_x1600_20230102_141555.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5c000335691n04prt4%2Fthumb_x1600_20230102_141555.jpg?alt=media&token=clcfe3kso000501s68mgh2fx9",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5c000335691n04prt4%2F20230102_141555.jpg?alt=media&token=a5b6d912-469b-4d7e-bc12-ca3692ed4611",
      },
      clcfe2a5c00043569h71q9xrk: {
        completed: true,
        failed: false,
        fileName: "20230102_141610.jpg",
        isPublic: true,
        size: 5132825,
        status: "Completed",
        storagePath: "vehicles/clcfe20j1000135690s4y6zsr",
        thumbs: [
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5c00043569h71q9xrk/thumb_x200_20230102_141610.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5c00043569h71q9xrk%2Fthumb_x200_20230102_141610.jpg?alt=media&token=clcfe3o5a000901s64uji2dq9",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5c00043569h71q9xrk/thumb_x800_20230102_141610.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5c00043569h71q9xrk%2Fthumb_x800_20230102_141610.jpg?alt=media&token=clcfe3obu000a01s60mchhww9",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5c00043569h71q9xrk/thumb_x1600_20230102_141610.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5c00043569h71q9xrk%2Fthumb_x1600_20230102_141610.jpg?alt=media&token=clcfe3ogs000b01s6d1h4ezns",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5c00043569h71q9xrk%2F20230102_141610.jpg?alt=media&token=ff24dc76-7cc7-4bb5-bede-cd91a0872aa8",
      },
      clcfe2a5d00053569kcj6rsb6: {
        completed: true,
        failed: false,
        fileName: "20230102_141641.jpg",
        isPublic: true,
        size: 4820642,
        status: "Completed",
        storagePath: "vehicles/clcfe20j1000135690s4y6zsr",
        thumbs: [
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5d00053569kcj6rsb6/thumb_x200_20230102_141641.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00053569kcj6rsb6%2Fthumb_x200_20230102_141641.jpg?alt=media&token=clcfe3n0a000301s68b8iej7c",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5d00053569kcj6rsb6/thumb_x800_20230102_141641.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00053569kcj6rsb6%2Fthumb_x800_20230102_141641.jpg?alt=media&token=clcfe3n5d000401s69vyc3igp",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5d00053569kcj6rsb6/thumb_x1600_20230102_141641.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00053569kcj6rsb6%2Fthumb_x1600_20230102_141641.jpg?alt=media&token=clcfe3nb8000501s6421i4n7q",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00053569kcj6rsb6%2F20230102_141641.jpg?alt=media&token=1b50d729-a433-443c-b048-ba2c050270cc",
      },
      clcfe2a5d00063569nxmiraxx: {
        completed: true,
        failed: false,
        fileName: "20230102_141700.jpg",
        isPublic: true,
        size: 5446731,
        status: "Completed",
        storagePath: "vehicles/clcfe20j1000135690s4y6zsr",
        thumbs: [
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5d00063569nxmiraxx/thumb_x200_20230102_141700.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00063569nxmiraxx%2Fthumb_x200_20230102_141700.jpg?alt=media&token=clcfe3njy000f01s64p7f7abh",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5d00063569nxmiraxx/thumb_x800_20230102_141700.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00063569nxmiraxx%2Fthumb_x800_20230102_141700.jpg?alt=media&token=clcfe3noe000g01s65bsb0dw5",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5d00063569nxmiraxx/thumb_x1600_20230102_141700.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00063569nxmiraxx%2Fthumb_x1600_20230102_141700.jpg?alt=media&token=clcfe3nui000h01s697he5tu1",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00063569nxmiraxx%2F20230102_141700.jpg?alt=media&token=7e3d2347-b56e-402f-abed-0d98b5682bf9",
      },
      clcfe2a5d00073569am8hezpm: {
        completed: true,
        failed: false,
        fileName: "20230102_141713.jpg",
        isPublic: true,
        size: 5104504,
        status: "Completed",
        storagePath: "vehicles/clcfe20j1000135690s4y6zsr",
        thumbs: [
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5d00073569am8hezpm/thumb_x200_20230102_141713.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00073569am8hezpm%2Fthumb_x200_20230102_141713.jpg?alt=media&token=clcfe3mcy000601s6fw1cgkei",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5d00073569am8hezpm/thumb_x800_20230102_141713.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00073569am8hezpm%2Fthumb_x800_20230102_141713.jpg?alt=media&token=clcfe3mi2000701s65bkccsi7",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5d00073569am8hezpm/thumb_x1600_20230102_141713.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00073569am8hezpm%2Fthumb_x1600_20230102_141713.jpg?alt=media&token=clcfe3mn2000801s6hcntfqfb",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00073569am8hezpm%2F20230102_141713.jpg?alt=media&token=f73642c5-fad1-41eb-b4a4-0c342ca5cd69",
      },
      clcfe2a5d00083569uq1l5nbm: {
        completed: true,
        failed: false,
        fileName: "20230102_141728.jpg",
        isPublic: true,
        size: 5332892,
        status: "Completed",
        storagePath: "vehicles/clcfe20j1000135690s4y6zsr",
        thumbs: [
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5d00083569uq1l5nbm/thumb_x200_20230102_141728.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00083569uq1l5nbm%2Fthumb_x200_20230102_141728.jpg?alt=media&token=clcfe3oyo000601s66rued3jk",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5d00083569uq1l5nbm/thumb_x800_20230102_141728.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00083569uq1l5nbm%2Fthumb_x800_20230102_141728.jpg?alt=media&token=clcfe3p55000701s6128d8aqw",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5d00083569uq1l5nbm/thumb_x1600_20230102_141728.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00083569uq1l5nbm%2Fthumb_x1600_20230102_141728.jpg?alt=media&token=clcfe3pa4000801s69m6ch596",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00083569uq1l5nbm%2F20230102_141728.jpg?alt=media&token=6c5714f7-011e-4b7f-997a-80d9398831c8",
      },
      clcfe2a5d00093569arn2hhob: {
        completed: true,
        failed: false,
        fileName: "20230102_141745.jpg",
        isPublic: true,
        size: 4917651,
        status: "Completed",
        storagePath: "vehicles/clcfe20j1000135690s4y6zsr",
        thumbs: [
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5d00093569arn2hhob/thumb_x200_20230102_141745.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00093569arn2hhob%2Fthumb_x200_20230102_141745.jpg?alt=media&token=clcfe3rh8000i01s69tgp15td",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5d00093569arn2hhob/thumb_x800_20230102_141745.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00093569arn2hhob%2Fthumb_x800_20230102_141745.jpg?alt=media&token=clcfe3rmu000j01s652shffx1",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5d00093569arn2hhob/thumb_x1600_20230102_141745.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00093569arn2hhob%2Fthumb_x1600_20230102_141745.jpg?alt=media&token=clcfe3rrx000k01s6c1iscdvm",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5d00093569arn2hhob%2F20230102_141745.jpg?alt=media&token=90573698-b198-4594-9ad8-14c8d590796f",
      },
      clcfe2a5e000a3569anifc9b4: {
        completed: true,
        failed: false,
        fileName: "20230102_141758.jpg",
        isPublic: true,
        size: 3278442,
        status: "Completed",
        storagePath: "vehicles/clcfe20j1000135690s4y6zsr",
        thumbs: [
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000a3569anifc9b4/thumb_x200_20230102_141758.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000a3569anifc9b4%2Fthumb_x200_20230102_141758.jpg?alt=media&token=clcfe3iff000001s6gf9jg480",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000a3569anifc9b4/thumb_x800_20230102_141758.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000a3569anifc9b4%2Fthumb_x800_20230102_141758.jpg?alt=media&token=clcfe3ild000101s6175tcgje",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000a3569anifc9b4/thumb_x1600_20230102_141758.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000a3569anifc9b4%2Fthumb_x1600_20230102_141758.jpg?alt=media&token=clcfe3iqg000201s65vktc9md",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000a3569anifc9b4%2F20230102_141758.jpg?alt=media&token=e14e564f-75a1-4e69-8f1a-bedac194afd9",
      },
      clcfe2a5e000b3569cqiy6xuv: {
        completed: true,
        failed: false,
        fileName: "20230102_141801.jpg",
        isPublic: true,
        size: 4074056,
        status: "Completed",
        storagePath: "vehicles/clcfe20j1000135690s4y6zsr",
        thumbs: [
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000b3569cqiy6xuv/thumb_x200_20230102_141801.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000b3569cqiy6xuv%2Fthumb_x200_20230102_141801.jpg?alt=media&token=clcfe3ju9000901s6ccrqfpu2",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000b3569cqiy6xuv/thumb_x800_20230102_141801.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000b3569cqiy6xuv%2Fthumb_x800_20230102_141801.jpg?alt=media&token=clcfe3jzp000a01s67aaa29ax",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000b3569cqiy6xuv/thumb_x1600_20230102_141801.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000b3569cqiy6xuv%2Fthumb_x1600_20230102_141801.jpg?alt=media&token=clcfe3k4y000b01s6d5w0cyct",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000b3569cqiy6xuv%2F20230102_141801.jpg?alt=media&token=d531ba82-76b0-4ba5-a51e-94fbd8bb9d47",
      },
      clcfe2a5e000c3569dm2dlnpl: {
        completed: true,
        failed: false,
        fileName: "20230102_141824.jpg",
        isPublic: true,
        size: 4073710,
        status: "Completed",
        storagePath: "vehicles/clcfe20j1000135690s4y6zsr",
        thumbs: [
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000c3569dm2dlnpl/thumb_x200_20230102_141824.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000c3569dm2dlnpl%2Fthumb_x200_20230102_141824.jpg?alt=media&token=clcfe3kkg000001s6hppu0w0l",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000c3569dm2dlnpl/thumb_x800_20230102_141824.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000c3569dm2dlnpl%2Fthumb_x800_20230102_141824.jpg?alt=media&token=clcfe3kr2000101s63cpp9hx6",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000c3569dm2dlnpl/thumb_x1600_20230102_141824.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000c3569dm2dlnpl%2Fthumb_x1600_20230102_141824.jpg?alt=media&token=clcfe3kwm000201s66b4l4brh",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000c3569dm2dlnpl%2F20230102_141824.jpg?alt=media&token=59a79c4d-ba0d-4040-9090-121a470fb3c3",
      },
      clcfe2a5e000d3569j8euplld: {
        completed: true,
        failed: false,
        fileName: "20230102_141916.jpg",
        isPublic: true,
        size: 3707315,
        status: "Completed",
        storagePath: "vehicles/clcfe20j1000135690s4y6zsr",
        thumbs: [
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000d3569j8euplld/thumb_x200_20230102_141916.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000d3569j8euplld%2Fthumb_x200_20230102_141916.jpg?alt=media&token=clcfe3hot000601s6gavkbmpu",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000d3569j8euplld/thumb_x800_20230102_141916.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000d3569j8euplld%2Fthumb_x800_20230102_141916.jpg?alt=media&token=clcfe3hvi000701s62spfda1x",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000d3569j8euplld/thumb_x1600_20230102_141916.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000d3569j8euplld%2Fthumb_x1600_20230102_141916.jpg?alt=media&token=clcfe3i0s000801s65umgc55i",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000d3569j8euplld%2F20230102_141916.jpg?alt=media&token=3c441f46-d610-4704-b378-cd1972943b3a",
      },
      clcfe2a5e000e3569loms9pe7: {
        completed: true,
        failed: false,
        fileName: "20230102_141922.jpg",
        isPublic: true,
        size: 2728792,
        status: "Completed",
        storagePath: "vehicles/clcfe20j1000135690s4y6zsr",
        thumbs: [
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000e3569loms9pe7/thumb_x200_20230102_141922.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000e3569loms9pe7%2Fthumb_x200_20230102_141922.jpg?alt=media&token=clcfe3dih000001s6hj24gutl",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000e3569loms9pe7/thumb_x800_20230102_141922.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000e3569loms9pe7%2Fthumb_x800_20230102_141922.jpg?alt=media&token=clcfe3dpg000101s6eeih8sc5",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000e3569loms9pe7/thumb_x1600_20230102_141922.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000e3569loms9pe7%2Fthumb_x1600_20230102_141922.jpg?alt=media&token=clcfe3dun000201s6b5va9v3z",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000e3569loms9pe7%2F20230102_141922.jpg?alt=media&token=8ad67485-f932-4273-99e1-a449613864b3",
      },
      clcfe2a5e000f3569i9o2d1mf: {
        completed: true,
        failed: false,
        fileName: "20230102_141949.jpg",
        isPublic: true,
        size: 4781989,
        status: "Completed",
        storagePath: "vehicles/clcfe20j1000135690s4y6zsr",
        thumbs: [
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000f3569i9o2d1mf/thumb_x200_20230102_141949.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000f3569i9o2d1mf%2Fthumb_x200_20230102_141949.jpg?alt=media&token=clcfe3ls7000c01s6gyk62vrb",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000f3569i9o2d1mf/thumb_x800_20230102_141949.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000f3569i9o2d1mf%2Fthumb_x800_20230102_141949.jpg?alt=media&token=clcfe3lyu000d01s6enub5d4b",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000f3569i9o2d1mf/thumb_x1600_20230102_141949.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000f3569i9o2d1mf%2Fthumb_x1600_20230102_141949.jpg?alt=media&token=clcfe3m4d000e01s6f77ugr76",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000f3569i9o2d1mf%2F20230102_141949.jpg?alt=media&token=a3846dba-4e5b-4c14-80b4-dce0a689a1a7",
      },
      clcfe2a5e000g3569edun1b2j: {
        completed: true,
        failed: false,
        fileName: "20230102_142029.jpg",
        isPublic: true,
        size: 3364003,
        status: "Completed",
        storagePath: "vehicles/clcfe20j1000135690s4y6zsr",
        thumbs: [
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000g3569edun1b2j/thumb_x200_20230102_142029.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000g3569edun1b2j%2Fthumb_x200_20230102_142029.jpg?alt=media&token=clcfe3fwi000301s62yx8hapz",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000g3569edun1b2j/thumb_x800_20230102_142029.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000g3569edun1b2j%2Fthumb_x800_20230102_142029.jpg?alt=media&token=clcfe3g0s000401s63a192aku",
          },
          {
            storagePath:
              "vehicles/clcfe20j1000135690s4y6zsr/clcfe2a5e000g3569edun1b2j/thumb_x1600_20230102_142029.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000g3569edun1b2j%2Fthumb_x1600_20230102_142029.jpg?alt=media&token=clcfe3g6c000501s65v29c5n7",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcfe20j1000135690s4y6zsr%2Fclcfe2a5e000g3569edun1b2j%2F20230102_142029.jpg?alt=media&token=a4db5cea-3b93-4c66-a5af-e2729e9be748",
      },
    },
    imgOrder: [
      "clcfe2a5c00023569a7bvoyl6",
      "clcfe2a5c000335691n04prt4",
      "clcfe2a5c00043569h71q9xrk",
      "clcfe2a5d00053569kcj6rsb6",
      "clcfe2a5d00063569nxmiraxx",
      "clcfe2a5d00073569am8hezpm",
      "clcfe2a5d00083569uq1l5nbm",
      "clcfe2a5d00093569arn2hhob",
      "clcfe2a5e000a3569anifc9b4",
      "clcfe2a5e000b3569cqiy6xuv",
      "clcfe2a5e000c3569dm2dlnpl",
      "clcfe2a5e000d3569j8euplld",
      "clcfe2a5e000e3569loms9pe7",
      "clcfe2a5e000f3569i9o2d1mf",
      "clcfe2a5e000g3569edun1b2j",
    ],
    interiorColor: "Grey",
    interiorType: "Cloth",
    isPublic: true,
    isSold: false,
    mainImgId: "clcfe2a5c00023569a7bvoyl6",
    make: "FORD",
    mileage: "249500",
    model: "F-250",
    seats: "",
    sellingPrice: "6999",
    series: "",
    transmissionSpeeds: "",
    transmissionStyle: "Automatic",
    trim: "XLT",
    vin: "1FTHX26F0SKA95771",
    wheels: "",
    year: "1995",
  },
  {
    body: "Sport Utility Vehicle (SUV)/Multi-Purpose Vehicle (MPV)",
    description:
      "This 2017 BMW X3 is an automotive marvel, a machine of unparalleled elegance and power. The 2.0-liter turbocharged four-cylinder engine (2.0T) is a powerhouse, producing 248 horsepower and 258 lb-ft of torque, enough to move mountains, or at least tackle any road with ease. With a mere 90,400 miles on the clock, this machine has been treated with the utmost care and respect, ready for its next chapter of greatness.\n\nThe panoramic sunroof allows the light of victory to shine down upon the cabin, while the beige leather interior and heated front seats provide comfort fit for a statesman. The rearview camera and parking sensors are the aides-de-camp of this great vehicle, ensuring easy navigation. The advanced safety features are the sentries of this fortress on wheels, keeping you and your passengers secure at all times.\n\nDo not let this opportunity pass you by, this BMW X3 is a vehicle for those who understand that true success is found not just in victory, but in the manner of achieving it. Drive it and you will understand that the word 'luxury' is an understatement.\n\nClean Title.\n\nDealership: Leaf Autos LLC\nAddress: 2501 B Harbor Ave SW, Seattle, WA 98126\nPhone: (206) 602-4363\n",
    doors: "4",
    driveType: "4x2",
    endgineCylinders: "4",
    engineCC: "1995.9443952",
    engineHP: "242",
    engineKW: "179.7137",
    exteriorColor: "Grey",
    fuelType: "Gasoline",
    id: "clcs9ws1w0001356994mguc62",
    images: {
      clcs9x4hy000235699zp4z7r5: {
        completed: true,
        failed: false,
        fileName: "20230111_132205.jpg",
        isPublic: true,
        main: true,
        size: 4813456,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hy000235699zp4z7r5/thumb_x200_20230111_132205.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy000235699zp4z7r5%2Fthumb_x200_20230111_132205.jpg?alt=media&token=clcs9zkcn000l01s66ow85apt",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hy000235699zp4z7r5/thumb_x800_20230111_132205.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy000235699zp4z7r5%2Fthumb_x800_20230111_132205.jpg?alt=media&token=clcs9zkhl000m01s6b64n7aua",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hy000235699zp4z7r5/thumb_x1600_20230111_132205.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy000235699zp4z7r5%2Fthumb_x1600_20230111_132205.jpg?alt=media&token=clcs9zkm9000n01s61g8s627e",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy000235699zp4z7r5%2F20230111_132205.jpg?alt=media&token=f568d07b-5676-47d3-86af-2a5c62594a19",
      },
      clcs9x4hy00033569cy2teqi5: {
        completed: true,
        failed: false,
        fileName: "20230111_132217.jpg",
        isPublic: true,
        size: 5764945,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hy00033569cy2teqi5/thumb_x200_20230111_132217.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy00033569cy2teqi5%2Fthumb_x200_20230111_132217.jpg?alt=media&token=clcs9zdz6000f01s6gmko8jqv",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hy00033569cy2teqi5/thumb_x800_20230111_132217.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy00033569cy2teqi5%2Fthumb_x800_20230111_132217.jpg?alt=media&token=clcs9ze3t000g01s60k9vbant",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hy00033569cy2teqi5/thumb_x1600_20230111_132217.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy00033569cy2teqi5%2Fthumb_x1600_20230111_132217.jpg?alt=media&token=clcs9ze8e000h01s62i8w4vid",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy00033569cy2teqi5%2F20230111_132217.jpg?alt=media&token=bfbed50e-9e2c-42da-8da6-d71a975fc17b",
      },
      clcs9x4hy00043569tm4sk4p9: {
        completed: true,
        failed: false,
        fileName: "20230111_132228.jpg",
        isPublic: true,
        size: 4346311,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hy00043569tm4sk4p9/thumb_x200_20230111_132228.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy00043569tm4sk4p9%2Fthumb_x200_20230111_132228.jpg?alt=media&token=clcs9zqv2000r01s61s9v4v23",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hy00043569tm4sk4p9/thumb_x800_20230111_132228.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy00043569tm4sk4p9%2Fthumb_x800_20230111_132228.jpg?alt=media&token=clcs9zr02000s01s65lga3rr5",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hy00043569tm4sk4p9/thumb_x1600_20230111_132228.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy00043569tm4sk4p9%2Fthumb_x1600_20230111_132228.jpg?alt=media&token=clcs9zr4m000t01s6cxxc5dfv",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy00043569tm4sk4p9%2F20230111_132228.jpg?alt=media&token=e9d7354d-2e02-4328-be6e-e4f8c5816c2b",
      },
      clcs9x4hy00053569md2von4y: {
        completed: true,
        failed: false,
        fileName: "20230111_132243.jpg",
        isPublic: true,
        size: 4099201,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hy00053569md2von4y/thumb_x200_20230111_132243.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy00053569md2von4y%2Fthumb_x200_20230111_132243.jpg?alt=media&token=clcs9zg83000i01s63uh0g73o",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hy00053569md2von4y/thumb_x800_20230111_132243.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy00053569md2von4y%2Fthumb_x800_20230111_132243.jpg?alt=media&token=clcs9zgdy000j01s630t30wuu",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hy00053569md2von4y/thumb_x1600_20230111_132243.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy00053569md2von4y%2Fthumb_x1600_20230111_132243.jpg?alt=media&token=clcs9zgir000k01s62np70ytv",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hy00053569md2von4y%2F20230111_132243.jpg?alt=media&token=c80dd0ae-b73a-4d13-824d-836b1ee22a2b",
      },
      clcs9x4hz00063569h5du5moc: {
        completed: true,
        failed: false,
        fileName: "20230111_132258.jpg",
        isPublic: true,
        size: 4714176,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hz00063569h5du5moc/thumb_x200_20230111_132258.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz00063569h5du5moc%2Fthumb_x200_20230111_132258.jpg?alt=media&token=clcs9zljr000001s64cqffzw2",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hz00063569h5du5moc/thumb_x800_20230111_132258.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz00063569h5du5moc%2Fthumb_x800_20230111_132258.jpg?alt=media&token=clcs9zlpt000101s614gh52kf",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hz00063569h5du5moc/thumb_x1600_20230111_132258.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz00063569h5du5moc%2Fthumb_x1600_20230111_132258.jpg?alt=media&token=clcs9zlv7000201s60ac54u7a",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz00063569h5du5moc%2F20230111_132258.jpg?alt=media&token=e2b2fdaa-9f77-480e-9f99-93da320353f2",
      },
      clcs9x4hz000735698bdtba18: {
        completed: true,
        failed: false,
        fileName: "20230111_132311.jpg",
        isPublic: true,
        size: 4930232,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hz000735698bdtba18/thumb_x200_20230111_132311.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz000735698bdtba18%2Fthumb_x200_20230111_132311.jpg?alt=media&token=clcs9z8a5000901s6ft8i2a0t",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hz000735698bdtba18/thumb_x800_20230111_132311.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz000735698bdtba18%2Fthumb_x800_20230111_132311.jpg?alt=media&token=clcs9z8f4000a01s68tgo71uw",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hz000735698bdtba18/thumb_x1600_20230111_132311.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz000735698bdtba18%2Fthumb_x1600_20230111_132311.jpg?alt=media&token=clcs9z8kr000b01s6bv58cysg",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz000735698bdtba18%2F20230111_132311.jpg?alt=media&token=d910730e-bea2-4c52-a914-87e299590e45",
      },
      clcs9x4hz00083569btjlzfct: {
        completed: true,
        failed: false,
        fileName: "20230111_132322.jpg",
        isPublic: true,
        size: 4321296,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hz00083569btjlzfct/thumb_x200_20230111_132322.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz00083569btjlzfct%2Fthumb_x200_20230111_132322.jpg?alt=media&token=clcs9zduj000f01s6b75paruz",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hz00083569btjlzfct/thumb_x800_20230111_132322.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz00083569btjlzfct%2Fthumb_x800_20230111_132322.jpg?alt=media&token=clcs9zdzc000g01s6cv0t35tw",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hz00083569btjlzfct/thumb_x1600_20230111_132322.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz00083569btjlzfct%2Fthumb_x1600_20230111_132322.jpg?alt=media&token=clcs9ze4s000h01s6fza32cqe",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz00083569btjlzfct%2F20230111_132322.jpg?alt=media&token=f91555df-c263-40f7-8f4d-3dcc7c173e04",
      },
      clcs9x4hz000935691m1vdofm: {
        completed: true,
        failed: false,
        fileName: "20230111_132331.jpg",
        isPublic: true,
        size: 3889308,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hz000935691m1vdofm/thumb_x200_20230111_132331.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz000935691m1vdofm%2Fthumb_x200_20230111_132331.jpg?alt=media&token=clcs9zatf000c01s644cu4i4w",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hz000935691m1vdofm/thumb_x800_20230111_132331.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz000935691m1vdofm%2Fthumb_x800_20230111_132331.jpg?alt=media&token=clcs9zayl000d01s6798t5t0j",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hz000935691m1vdofm/thumb_x1600_20230111_132331.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz000935691m1vdofm%2Fthumb_x1600_20230111_132331.jpg?alt=media&token=clcs9zb3a000e01s6g1857mux",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz000935691m1vdofm%2F20230111_132331.jpg?alt=media&token=44e513af-8830-40c9-8a31-cb881b8c3817",
      },
      clcs9x4hz000a35691jylrsez: {
        completed: true,
        failed: false,
        fileName: "20230111_132355.jpg",
        isPublic: true,
        size: 2824539,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hz000a35691jylrsez/thumb_x200_20230111_132355.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz000a35691jylrsez%2Fthumb_x200_20230111_132355.jpg?alt=media&token=clcs9z2bl000001s61f1lft5w",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hz000a35691jylrsez/thumb_x800_20230111_132355.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz000a35691jylrsez%2Fthumb_x800_20230111_132355.jpg?alt=media&token=clcs9z2hd000101s6hmumaudd",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4hz000a35691jylrsez/thumb_x1600_20230111_132355.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz000a35691jylrsez%2Fthumb_x1600_20230111_132355.jpg?alt=media&token=clcs9z2mv000201s6e65p9i34",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4hz000a35691jylrsez%2F20230111_132355.jpg?alt=media&token=445917e8-9150-441b-9779-9599912f087b",
      },
      clcs9x4i0000b3569xlnvc744: {
        completed: true,
        failed: false,
        fileName: "20230111_132402.jpg",
        isPublic: true,
        size: 3091504,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i0000b3569xlnvc744/thumb_x200_20230111_132402.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000b3569xlnvc744%2Fthumb_x200_20230111_132402.jpg?alt=media&token=clcs9z5po000601s6a0ipatd4",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i0000b3569xlnvc744/thumb_x800_20230111_132402.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000b3569xlnvc744%2Fthumb_x800_20230111_132402.jpg?alt=media&token=clcs9z5up000701s6fh780o3x",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i0000b3569xlnvc744/thumb_x1600_20230111_132402.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000b3569xlnvc744%2Fthumb_x1600_20230111_132402.jpg?alt=media&token=clcs9z60e000801s63juyg07z",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000b3569xlnvc744%2F20230111_132402.jpg?alt=media&token=ddc61046-e139-4527-b057-7eb75144fdbe",
      },
      clcs9x4i0000c35694e4y1e7l: {
        completed: true,
        failed: false,
        fileName: "20230111_132431.jpg",
        isPublic: true,
        size: 3131019,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i0000c35694e4y1e7l/thumb_x200_20230111_132431.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000c35694e4y1e7l%2Fthumb_x200_20230111_132431.jpg?alt=media&token=clcs9zfo5000i01s65hvu6u0l",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i0000c35694e4y1e7l/thumb_x800_20230111_132431.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000c35694e4y1e7l%2Fthumb_x800_20230111_132431.jpg?alt=media&token=clcs9zftf000j01s67zy2dqcs",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i0000c35694e4y1e7l/thumb_x1600_20230111_132431.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000c35694e4y1e7l%2Fthumb_x1600_20230111_132431.jpg?alt=media&token=clcs9zfz0000k01s6844a8kb6",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000c35694e4y1e7l%2F20230111_132431.jpg?alt=media&token=8d540575-c4da-4bff-b925-aa5fa98ee8be",
      },
      clcs9x4i0000d3569pvophb9y: {
        completed: true,
        failed: false,
        fileName: "20230111_132435.jpg",
        isPublic: true,
        size: 2970883,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i0000d3569pvophb9y/thumb_x200_20230111_132435.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000d3569pvophb9y%2Fthumb_x200_20230111_132435.jpg?alt=media&token=clcs9z43b000301s6h4n2ab80",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i0000d3569pvophb9y/thumb_x800_20230111_132435.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000d3569pvophb9y%2Fthumb_x800_20230111_132435.jpg?alt=media&token=clcs9z47r000401s6fy1ucwnq",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i0000d3569pvophb9y/thumb_x1600_20230111_132435.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000d3569pvophb9y%2Fthumb_x1600_20230111_132435.jpg?alt=media&token=clcs9z4d0000501s687vd74dx",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000d3569pvophb9y%2F20230111_132435.jpg?alt=media&token=fc4c3ab0-e9dd-4d58-b64d-83e1ca7a249a",
      },
      clcs9x4i0000e3569lgbluxct: {
        completed: true,
        failed: false,
        fileName: "20230111_132454.jpg",
        isPublic: true,
        size: 3211303,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i0000e3569lgbluxct/thumb_x200_20230111_132454.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000e3569lgbluxct%2Fthumb_x200_20230111_132454.jpg?alt=media&token=clcs9zsb7000001s6fk7b71bf",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i0000e3569lgbluxct/thumb_x800_20230111_132454.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000e3569lgbluxct%2Fthumb_x800_20230111_132454.jpg?alt=media&token=clcs9zsgh000101s6brtshz0k",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i0000e3569lgbluxct/thumb_x1600_20230111_132454.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000e3569lgbluxct%2Fthumb_x1600_20230111_132454.jpg?alt=media&token=clcs9zslz000201s6hx2ydcpi",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000e3569lgbluxct%2F20230111_132454.jpg?alt=media&token=04386981-aa06-4049-b19c-bc0b1ce535e1",
      },
      clcs9x4i0000f3569dl517so7: {
        completed: true,
        failed: false,
        fileName: "20230111_132527.jpg",
        isPublic: true,
        size: 3846193,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i0000f3569dl517so7/thumb_x200_20230111_132527.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000f3569dl517so7%2Fthumb_x200_20230111_132527.jpg?alt=media&token=clcs9zob8000o01s6f39kfszo",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i0000f3569dl517so7/thumb_x800_20230111_132527.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000f3569dl517so7%2Fthumb_x800_20230111_132527.jpg?alt=media&token=clcs9zogd000p01s63wnbfc3n",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i0000f3569dl517so7/thumb_x1600_20230111_132527.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000f3569dl517so7%2Fthumb_x1600_20230111_132527.jpg?alt=media&token=clcs9zoly000q01s66bisdroe",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i0000f3569dl517so7%2F20230111_132527.jpg?alt=media&token=d9b3e0c3-2670-4cbb-a36b-f500c58f128c",
      },
      clcs9x4i1000g35693b286r6y: {
        completed: true,
        failed: false,
        fileName: "20230111_132543.jpg",
        isPublic: true,
        size: 3362272,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000g35693b286r6y/thumb_x200_20230111_132543.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000g35693b286r6y%2Fthumb_x200_20230111_132543.jpg?alt=media&token=clcs9zmje000001s6dhl8fbmc",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000g35693b286r6y/thumb_x800_20230111_132543.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000g35693b286r6y%2Fthumb_x800_20230111_132543.jpg?alt=media&token=clcs9zmp4000101s6c6g83h6h",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000g35693b286r6y/thumb_x1600_20230111_132543.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000g35693b286r6y%2Fthumb_x1600_20230111_132543.jpg?alt=media&token=clcs9zmv9000201s67eqyfuku",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000g35693b286r6y%2F20230111_132543.jpg?alt=media&token=6413d914-7216-411a-93d1-cea25d805629",
      },
      clcs9x4i1000h3569n7d5g7jo: {
        completed: true,
        failed: false,
        fileName: "20230111_132555.jpg",
        isPublic: true,
        size: 3265913,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000h3569n7d5g7jo/thumb_x200_20230111_132555.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000h3569n7d5g7jo%2Fthumb_x200_20230111_132555.jpg?alt=media&token=clcs9zai2000c01s69ru17kyf",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000h3569n7d5g7jo/thumb_x800_20230111_132555.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000h3569n7d5g7jo%2Fthumb_x800_20230111_132555.jpg?alt=media&token=clcs9zao1000d01s69vcb2cyg",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000h3569n7d5g7jo/thumb_x1600_20230111_132555.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000h3569n7d5g7jo%2Fthumb_x1600_20230111_132555.jpg?alt=media&token=clcs9zav5000e01s6b2nl3ls0",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000h3569n7d5g7jo%2F20230111_132555.jpg?alt=media&token=17133494-b976-4a7b-96f6-bdd3b9792efc",
      },
      clcs9x4i1000i3569latoct2h: {
        completed: true,
        failed: false,
        fileName: "20230111_132603.jpg",
        isPublic: true,
        size: 2966182,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000i3569latoct2h/thumb_x200_20230111_132603.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000i3569latoct2h%2Fthumb_x200_20230111_132603.jpg?alt=media&token=clcs9zkdf000l01s6ha8k6mo4",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000i3569latoct2h/thumb_x800_20230111_132603.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000i3569latoct2h%2Fthumb_x800_20230111_132603.jpg?alt=media&token=clcs9zki0000m01s61kfnepe9",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000i3569latoct2h/thumb_x1600_20230111_132603.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000i3569latoct2h%2Fthumb_x1600_20230111_132603.jpg?alt=media&token=clcs9zkmz000n01s68lsc36v0",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000i3569latoct2h%2F20230111_132603.jpg?alt=media&token=fa059cd2-1a60-4dcd-8ca7-7874bdae3dd8",
      },
      clcs9x4i1000j3569thwyfjvz: {
        completed: true,
        failed: false,
        fileName: "20230111_132633.jpg",
        isPublic: true,
        size: 3299475,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000j3569thwyfjvz/thumb_x200_20230111_132633.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000j3569thwyfjvz%2Fthumb_x200_20230111_132633.jpg?alt=media&token=clcs9z7ty000901s6ghr706au",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000j3569thwyfjvz/thumb_x800_20230111_132633.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000j3569thwyfjvz%2Fthumb_x800_20230111_132633.jpg?alt=media&token=clcs9z7yr000a01s60hao67c6",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000j3569thwyfjvz/thumb_x1600_20230111_132633.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000j3569thwyfjvz%2Fthumb_x1600_20230111_132633.jpg?alt=media&token=clcs9z853000b01s60d5j6zea",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000j3569thwyfjvz%2F20230111_132633.jpg?alt=media&token=366caa13-053e-4828-9223-a9b171862dc7",
      },
      clcs9x4i1000k35697bdkjv85: {
        completed: true,
        failed: false,
        fileName: "20230111_132643.jpg",
        isPublic: true,
        size: 4185391,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000k35697bdkjv85/thumb_x200_20230111_132643.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000k35697bdkjv85%2Fthumb_x200_20230111_132643.jpg?alt=media&token=clcs9z67h000601s6g66oaqsh",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000k35697bdkjv85/thumb_x800_20230111_132643.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000k35697bdkjv85%2Fthumb_x800_20230111_132643.jpg?alt=media&token=clcs9z6e6000701s68fhu2gmt",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000k35697bdkjv85/thumb_x1600_20230111_132643.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000k35697bdkjv85%2Fthumb_x1600_20230111_132643.jpg?alt=media&token=clcs9z6jb000801s6fffg4x7t",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000k35697bdkjv85%2F20230111_132643.jpg?alt=media&token=8dcc4c7a-9093-41b8-94de-a86c53967e7f",
      },
      clcs9x4i1000l35695nv3rg13: {
        completed: true,
        failed: false,
        fileName: "20230111_132700.jpg",
        isPublic: true,
        size: 3223199,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000l35695nv3rg13/thumb_x200_20230111_132700.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000l35695nv3rg13%2Fthumb_x200_20230111_132700.jpg?alt=media&token=clcs9z4hc000301s6bnwb1jca",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000l35695nv3rg13/thumb_x800_20230111_132700.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000l35695nv3rg13%2Fthumb_x800_20230111_132700.jpg?alt=media&token=clcs9z4mc000401s6fkwgfp2l",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i1000l35695nv3rg13/thumb_x1600_20230111_132700.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000l35695nv3rg13%2Fthumb_x1600_20230111_132700.jpg?alt=media&token=clcs9z4rb000501s69iiefd9r",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i1000l35695nv3rg13%2F20230111_132700.jpg?alt=media&token=be04e7ce-9ca0-4638-ac89-91205e9705bb",
      },
      clcs9x4i2000m3569l0w8c3wz: {
        completed: true,
        failed: false,
        fileName: "20230111_132728.jpg",
        isPublic: true,
        size: 2955106,
        status: "Completed",
        storagePath: "vehicles/clcs9ws1w0001356994mguc62",
        thumbs: [
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i2000m3569l0w8c3wz/thumb_x200_20230111_132728.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i2000m3569l0w8c3wz%2Fthumb_x200_20230111_132728.jpg?alt=media&token=clcs9z2au000001s653ew75sv",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i2000m3569l0w8c3wz/thumb_x800_20230111_132728.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i2000m3569l0w8c3wz%2Fthumb_x800_20230111_132728.jpg?alt=media&token=clcs9z2gj000101s6hlto2u13",
          },
          {
            storagePath:
              "vehicles/clcs9ws1w0001356994mguc62/clcs9x4i2000m3569l0w8c3wz/thumb_x1600_20230111_132728.jpg",
            url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i2000m3569l0w8c3wz%2Fthumb_x1600_20230111_132728.jpg?alt=media&token=clcs9z2lb000201s68fh44ukm",
          },
        ],
        type: "image/jpeg",
        url: "https://firebasestorage.googleapis.com/v0/b/leafautosphotos/o/vehicles%2Fclcs9ws1w0001356994mguc62%2Fclcs9x4i2000m3569l0w8c3wz%2F20230111_132728.jpg?alt=media&token=742e1285-fb50-43cf-8c0b-9fa6bbdff97b",
      },
    },
    imgOrder: [
      "clcs9x4hy000235699zp4z7r5",
      "clcs9x4hy00033569cy2teqi5",
      "clcs9x4hy00043569tm4sk4p9",
      "clcs9x4hy00053569md2von4y",
      "clcs9x4hz00063569h5du5moc",
      "clcs9x4hz000735698bdtba18",
      "clcs9x4hz00083569btjlzfct",
      "clcs9x4hz000935691m1vdofm",
      "clcs9x4hz000a35691jylrsez",
      "clcs9x4i0000b3569xlnvc744",
      "clcs9x4i0000c35694e4y1e7l",
      "clcs9x4i0000d3569pvophb9y",
      "clcs9x4i0000e3569lgbluxct",
      "clcs9x4i0000f3569dl517so7",
      "clcs9x4i1000g35693b286r6y",
      "clcs9x4i1000h3569n7d5g7jo",
      "clcs9x4i1000i3569latoct2h",
      "clcs9x4i1000j3569thwyfjvz",
      "clcs9x4i1000k35697bdkjv85",
      "clcs9x4i1000l35695nv3rg13",
      "clcs9x4i2000m3569l0w8c3wz",
    ],
    interiorColor: "Beige",
    interiorType: "Leather",
    isPublic: true,
    isSold: false,
    mainImgId: "clcs9x4hy000235699zp4z7r5",
    make: "BMW",
    mileage: "90400",
    model: "X3",
    seats: "5",
    sellingPrice: "16999",
    series: "",
    transmissionSpeeds: "8",
    transmissionStyle: "Automatic",
    trim: "sDrive28i",
    vin: "5UXWZ7C37H0V90710",
    wheels: "",
    year: "2017",
  },
];

export default vehicles;
