import { FaPercent, FaWrench } from "react-icons/fa";
import { GrUserWorker } from "react-icons/gr";
import { MdEmojiTransportation } from "react-icons/md";

const categories = [
  {
    label: "Transportation",
    Icon: MdEmojiTransportation,
  },
  { label: "Parts", Icon: FaWrench },
  { label: "Labor", Icon: GrUserWorker },
  { label: "Interest", Icon: FaPercent },
  { label: "Custom" },
];

export default categories;
